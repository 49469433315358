import React, { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NotificationContext, { UndoAction } from '../../contexts/NotificationsContext';
import { DialogNotifications } from './DialogNotifications';
import { SnackbarNotifications } from './SnackbarNotifications';

export const NotificationsAndErrorProvider = (props: any) => {
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [title, setTitle] = useState('');
  const [errorTitle, setErrorTitle] = useState('');
  const [hasAction, setHasAction] = useState(false);
  const [message, setMessage] = useState('');
  const [cancelAction, setCancelAction] = useState<UndoAction>();
  const [icon, setIcon] = useState<React.ReactNode | null>(null);
  const [timeOut, setTimeOut] = useState(5000);
  const navigate = useNavigate();
  const snackbarClassName = useRef('');
  const showErrorDialog = useCallback((title: string, message: string, hasAction: boolean) => {
    setOpenNotification(false);
    setOpenErrorDialog(true);
    setErrorTitle(title);
    setHasAction(hasAction);
    setMessage(message);
  }, []);

  const showNotificationSnackbar = useCallback(
    (title: string, timeOut?: number, cancelAction?: UndoAction, className?: string, icon?: React.ReactNode) => {
      setOpenNotification(true);
      setTitle(title);
      setCancelAction(cancelAction);
      setTimeOut(timeOut || 5000);
      snackbarClassName.current = className ? className : '';
      setIcon(icon);
    },
    []
  );

  return (
    <NotificationContext.Provider
      value={{
        showError: showErrorDialog,
        showNotification: showNotificationSnackbar,
        hideNotification: () => setOpenNotification(false),
      }}
    >
      {props.children}
      <DialogNotifications
        navigate={navigate}
        showError={openErrorDialog}
        setShowError={setOpenErrorDialog}
        hasAction={hasAction}
        title={errorTitle}
        message={message}
      />
      <SnackbarNotifications
        showNotifications={openNotification}
        setShowNotifications={setOpenNotification}
        title={title}
        message={message}
        cancelAction={cancelAction}
        timeOut={timeOut}
        className={snackbarClassName.current}
        icon={icon}
      />
    </NotificationContext.Provider>
  );
};
