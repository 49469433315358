import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useGraphClient } from '@services';
import { DEFAULT_VIEW, ItemDataWithPaging, RefreshAction, SharePointClient } from '~/utilities';
import { DrilldownState } from '~/components/Tree/Renderers/DrilldownHandler';
import Drilldown from './Drilldown';

const FolderDrilldown = () => {
  const {
    state: { location, refreshStamp, newItems, view, orderBy },
  } = useLocation() as DrilldownState;
  const gcl = useGraphClient();
  const getItems = useCallback(
    async (token?: string): Promise<ItemDataWithPaging> => {
      const stamp = await RefreshAction.markRefreshStamp(location, refreshStamp);
      const folder = await location.getFolder(gcl);
      await location.getSchema(new SharePointClient(gcl, location.rootSite));
      let folderView = view ?? (await folder.getViews(gcl, false, refreshStamp)).find(v => v.isDefault) ?? DEFAULT_VIEW;
      if (orderBy) folderView = folderView.combineWithOrderBy([orderBy]);
      return await folder.getItemsFromView(gcl, folderView, 50, stamp, token);
    },
    [location, gcl, refreshStamp, view, orderBy]
  );
  return <Drilldown key={location.apiIdKey} newItems={newItems} location={location} getItems={getItems} />;
};

export default FolderDrilldown;
