import { Box, styled } from '@mui/material';
import { newItemDecorator } from '../../../NewItemDecorator';

interface CellContentIconProps {
  isColorable?: boolean;
  isSelected?: boolean;
  isTypeIcon?: boolean;
}

const CellContentIcon = styled(Box, {
  shouldForwardProp: prop => prop !== 'isColorable' && prop !== 'isSelected' && prop !== 'isTypeIcon',
})<CellContentIconProps>`
  ${({ theme, isColorable, isSelected, isTypeIcon }) => `
    display: flex;
    flex-direction: row;
    min-width: 20px;
    height: 20px;
    margin-right: ${theme.spacing(1)} !important;
    &+.MuiBox-root {
      width: calc(100% - 25px);
    }
    ${
      isTypeIcon && isSelected
        ? `.navigationItem.isNew & ${newItemDecorator(
            theme,
            theme.palette.buttons.accentPrimary,
            theme.palette.texts.onAccentPrimary
          )}`
        : isTypeIcon
          ? `.navigationItem.isNew:not(:hover) & ${newItemDecorator(theme)}`
          : ''
    }
    ${
      isTypeIcon && !isSelected
        ? `.navigationItem.isNew:hover & ${newItemDecorator(theme, theme.palette.backgrounds.secondary)}`
        : ''
    }
    ${
      isColorable
        ? `.navigationItem & {
            svg,
            svg path {
              fill: ${theme.palette.texts.primary};
            }
          }`
        : ''
    }
    ${
      isColorable && isSelected
        ? `.navigationItem & {
            svg,
            svg path {
              fill: ${theme.palette.texts.onAccentPrimary};
            }
          }`
        : ''
    }
    ${
      isColorable
        ? `.navigationItem:hover & {
            svg,
            svg path {
              fill: ${theme.palette.texts.accentSecondary};
            }
          }`
        : ''
    }
    ${
      isColorable && isSelected
        ? `.navigationItem:hover & {
            svg,
            svg path {
              fill: ${theme.palette.texts.onAccentPrimary};
            }
          }`
        : ''
    }
  `}
`;

export default CellContentIcon;
