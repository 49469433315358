import React, { useCallback, useEffect, useState } from 'react';
import { RowTable } from '~/modules/Drilldown/Table/RowTable';
import { TableRowRenderProps, RowTableRenderer } from './GetNodeRenderer';
import { EmptyDiv, TooltipHideEvent, TooltipShowEvent } from './RenderViewItem';

export const RowTableRender: RowTableRenderer = ({
  node,
  RenderTooltip,
  onSelect,
  onDoubleClick,
  gridRowProps,
}: TableRowRenderProps) => {
  const { data } = node;
  const [showTooltip, setShowTooltip] = useState(true);

  const handleTooltipHide = useCallback(() => setShowTooltip(false), []);
  const handleTooltipShow = useCallback(() => setShowTooltip(true), []);

  useEffect(() => {
    const hideToken = PubSub.subscribe(TooltipHideEvent, handleTooltipHide);
    const showToken = PubSub.subscribe(TooltipShowEvent, handleTooltipShow);

    return () => {
      PubSub.unsubscribe(hideToken);
      PubSub.unsubscribe(showToken);
    };
  }, [handleTooltipHide, handleTooltipShow]);
  RenderTooltip = (showTooltip && RenderTooltip) || EmptyDiv;

  return (
    <RenderTooltip data={data}>
      <RowTable rowProps={gridRowProps} onSelect={onSelect} customOnDoubleClick={onDoubleClick} />
    </RenderTooltip>
  );
};
