import * as React from 'react';
import { styled } from '@mui/material/styles';
import Drawer from 'material-ui-core/Drawer';
import { Stack } from '@mui/system';

interface Props {
  container?: HTMLDivElement | null;
  open: boolean;
  isCustomContainer?: boolean;
  toggleDrawer?: (state: boolean) => void;
  window?: () => Window;
}

const Container = styled(Stack)`
  ${({ theme }) => `
    border-radius: 8px 8px 0px 0px;
    background: ${theme.palette.backgrounds.primary};
    display: flex;
    padding:  ${theme.spacing(4, 3)};
  `}
`;

const DrawerComponent: React.FC<React.PropsWithChildren<Props>> = props => (
  <Drawer
    PaperProps={{ style: { position: 'absolute', background: 'none' } }}
    BackdropProps={{ style: { position: 'absolute' } }}
    ModalProps={{
      container: props.container,
      style: { position: 'absolute' },
    }}
    keepMounted={false}
    container={props.container}
    anchor="bottom"
    open={props.open}
    onClose={() => props.toggleDrawer && props.toggleDrawer(false)}
  >
    {props.isCustomContainer ? <>{props.children}</> : <Container>{props.children}</Container>}
  </Drawer>
);

export default DrawerComponent;
