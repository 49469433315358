import React, { useState } from 'react';
import { useTheme } from '@mui/material';
import { strings } from '@vendor/languages';
import { Typography } from '../Typography';
import { Stack } from '../Stack';
import OnboardingListItem from '../OnboardingListItem/OnboardingListItem';
import { SelectionInfo } from '../Onboarding/SlideFooter';
import { Divider } from '../Divider';
import { Box } from '../Box';
import { ButtonsStack } from '../ButtonsStack';
import { ButtonProps } from '../Button';
import { Scrollbar } from '../Scollbar';
import Drawer from '../Drawer/Drawer';

export interface SelectItem {
  id: string;
  name: string;
  checked: boolean;
  isParentItem: boolean;
  parentId: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  description?: string | null;
  contentType?: string | null;
}

interface SelectItemsModalProps {
  items: SelectItem[];
  onSave: (selectedItems: SelectItem[]) => void;
  onCancel: () => void;
}
const getHeight = (items: SelectItem[]) => {
  // Calculate the total height based on item type
  const totalHeight = items.reduce((height, item) => {
    return height + (item.isParentItem ? 45 : 35); // 40 for emails with divider, 30 for others
  }, 0);

  // If the total height exceeds a maximum of 13 rows, cap it at 13 rows
  const maxHeight = 13 * 30; // Maximum height calculated for 13 non-email items
  return totalHeight > maxHeight ? maxHeight : totalHeight;
};

export const SelectItemsModal: React.FC<SelectItemsModalProps> = ({ items, onSave, onCancel }) => {
  const [selectedItems, setSelectedItems] = useState<SelectItem[]>(items.filter(item => item.isParentItem));
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  // Toggle individual item selection
  const toggleItem = (id: string) => {
    setSelectedItems(prevSelected => {
      // Check if the item is already selected
      const isSelected = prevSelected.some(item => item.id === id);
      if (isSelected) {
        // Remove the item if it's already selected
        return prevSelected.filter(item => item.id !== id);
      } else {
        // Add the item if it's not selected
        const selectedItem = items.find(item => item.id === id);
        return selectedItem ? [...prevSelected, selectedItem] : prevSelected;
      }
    });
  };
  const onClick = () => {
    onSave(selectedItems);
    setOpen(false);
  };
  const cancel = () => {
    onCancel();
    setOpen(false);
  };
  const approveReject: ButtonProps[] = [
    {
      children: strings.lang.selectItemsModal.secondaryActionText,
      variant: 'text',
      onClick: cancel,
    },
    {
      children: strings.lang.selectItemsModal.primaryButton,
      variant: 'primary',
      onClick: onClick,
      disabled: selectedItems.length === 0,
    },
  ];

  // Select all items
  const selectAll = () => {
    setSelectedItems(items);
  };

  // Unselect all items
  const unselectAll = () => {
    setSelectedItems([]);
  };

  return (
    <Drawer open={open} isCustomContainer>
      <Stack
        sx={{
          borderRadius: '8px 8px 0px 0px',
          background: `${theme.palette.backgrounds.primary}`,
          display: 'flex',
          paddingX: 2,
          paddingY: 3,
        }}
      >
        <Stack spacing={1}>
          <Stack spacing={1} paddingLeft={2}>
            <Typography variant="H1SemiBold" color={theme.palette.text.primary}>
              {strings.lang.selectItemsModal.title}
            </Typography>
            <Typography variant="H3Regular" color={theme.palette.text.secondary}>
              {strings.lang.selectItemsModal.description}
            </Typography>
          </Stack>

          {/* SelectionInfo with dynamic count */}
          <Stack>
            <SelectionInfo
              selectedItems={selectedItems.length}
              totalItems={items.length}
              selectAll={selectAll}
              unselectAll={unselectAll}
              texts={{
                selectAll: strings.lang.selectItemsModal.selectAll,
                selectedItems: strings.lang.selectItemsModal.selectedItems,
                unSelectAll: strings.lang.selectItemsModal.unselectAll,
              }}
            />
          </Stack>
        </Stack>

        <Stack
          mt={1}
          sx={{
            height: getHeight(items),
            maxHeight: '300px',
            width: '100%',
          }}
          className="hidden-scrollbar"
        >
          <Scrollbar>
            <Stack spacing={1}>
              {items?.map(item => {
                return (
                  <React.Fragment key={item.id}>
                    {/* Add a Divider if it's an email item */}

                    {item.isParentItem ? (
                      <Stack marginTop={`${theme.spacing(1)} !important`}>
                        <Divider />
                      </Stack>
                    ) : (
                      <></>
                    )}
                    <OnboardingListItem
                      id={item.id}
                      name={item.name}
                      Icon={item.Icon}
                      checked={selectedItems.some(selected => selected.id === item.id)}
                      onChange={() => toggleItem(item.id)}
                      description={item.description}
                    />
                  </React.Fragment>
                );
              })}
            </Stack>
          </Scrollbar>
        </Stack>
        <Box mt={2}>
          <ButtonsStack buttons={approveReject} />
        </Box>
      </Stack>
    </Drawer>
  );
};
