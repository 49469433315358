import { FavoriteEmptyStateDark, FavoriteEmptyStateLight, FavoriteMedium, IconProps } from '@storybook';
import { strings } from '@vendor/languages';
import {
  ItemData,
  BaseContainer,
  EmptyStateItem,
  BaseItem,
  treeitemFirstParent,
  ItemDataWithPaging,
} from '../itemTypes';
import { OfficeUserSettingManager } from '../stateManager';
import { createItemDataFromJson, cloneItemData } from '../misc';
import { ItemListChangesType, ItemsListChangedEvent, publishItemListChanged } from '../actions';

class FavoritesStateManager extends OfficeUserSettingManager<Map<string, ItemData>> {
  constructor() {
    super('AllFacorites', () => new Map<string, ItemData>());
  }

  protected stringify(val) {
    return JSON.stringify([...val.values()].map(f => f.toJson()));
  }

  protected parse(val) {
    const vals: BaseItem[] = JSON.parse(val).map(f => createItemDataFromJson(f));
    vals.forEach(v => {
      v.setOwnPrefix();
      v.isTransientLocation = !v.isDocument;
    });
    return new Map<string, ItemData>(vals.map(f => [f.apiIdKey, f]));
  }

  isFavorite(fav: ItemData) {
    return this.value?.has(fav.apiIdKey);
  }

  remove(fav: string, save = true) {
    const res = this.value.delete(fav);
    if (res && save) this.save();
    return res;
  }

  private cloneAndPrepare(fav: ItemData, timestamp?: number) {
    const cloned = cloneItemData(fav);
    (cloned as BaseItem).setOwnPrefix();
    cloned.isTransientLocation = !cloned.isDocument;
    cloned.timeStamp = timestamp;
    return cloned;
  }

  update(key: string, fav: ItemData) {
    const prev = this.value.get(key);
    if (prev === undefined) return false;
    this.value.delete(key);
    this.value.set(fav.apiIdKey, this.cloneAndPrepare(fav, prev.timeStamp));
    return true;
  }

  add(fav: ItemData, timeStamp?: number) {
    const cloned = this.cloneAndPrepare(fav, timeStamp);
    const favArray = Array.from(this.value);
    favArray.unshift([fav.apiIdKey, cloned]);
    this.value = favArray.reduce(function (map, [key, value]) {
      map.set(key, value);
      return map;
    }, new Map());
  }
}

export class FavoritesRoot extends BaseContainer {
  private static _instance: FavoritesRoot;
  static NewChildren: boolean;
  private static _allFavorites?: FavoritesStateManager;
  timeStamp: number;

  static get AllFavorites(): FavoritesStateManager {
    if (this._allFavorites === undefined) this._allFavorites = new FavoritesStateManager();
    return this._allFavorites;
  }

  static AddNewItem(data: ItemData) {
    FavoritesRoot.NewChildren = true;
    publishItemListChanged({ added: { [data.apiIdKey]: data }, location: this.instance });
  }

  private constructor() {
    super({ type: 'favoritesroot', id: 'root', name: strings.lang.nodeNames.favorites });
    this.rootPrefix = 'favorites:';
    this.timeStamp = Date.now();
    PubSub.subscribe(ItemsListChangedEvent, (_nad, { updated, deleted, location }: ItemListChangesType) => {
      if (location.rootPrefix == this.rootPrefix) return;
      const favsMap = FavoritesRoot.AllFavorites;
      let isUpdated = false;
      if (updated) Object.keys(updated).forEach(k => (isUpdated = favsMap.update(k, updated[k] || isUpdated)));
      if (deleted) Object.keys(deleted).forEach(k => (isUpdated = favsMap.remove(k) || isUpdated));
      if (isUpdated) FavoritesRoot.AllFavorites.save();
    });
  }

  static get instance() {
    if (this._instance === undefined) this._instance = new FavoritesRoot();
    return this._instance;
  }

  override get supportSearch(): boolean {
    return false;
  }

  override get hasNewChildren(): boolean {
    return FavoritesRoot.NewChildren;
  }
  override resetNewItems(isOpened: boolean) {
    if (isOpened) {
      if (FavoritesRoot._allFavorites !== undefined) {
        [...FavoritesRoot._allFavorites.value.values()].map(val => {
          val.isNew = undefined;
        });
      }
      this.timeStamp = Date.now();
    }
    FavoritesRoot.NewChildren = false;
  }

  override get itemCanSelected(): boolean {
    return false;
  }

  override async fetchChildren(): Promise<ItemDataWithPaging> {
    [...FavoritesRoot.AllFavorites.value.values()].map(val => {
      val.isNew = val.timeStamp && val.timeStamp > this.timeStamp ? true : false;
      val.itemFirstParent = treeitemFirstParent.Favorite;
    });
    return {
      items: new EmptyStateItem({
        inTree: true,
        name: 'favorites',
        images: { light: FavoriteEmptyStateLight, dark: FavoriteEmptyStateDark },
      }).apply(
        [...FavoritesRoot.AllFavorites.value.values()].sort((u, v) => (v.timeStamp || 0) - (u.timeStamp || 0)),
        FavoritesRoot.AllFavorites.value.size === 0
      ),
    };
  }

  override get canRefresh(): boolean {
    return false;
  }

  override get isRootSection(): boolean {
    return true;
  }

  get locationHaveQuickSearch(): boolean {
    return false;
  }

  override getIcon(_expanded: boolean): IconProps {
    return { icon: FavoriteMedium, isColorable: true };
  }
}
