import { DataGridPremium } from '@mui/x-data-grid-premium';
import { styled } from '@mui/material';
interface StyledDataGridProps {
  hideHeader?: boolean;
}
export const StyledDataGrid = styled(DataGridPremium)<StyledDataGridProps>(({ theme, hideHeader }) => ({
  margin: theme.spacing(2),
  backgroundColor: `${theme.palette.backgrounds.primary} !important`,
  '.MuiDataGrid-scrollbar--horizontal': {
    marginBottom: theme.spacing(1),
  },
  '& ::-webkit-scrollbar': {
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
  '& ::-webkit-scrollbar-track': {
    backgroundColor: 'none',
  },
  '& ::-webkit-scrollbar-thumb': {
    borderRadius: theme.spacing(2),
    background: theme.palette.scrollBar.hover,
    ':hover': {
      background: theme.palette.scrollBar.pressed,
    },
    ':active': {
      background: theme.palette.scrollBar.pressed,
    },
  },
  color: theme.palette.texts.primary,
  '.MuiDataGrid-row': {
    borderRadius: '8px',
    width: 'calc(100% - 20px)',
    ':hover': {
      backgroundColor: theme.palette.backgrounds.secondary,
      color: theme.palette.texts.accentSecondary,
      '& .MuiRating-iconEmpty': {
        color: theme.palette.texts.accentSecondary,
      },
      '& .MuiChip-label': {
        color: theme.palette.texts.accentSecondary,
      },
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.buttons.accentPrimary,
      color: theme.palette.texts.onAccentPrimary,
      boxShadow: theme.palette.shadow.item,
      '& .MuiChip-label': {
        color: theme.palette.texts.onAccentPrimary,
      },
      ':hover': {
        backgroundColor: theme.palette.buttons.accentPrimary,
        '& .MuiRating-iconEmpty': {
          color: theme.palette.texts.onAccentPrimary,
        },
        '& .MuiChip-label': {
          color: theme.palette.texts.onAccentPrimary,
        },
      },
    },
  },
  '.MuiDataGrid-container--top': {
    background: `${theme.palette.backgrounds.primary} !important`,
  },
  '.MuiDataGrid-scrollbarFiller': {
    borderTop: `0 !important`,
  },
  '.MuiDataGrid-columnHeader': {
    background: `${theme.palette.backgrounds.primary} !important`,
    display: hideHeader ? 'none' : '',
    maxHeight: theme.spacing(5),
    marginBottom: theme.spacing(1),
    borderRadius: '8px',
    borderBottom: `0 !important`,
    '& .MuiSvgIcon-root': {
      fontSize: '2rem',
    },

    cursor: 'default',
    ':hover': {
      background: `${theme.palette.backgrounds.secondary}!important`,
    },
    ':focus-within': {
      outline: 'none',
    },
  },
  '.MuiDataGrid-columnSeparator': {
    color: `${theme.palette.texts.secondary}!important`,
    ':hover': {
      color: `${theme.palette.texts.accentSecondary}!important`,
    },
  },

  '.MuiDataGrid-columnHeaderTitleContainerContent': {
    width: '100%',
    height: '100%',
    cursor: 'default',
  },

  '.MuiDataGrid-cell': {
    borderTop: 0,
    width: '100%',
    alignContent: 'center',
    ':focus-within': {
      outline: 'none',
    },
    '.MuiSkeleton-root': {
      backgroundColor: theme.palette.scrollBar.default,
    },
  },
}));
