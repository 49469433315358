export const en = {
  //EHM Dialog
  ehm_title: 'Create an email view in this team',
  ehm_checkmark1: 'Emails saved to this team will automatically include metadata',
  ehm_checkmark2: 'A view will be created with email detail columns (subject, to, from, etc.)',
  ehm_checkboxText: "Don't ask again for this location",
  ehm_primary_button: 'Create view',
  ehm_secondary_button: 'Not now',
  // share to teams dialog
  shareDialogTexts_titleEnd: 'Microsoft Teams',
  shareDialogTexts_shareTo: 'Share to',
  shareDialogTexts_viewMailText: 'View email',
  shareDialogTexts_notifyText: 'Notify everyone in this channel',
  shareDialogTexts_noPreview: 'This message has no body content',
  searchPlaceHolder: 'Search for a person, chat, or channel',
  searchPeoples: 'People',
  searchChannels: 'Channels',
  searchChatsAndGroups: 'Chats and meetings',
  emailProps_msgBody: "I'd like to share this with you",
  emailShareEmail: 'Shared Email',

  successPage_title: 'Your message is on its way to',
  successPage_notification: 'You may need to check your browser',
  errorPage_genericTitle: 'Something went wrong',
  errorPage_genericBody: 'An unexpected error has occurred. Please try again later.',
  errorPage_title: 'Oops, unable to share',
  errorPage_body: `Don"t worry, the file exists in`,
  errorPage_linkCopied: 'Link was copied!',

  // emailProps
  emailProps_from: 'From',
  emailProps_to: 'To',
  emailProps_noSubject: '(no subject)',

  //Email Header Mapping
  createEmailHeaderMappingTeam_title: 'Create an email view in this team channel',
  createEmailHeaderMappingTeam_checkmark1: 'Emails saved to this team channel will automatically include metadata',
  createEmailHeaderMappingSite_title: 'Create an email view in this library',
  createEmailHeaderMappingSite_checkmark1: 'Emails saved to this library will automatically include metadata',
  createEmailHeaderMapping_checkmark2: 'A view will be created with email detail columns (Name, To, From, etc.)',
  createEmailHeaderMapping_checkmark3:
    'Modify email view anytime in the settings under the email header mapping section',
  createEmailHeaderMapping_checkboxText: "Don't ask again for this location",
  createEmailHeaderMapping_primary_button: 'Create view',
  createEmailHeaderMapping_secondary_button: 'Not now',
  createEmailHeaderMapping_success: 'Email view created',
  createEmailHeaderMapping_error: 'Unable to create email view',
  emailHeaderMappingFailed: 'Email header mapping failed',
  // manageLists
  manageLists_clear: 'Clear',
  manageLists_noResults: "We couldn't find any results for '<%= name %>'",

  // uploadIndicator
  cancel_upload: 'Cancel saving',
  upload_canceled: 'Saving canceled',
  upload_complete: '1 item saved!',
  uploading_one_item: 'Saving 1 item',
  fail_upload: '<%= count %>  item failed to save',
  progress_uploaded: 'Saving <%= count %> of <%= totals %> items',
  items_uploaded: '<%= count %> items saved',
  unable_to_upload: 'Upload blocked, still in progress',

  // actiosNames
  actionsNames_undo: 'Undo',
  actionsNames_goBack: 'Go back',
  actionsNames_ok: 'Got it',
  actionsNames_showAllResults: 'Show more results',
  actionsNames_searchAllLocation: 'Search all locations',
  actionsNames_showMoreFilters: 'Show more filters',
  actionsNames_showLessFilters: 'Show less filters',
  actionsNames_breadcrumbsAction: 'Go to parent location',
  actionsNames_backToTop: 'Back to top',
  actionsNames_close: 'Close',

  // tooltip
  tooltip_shareDisabled: 'Please select a chat or channel to enable sharing',
  tooltip_chatAndFilesSearch: 'This feature is coming soon!',

  // QuickSearchFilters
  quickSearchFilters_sharePoint: 'SharePoint',
  quickSearchFilters_oneDrive: 'OneDrive',
  quickSearchFilters_teams: 'Teams',
  quickSearchFilters_team: 'Team',
  quickSearchFilters_channel: 'Channel',
  quickSearchFilters_site: 'Site',
  quickSearchFilters_library: 'Library',
  quickSearchFilters_folder: 'Folder',
  quickSearchFilters_allFiles: 'All files',
  quickSearchFilters_word: 'Word',
  quickSearchFilters_powerPoint: 'PowerPoint',
  quickSearchFilters_excel: 'Excel',
  quickSearchFilters_pdf: 'PDF',
  quickSearchFilters_email: 'Email',

  // dateFormat
  dateFormat_justNow: 'Just now',
  dateFormat_halfAnHour: '30 mins',
  dateFormat_twoHoursAgo: '2 hours ago',
  dateFormat_today: 'Today at',
  dateFormat_yesterday: 'Yesterday at',
  dateFormat_at: 'at',

  // Onbarding
  onBoarding_welcomePageTitle: 'Welcome to New harmon.ie',
  onBoarding_welcomePageMessage:
    'Turn Outlook into a central hub for all your files and locations across Microsoft 365',
  onBoarding_uploadTitle: 'Upload emails and files',
  onBoarding_uploadMessage: 'Drag-and-drop or click to save important content to SharePoint, Teams and OneDrive.',
  onBoarding_shareTitle: 'Share links and attachments',
  onBoarding_shareMessage: 'Share anything from Microsoft 365 in a simple click, keeping everyone in the loop',
  onBoarding_searchTitle: 'Search everything at once',
  onBoarding_searchMessage: "It's never been easier to find files, emails or locations",
  onBoarding_teamsPageTitle: 'Which Teams are you working with?',
  onBoarding_teamsPageMessage:
    'Select the Teams you save to or access data from. These will appear on your home screen',
  onBoarding_sharePointPageTitle: 'Which sites are you working with?',
  onBoarding_sharePointPageMessage:
    'Select the SharePoint sites you save to or access data from. These will appear on your home screen',
  onBoarding_DoneTitle: 'All set up!',
  onBoarding_DoneLoadingTitle: 'Loading your data!',

  onBoarding_requiredNewPremission: 'Need approval for new permissions',
  onBoarding_sharePointPageHeader: 'Set up SharePoint',
  onBoarding_teamsPageHeader: 'Set up Teams',

  onBoarding_selectedTeams: 'teams selected',
  onBoarding_selectedSites: 'sites selected',
  onBoarding_loadMore: 'Load more',
  onBoarding_skip: 'Skip',
  onBoarding_next: 'Next',
  onBoarding_done: 'Done',
  onBoarding_getStarted: 'Get started',
  onBoarding_selectAllSites: 'Select all sites',
  onBoarding_selectAllTeams: 'Select all teams',
  onBoarding_unselectAllSites: 'Unselect all sites',
  onBoarding_unselectAllTeams: 'Unselect all teams',
  onBoarding_unselectAll: 'Unselect all',
  onBoarding_selectAll: 'Select all',

  // Select items modal
  selectItemsModal_title: 'Select items to save',
  selectItemsModal_description: 'Emails and attachments will be saved separately in the chosen location.',
  selectItemsModal_itemsSelected: 'ITEMS SELECTED',
  selectItemsModal_selectAll: 'Select all',
  selectItemsModal_unselectAll: 'Unselect all',
  selectItemsModal_primaryButton: 'Save',
  selecItemsModal_fetchingEmails: 'Preparing email data...',
  selecItemsModal_unable_to_save: 'Unable to save. Please try again',
  selecItemsModal_item_size: 'Item size',

  // errorMessages
  errorMessages_drilldownNavigation: 'Navigation to this location failed',
  errorMessages_cannotGetItems: 'Cannot retrieve items',
  errorMessages_tryAgain: 'Refresh to try again or contact your administrator.',

  // userManage (teams and sites)
  userManage_teams_title: 'Manage Teams',
  userManage_teams_description: 'Select which teams will be visible in the home screen',
  userManage_teams_availableItems: 'Available teams',
  userManage_teams_addedItems: 'Added teams',
  userManage_teams_placeholderSearch: 'Search Teams',
  userManage_teams_showMoreHint: 'Use the teams search to see more results',
  userManage_teams_emptyState: 'There are currently no teams',
  userManage_teams_errorMessage: 'Cannot retrieve teams',
  userManage_sites_title: 'Manage Sites',
  userManage_sites_description: 'Select which sites will be visible in the home screen',
  userManage_sites_show_teams: 'Include sites connected to Microsoft Teams',
  userManage_sites_availableItems: 'Available sites',
  userManage_sites_addedItems: 'Added Sites',
  userManage_sites_placeholderSearch: 'Search Sites',
  userManage_sites_showMoreHint: 'Use the sites search to see more results',
  userManage_sites_emptyState: 'There are currently no sites',
  userManage_sites_errorMessage: 'Cannot retrieve sites',

  // userSetting
  userSetting_resetdialog_warningTitle: 'Reset user settings',
  userSetting_resetdialog_warningBody: "Are you sure you want to reset user settings? This can't be undone.",
  userSetting_resetdialog_secondaryAction: 'Cancel',
  userSetting_resetdialog_primaryAction: 'Reset',
  userSetting_cleardialog_warningTitle: 'Clear search history',
  userSetting_cleardialog_warningBody: 'Are you sure you want to clear your search history? This can’t be undone.',
  userSetting_cleardialog_secondaryAction: 'Cancel',
  userSetting_cleardialog_primaryAction: 'Clear',
  userSetting_resetTitle: 'Reset settings to default',
  userSetting_signoutdialog_warningTitle: 'Sign out',
  userSetting_signoutdialog_warningBody: 'Are you sure you want to sign out?',
  userSetting_signoutdialog_secondaryAction: 'Cancel',
  userSetting_signoutdialog_primaryAction: 'Sign out',
  userSetting_signOutTitle: 'Disconnect from harmon.ie',
  userSetting_actionName: ' Reset',
  userSetting_documentsTitle: 'Opening documents',
  userSetting_documentsDescription: 'How would you like to open M365 documents?',
  userSetting_emailNameTitle: 'Saved email name',
  userSetting_emailNamesDescription: 'How would you like to name saved emails',
  userSetting_emailNamesDefault: 'Subject only',
  userSetting_emailNamesAdvanced: 'Subject, sender and date',

  userSetting_ehmTitle: 'Email header mapping view',
  userSetting_ehmDescription: 'Create email view and classify emails with email header fields',
  userSetting_ehmCheckboxAllLocations: 'Teams and SharePoint',
  userSetting_ehmCheckboxTeams: 'Teams only',
  userSetting_ehmCheckboxNone: 'None',

  userSetting_themeSelectorTitle: 'Theme',
  userSetting_themeSelectorSystem: 'System',
  userSetting_themeSelectorLight: 'Light',
  userSetting_themeSelectorDark: 'Dark',

  userSetting_treeSettingFileTitle: 'Files to display on tree view',
  userSetting_treeSettingFileDescription:
    'How many files would you like to see when expanding locations on the home screen?',

  userSetting_treeSettingHistoryTitle: 'Tree view history',
  userSetting_treeSettingHistoryDescription: 'Remember expanded sections',

  userSetting_addCategoryTitle: 'Saved email labels in Outlook',
  userSetting_addCategoryDescription: 'Add location category to saved emails',

  // deleteDialog
  deleteDialog_warningTitle: 'Delete item',
  deleteDialog_warningBody: 'Are you sure you want to delete?',
  deleteDialog_actionName: 'Delete',
  deleteDialog_cancel: 'Cancel',

  // replaceDialog
  replaceDialogTitle: 'A file with this name already exists',
  replaceDialogMeesage: 'Would you like to replace it, or keep both',
  replaceDialogPrimary: 'Replace',
  replaceDialogSecondry: 'Keep both',
  replaceDialogCheckbox: 'Apply to all items',

  // emptyStateTitle
  emptyStateTitle_favorites: 'No favorites yet',
  emptyStateTitle_recent: 'Life is going to get easier!',
  emptyStateTitle_sharedwithme: 'Life is going to get easier!',
  emptyStateTitle_teams: 'Add your Teams',
  emptyStateTitle_chatsAndMeetings: 'No files from chats and meetings',
  emptyStateTitle_sharePoint: 'Add your Sites',
  emptyStateTitle_oneDrive: 'No items here yet!',
  emptyStateTitle_drilldown: 'This location is empty',
  emptyStateTitle_drilldownWithView: 'No items found in this view',
  emptyStateTitle_noSearchResultInLocation: 'No search results in this location',
  emptyStateTitle_globalNoSearchResult: 'No search results',
  emptyStateTitle_searchResultLastItem: 'Didn’t find what you looked for?',

  // emptyStateMessage
  emptyStateMessage_favorites: 'Click the star icon next to any file or location and it will show up here',
  emptyStateMessage_recent: 'Your recent files and folders will be displayed here',
  emptyStateMessage_sharedwithme: 'Files and folders shared with you will be displayed here',
  emptyStateMessage_teams:
    'Teams files and locations will be displayed here. Add some Teams that are important to you!',
  emptyStateMessage_chatsAndMeetings: 'Files you send and receive in Teams chats and meetings will show up here',
  emptyStateMessage_sharePoint:
    'Sites files and locations will be displayed here. Add some Sites that are important to you!',
  emptyStateMessage_oneDrive: 'Save something to OneDrive to see your files and folders here',
  emptyStateMessage_drilldown: 'Drag & drop here or',
  emptyStateMessage_noSearchResultInLocation: 'Try to search in all Microsoft 365 for more results',
  emptyStateMessage_searchResultLastItem: 'Try to search in all Microsoft 365 for more results',
  emptyStateMessage_quickSearchWithResults: 'Try to search in all Microsoft 365 for more results',

  // ActionDefaults
  actionDefaults_errorMessage: 'There was an error with the last action',
  actionDefaults_errorTitle: 'Something went wrong',

  // RemoveItem
  removeItem_team_errorTitle: 'Unable to remove team',
  removeItem_team_onSuccessTitle: 'Team removed',
  removeItem_team_actionTitle: 'Remove',
  removeItem_item_actionTitle: 'Delete',
  removeItem_site_errorTitle: 'Unable to remove site',
  removeItem_site_onSuccessTitle: 'Site removed',
  removeItem_insight_errorTitle: 'Unable to delete file',
  removeItem_insight_onSuccessTitle: 'File deleted',
  removeItem_document_errorTitle: 'Unable to delete file',
  removeItem_document_onSuccessTitle: 'File deleted',
  removeItem_folder_errorTitle: 'Unable to delete folder',
  removeItem_folder_onSuccessTitle: 'Folder deleted',

  signIn: 'Sign in with Microsoft',
  signOut: 'Sign out',
  logOutTitle: 'Logging you out from Microsoft 365',
  logOutMessage: 'Disconnecting...',
  allLocations: 'ALL LOCATIONS',
  collapseAll: 'Collapse all locations',

  // ActionToolTip
  actionToolTip_openMenu: 'Open menu',
  actionToolTip_simpleView: 'Simple',
  actionToolTip_detailsView: 'Details (columns)',

  actionToolTip_upload: 'Upload files',
  actionToolTip_emptyStateUpload: 'Upload files',
  actionToolTip_refresh: 'Refresh',
  actionToolTip_share: 'Share',
  actionToolTip_newItem: 'Add new',
  actionToolTip_copyAddress: 'Copy address',
  actionToolTip_openInBrowser: 'Open in browser',
  actionToolTip_openInBrowserApp: 'Open in browser app',
  actionToolTip_openInApp: 'Open in desktop app',
  actionToolTip_download: 'Download',
  actionToolTip_addFavorite: 'Favorite',
  actionToolTip_removeFavorite: 'Unfavorite',
  actionToolTip_manageItemCount: 'Manage...',
  actionToolTip_manage: 'Manage',
  actionToolTip_viewSetting: 'Manage items',
  actionToolTip_itemCountTitle: 'Item amount',
  actionToolTip_itemCountDescription: 'Choose number of items to display',
  actionToolTip_saveEmail: 'Save email',
  actionToolTip_saveEmailDisabled: 'Select one email to save',
  actionToolTip_openInTeamsApp: 'Open in Teams app',
  actionToolTip_openContainingLocation: 'Go to parent location',
  actionToolTip_moreActions: 'More actions',
  actionToolTip_favorite: 'Favorite',
  actionToolTip_copyLink: 'Copy link (Ctrl+L)',
  actionToolTip_copyLinkShort: 'Copy link',
  actionToolTip_emailWithLink: 'Email with link (Ctrl+E)',
  actionToolTip_addLink: 'Add as link',
  actionToolTip_addAttachment: 'Add as an attachment',
  actionToolTip_emailWithLinkShort: 'Email with link',
  actionToolTip_shareToTeams: 'Share to Teams (Ctrl+T)',
  actionToolTip_shareToTeamsShort: 'Share to Teams',
  actionToolTip_editOnSharepoint: 'Edit details (in browser)',
  actionToolTip_reorderA2Z: 'Reorder (A-Z)',
  actionToolTip_reorderZ2A: 'Reorder (Z-A)',
  actionToolTip_searchAllLocations: 'Search all locations',
  actionToolTip_newFolder: 'Folder',
  actionToolTip_newWordDocument: 'Word document',
  actionToolTip_newExcelDocument: 'Excel workbook',
  actionToolTip_newLinkDocument: 'Link',
  actionToolTip_newPowerPointDocument: 'PowerPoint presentation',
  actionToolTip_new: 'New',

  // ToolbarActions
  toolbarActions_home: 'Home',
  toolbarActions_back: 'Back',
  toolbarActions_sort: 'Sort',
  toolbarActions_saveEmail: 'Save email',

  //Lables
  categoryLabel: '[Microsoft 365] Saved in',

  // AllLocatiosToolbar
  allLocatiosToolbar_getSupport: 'Get support',
  allLocatiosToolbar_gotoSettings: 'Settings',
  allLocatiosToolbar_feedback: 'Send feedback',
  allLocatiosToolbar_feedbackTitle: 'Send feedback',

  // Email Preview
  emailPreview_title: 'Save selected email',
  emailsPreview_title: 'Save selected emails',
  // FilePreview
  filePreview_sharedInChat: 'shared with you in a Teams chat',
  filePreview_youSharedInChat: 'You shared in a Teams chat',
  filePreview_youSent: 'You sent this',
  filePreview_youShared: 'You shared this',
  filePreview_shared: 'shared with you',
  filePreview_sentThis: 'sent this',
  filePreview_recentlyOpened: 'You recently opened this',
  filePreview_editedThis: 'edited this',
  filePreview_recentlyEdited: 'You recently edited this',
  filePreview_emailAttachment: 'Email attachment in Outlook',

  // Slider
  SliderFilter: 'Filter',
  SliderFilterOn: 'Show email attachments',

  // NewItemDialogTexts and ItemDialogTexts (Merged for brevity)
  newItemDialogTexts_defaults_createButton: 'Create',
  newItemDialogTexts_document_subTitle: 'File name',
  newItemDialogTexts_document_successNote: 'File created',
  newItemDialogTexts_document_errorTitle: 'File not created',
  newItemDialogTexts_document_errorMessage: 'Unable to create file',
  newItemDialogTexts_folder_subTitle: 'Folder name',
  newItemDialogTexts_folder_successNote: 'Folder created',
  newItemDialogTexts_folder_errorTitle: 'Folder not created',
  newItemDialogTexts_folder_errorMessage: 'Unable to create folder',
  newItemDialogTexts_renameFile_title: 'Rename file',
  newItemDialogTexts_renameFile_subTitle: 'File name',
  newItemDialogTexts_renameFile_successNote: 'File renamed',

  DialogTexts_renameFile_errorTitle: 'File not renamed',
  newItemDialogTexts_renameFile_errorMessage: 'Unable to rename file',
  newItemDialogTexts_renameFile_createButton: 'Rename',
  newItemDialogTexts_renameFolder_title: 'Rename folder',
  newItemDialogTexts_renameFolder_subTitle: 'Folder name',
  newItemDialogTexts_renameFolder_successNote: 'Folder renamed',
  newItemDialogTexts_renameFolder_errorTitle: 'Folder not renamed',
  newItemDialogTexts_renameFolder_errorMessage: 'Unable to rename folder',
  newItemDialogTexts_renameFolder_createButton: 'Rename',

  // ItemDialogTexts
  itemDialogTexts_folderTitle: 'New folder',
  itemDialogTexts_folderSubTitle: 'Folder name',
  itemDialogTexts_fileSubTitle: 'File name',
  itemDialogTexts_urlPath: 'http://url.com',
  itemDialogTexts_defaultUrl: 'http://',
  itemDialogTexts_wordDocumentTitle: 'New Word document',
  itemDialogTexts_linkDocumentTitle: 'New link',
  itemDialogTexts_excelDocumentTitle: 'New Excel workbook',
  itemDialogTexts_powerPointDocumentTitle: 'New PowerPoint presentation',
  itemDialogTexts_cancel: 'Cancel',
  itemDialogTexts_itemExistError: 'An item with this name already exist',
  itemDialogTexts_urlNotValid: 'The URL is not valid',
  itemDialogTexts_itemNameNotValid: 'Common special characters cannot be used',
  itemDialogTexts_requiredField: 'Required field',

  // SortMenuActions
  sortMenuActions_name: 'Name',
  sortMenuActions_dateModified: 'Date modified',
  sortMenuActions_dateCreated: 'Date created',
  sortMenuActions_ascending: 'Ascending',
  sortMenuActions_descending: 'Descending',

  // DoubleClickAction
  doubleClickAction_openInApp: 'Desktop app',
  doubleClickAction_openInBrowserApp: 'Browser app',
  doubleClickAction_openInBrowser: 'Open in browser',
  doubleClickAction_askEveryTime: 'Ask me every time',
  doubleClickAction_downloads: 'Download',
  doubleClickAction_dialogTitle: 'How would you like to open this file?',
  doubleClickAction_appDialogTitle: 'How would you like to open this document?',
  doubleClickAction_checkboxLabel: 'Remember my choice (you can change this later)',

  // NodeNames
  nodeNames_favorites: 'Favorites',
  nodeNames_recent: 'Recent',
  nodeNames_sharedWithMe: 'Shared with me',
  nodeNames_teams: 'Teams',
  nodeNames_search: 'Searching in',
  nodeNames_defaultSearch: 'Search results',
  nodeNames_sharePoint: 'SharePoint',
  nodeNames_oneDrive: 'OneDrive',
  nodeNames_viewAllFiles: ' View all files',
  nodeNames_teamsChatFiles: 'Chats and meetings',
  nodeNames_searchAllLocations: 'Search all locations',

  // Notifications

  notifications_saveEmailStart: 'Saving...',
  notifications_emailWithAttachmentStart: 'Adding attachment...',
  notifications_emailWithAttachmentDone: 'Attachment added',
  notifications_saveEmailDone: 'Saved',
  notifications_copyLink: 'Link copied',
  notifications_addToFavorite: 'Added to favorites',
  notifications_renovedFromFavorite: 'Removed from favorites',
  notifications_openInBrowser: 'Opening in browser...',
  notifications_doubleClick: 'Opening document',

  dragAndDropFailedTitle: 'Unable to save email',
  dragAndDropItemNotSupportedMeesage: 'This item type is not supported.',
  dragAndDropErrorMessage: 'Retry with drag and drop or save using quick actions.',
  // NotificationsError
  notificationsError_uploadFailedTitle: 'Upload failed.',
  notificationsError_saveEmailFailedTitle: 'Save failed.',
  notificationsError_uploadFailedMessage: 'There was an error uploading.',
  officeSelectionErrorTitle: 'Office error',
  officeSelectionErrorMessage: 'Please restart the addin',
  notificationsError_saveEmailFailedMessage: 'There was an error saving.',
  notificationsError_drilldownFailedTitle: 'Location not available',
  notificationsError_drilldownFailedMessage: 'You are currently unable to access this location',
  notificationsError_emailWithAttachmentFailedTitle: 'Unable to add',
  notificationsError_emailWithAttachmentFailedMessage:
    'This file could not be added as an attachment. Try sending as a link instead.',

  site: 'site',
  list: 'document library',
  folder: 'folder',
  channel: 'channel',
  team: 'team',
  location: 'location',
  RootSite: ' root site',
  ShareToTeamsIntroStartWith: "I'd like to share",
  ShareToTeamsIntroEndWith: 'with you:',
  quickSearchsearchPlaceholder: 'Search this',
  quickSearchclearTooltipText: 'Clear',
  quickSearchclearFilterText: 'Click to remove',
  quickSearchhistoryTitle: 'HISTORY',
  quickSearchoneDrive: 'Search OneDrive',
  quickSearchchatAndFiles: 'Search chats and meetings',
  quickSearchgeneralSearch: 'Search Microsoft 365',
  results_countResults: 'Results',

  viewsAction_title: 'Views',
  viewsAction_changeViewSuccessMessage: `Switched to '<%= viewname %>'`,
  advancedFilterAction_title: 'Advanced options',
};
export const fr: typeof en = {
  //EHM Dialog
  ehm_title: 'Create an email view in this team',
  ehm_checkmark1: 'Emails saved to this team will automatically include metadata',
  ehm_checkmark2: 'A view will be created with email detail columns (subject, to, from, etc.)',
  ehm_checkboxText: "Don't ask again for this location",
  ehm_primary_button: 'Create view',
  ehm_secondary_button: 'Not now',
  // share to teams dialog
  shareDialogTexts_titleEnd: 'Microsoft Teams',
  shareDialogTexts_shareTo: 'Share to',
  shareDialogTexts_viewMailText: 'View email',
  shareDialogTexts_notifyText: 'Notify everyone in this channel',
  shareDialogTexts_noPreview: 'This message has no body content',
  searchPlaceHolder: 'Search for a person, chat, or channel',
  searchPeoples: 'People',
  searchChannels: 'Channels',
  searchChatsAndGroups: 'Chats and meetings',
  emailProps_msgBody: "I'd like to share this with you",
  emailShareEmail: 'Shared Email',

  successPage_title: 'Your message is on its way to',
  successPage_notification: 'You may need to check your browser',
  errorPage_genericTitle: 'Something went wrong',
  errorPage_genericBody: 'An unexpected error has occurred. Please try again later.',
  errorPage_title: 'Oops, unable to share',
  errorPage_body: `Don"t worry, the file exists in`,
  errorPage_linkCopied: 'Link was copied!',

  // emailProps
  emailProps_from: 'From',
  emailProps_to: 'To',
  emailProps_noSubject: '(no subject)',

  //Email Header Mapping
  createEmailHeaderMappingTeam_title: 'Create an email view in this team channel',
  createEmailHeaderMappingTeam_checkmark1: 'Emails saved to this team channel will automatically include metadata',
  createEmailHeaderMappingSite_title: 'Create an email view in this library',
  createEmailHeaderMappingSite_checkmark1: 'Emails saved to this library will automatically include metadata',
  createEmailHeaderMapping_checkmark2: 'A view will be created with email detail columns (Name, To, From, etc.)',
  createEmailHeaderMapping_checkmark3:
    'Modify email view anytime in the settings under the email header mapping section',
  createEmailHeaderMapping_checkboxText: "Don't ask again for this location",
  createEmailHeaderMapping_primary_button: 'Create view',
  createEmailHeaderMapping_secondary_button: 'Not now',
  createEmailHeaderMapping_success: 'Email view created',
  createEmailHeaderMapping_error: 'Unable to create email view',
  emailHeaderMappingFailed: 'Email header mapping failed',

  // manageLists
  manageLists_clear: 'Clear',
  manageLists_noResults: "We couldn't find any results for '<%= name %>'",

  // uploadIndicator
  cancel_upload: 'Cancel saving',
  upload_canceled: 'Saving canceled',
  upload_complete: '1 item saved!',
  uploading_one_item: 'Saving 1 item',
  fail_upload: '<%= count %>  item failed to save',
  progress_uploaded: 'Saving <%= count %> of <%= totals %> items',
  items_uploaded: '<%= count %> items saved',
  unable_to_upload: 'Upload blocked, still in progress',

  // actiosNames
  actionsNames_undo: 'Undo',
  actionsNames_goBack: 'Go back',
  actionsNames_ok: 'Got it',
  actionsNames_showAllResults: 'Show more results',
  actionsNames_searchAllLocation: 'Search all locations',
  actionsNames_showMoreFilters: 'Show more filters',
  actionsNames_showLessFilters: 'Show less filters',
  actionsNames_breadcrumbsAction: 'Go to parent location',
  actionsNames_backToTop: 'Back to top',
  actionsNames_close: 'Close',

  // tooltip
  tooltip_shareDisabled: 'Please select a chat or channel to enable sharing',
  tooltip_chatAndFilesSearch: 'This feature is coming soon!',

  // QuickSearchFilters
  quickSearchFilters_sharePoint: 'SharePoint',
  quickSearchFilters_oneDrive: 'OneDrive',
  quickSearchFilters_teams: 'Teams',
  quickSearchFilters_team: 'Team',
  quickSearchFilters_channel: 'Channel',
  quickSearchFilters_site: 'Site',
  quickSearchFilters_library: 'Library',
  quickSearchFilters_folder: 'Folder',
  quickSearchFilters_allFiles: 'All files',
  quickSearchFilters_word: 'Word',
  quickSearchFilters_powerPoint: 'PowerPoint',
  quickSearchFilters_excel: 'Excel',
  quickSearchFilters_pdf: 'PDF',
  quickSearchFilters_email: 'Email',

  // dateFormat
  dateFormat_justNow: 'Just now',
  dateFormat_halfAnHour: '30 mins',
  dateFormat_twoHoursAgo: '2 hours ago',
  dateFormat_today: 'Today at',
  dateFormat_yesterday: 'Yesterday at',
  dateFormat_at: 'at',

  // welcomePage
  onBoarding_welcomePageTitle: 'Welcome to New harmon.ie',
  onBoarding_welcomePageMessage:
    'Turn Outlook into a central hub for all your files and locations across Microsoft 365',
  onBoarding_uploadTitle: 'Upload emails and files',
  onBoarding_uploadMessage: 'Drag-and-drop or click to save important content to SharePoint, Teams and OneDrive.',
  onBoarding_shareTitle: 'Share links and attachments',
  onBoarding_shareMessage: 'Share anything from Microsoft 365 in a simple click, keeping everyone in the loop',
  onBoarding_searchTitle: 'Search everything at once',
  onBoarding_searchMessage: "It's never been easier to find files, emails or locations",
  onBoarding_teamsPageTitle: 'Which Teams are you working with?',
  onBoarding_teamsPageMessage:
    'Select the Teams you save to or access data from. These will appear on your home screen',
  onBoarding_sharePointPageTitle: 'Which sites are you working with?',
  onBoarding_sharePointPageMessage:
    'Select the SharePoint sites you save to or access data from. These will appear on your home screen',
  onBoarding_DoneTitle: 'All set up!',
  onBoarding_DoneLoadingTitle: 'Loading your data!',
  onBoarding_requiredNewPremission: 'Need approval for new permissions',

  onBoarding_sharePointPageHeader: 'Set up SharePoint',
  onBoarding_teamsPageHeader: 'Set up Teams',

  onBoarding_selectedTeams: 'teams selected',
  onBoarding_selectedSites: 'sites selected',
  onBoarding_loadMore: 'Load more',
  onBoarding_skip: 'Skip',
  onBoarding_next: 'Next',
  onBoarding_done: 'Done',
  onBoarding_getStarted: 'Get started',
  onBoarding_selectAllSites: 'Select all sites',
  onBoarding_selectAllTeams: 'Select all teams',
  onBoarding_unselectAllSites: 'Unselect all sites',
  onBoarding_unselectAllTeams: 'Unselect all teams',
  onBoarding_unselectAll: 'Unselect all',
  onBoarding_selectAll: 'Select all',

  // Select items modal
  selectItemsModal_title: 'Select items to save',
  selectItemsModal_description: 'Emails and attachments will be saved separately in the chosen location.',
  selectItemsModal_itemsSelected: '<%= selectedItems %> of <%= totalItems %> ITEMS SELECTED',
  selectItemsModal_selectAll: 'Select all',
  selectItemsModal_unselectAll: 'Unselect all',
  selectItemsModal_primaryButton: 'Save',
  selecItemsModal_fetchingEmails: 'Preparing email data...',
  selecItemsModal_unable_to_save: 'Unable to save. Please try again',
  selecItemsModal_item_size: 'Item size',

  // errorMessages
  errorMessages_drilldownNavigation: 'Navigation to this location failed',
  errorMessages_cannotGetItems: 'Cannot retrieve items',
  errorMessages_tryAgain: 'Refresh to try again or contact your administrator.',

  // userManage (teams and sites)
  userManage_teams_title: 'Manage Teams',
  userManage_teams_description: 'Select which teams will be visible in the home screen',
  userManage_teams_availableItems: 'Available teams',
  userManage_teams_addedItems: 'Added teams',
  userManage_teams_placeholderSearch: 'Search Teams',
  userManage_teams_showMoreHint: 'Use the teams search to see more results',
  userManage_teams_emptyState: 'There are currently no teams',
  userManage_teams_errorMessage: 'Cannot retrieve teams',
  userManage_sites_title: 'Manage Sites',
  userManage_sites_description: 'Select which sites will be visible in the home screen',
  userManage_sites_show_teams: 'Include sites connected to Microsoft Teams',
  userManage_sites_availableItems: 'Available sites',
  userManage_sites_addedItems: 'Added Sites',
  userManage_sites_placeholderSearch: 'Search Sites',
  userManage_sites_showMoreHint: 'Use the sites search to see more results',
  userManage_sites_emptyState: 'There are currently no sites',
  userManage_sites_errorMessage: 'Cannot retrieve sites',

  // userSetting
  userSetting_resetdialog_warningTitle: 'Reset user settings',
  userSetting_resetdialog_warningBody: "Are you sure you want to reset user settings? This can't be undone.",
  userSetting_resetdialog_secondaryAction: 'Cancel',
  userSetting_resetdialog_primaryAction: 'Reset',
  userSetting_cleardialog_warningTitle: 'Clear search history',
  userSetting_cleardialog_warningBody: 'Are you sure you want to clear your search history? This can’t be undone.',
  userSetting_cleardialog_secondaryAction: 'Cancel',
  userSetting_cleardialog_primaryAction: 'Clear',
  userSetting_resetTitle: 'Reset settings to default',
  userSetting_signoutdialog_warningTitle: 'Sign out',
  userSetting_signoutdialog_warningBody: 'Are you sure you want to sign out?',
  userSetting_signoutdialog_secondaryAction: 'Cancel',
  userSetting_signoutdialog_primaryAction: 'Sign out',
  userSetting_signOutTitle: 'Disconnect from harmon.ie',
  userSetting_actionName: ' Reset',
  userSetting_documentsTitle: 'Opening documents',
  userSetting_documentsDescription: 'How would you like to open M365 documents?',
  userSetting_emailNameTitle: 'Saved email name',
  userSetting_emailNamesDescription: 'How would you like to name saved emails',
  userSetting_emailNamesDefault: 'Subject only',
  userSetting_emailNamesAdvanced: 'Subject, sender and date',

  userSetting_ehmTitle: 'Email header mapping view',
  userSetting_ehmDescription: 'Create email view and classify emails with email header fields',
  userSetting_ehmCheckboxAllLocations: 'Teams and SharePoint',
  userSetting_ehmCheckboxTeams: 'Teams only',
  userSetting_ehmCheckboxNone: 'None',

  userSetting_themeSelectorTitle: 'Theme',
  userSetting_themeSelectorSystem: 'System',
  userSetting_themeSelectorLight: 'Light',
  userSetting_themeSelectorDark: 'Dark',

  userSetting_treeSettingFileTitle: 'Files to display on tree view',
  userSetting_treeSettingFileDescription:
    'How many files would you like to see when expanding locations on the home screen?',

  userSetting_treeSettingHistoryTitle: 'Tree view history',
  userSetting_treeSettingHistoryDescription: 'Remember expanded sections',

  userSetting_addCategoryTitle: 'Saved email labels in Outlook',
  userSetting_addCategoryDescription: 'Add location category to saved emails',

  // deleteDialog
  deleteDialog_warningTitle: 'Delete item',
  deleteDialog_warningBody: 'Are you sure you want to delete?',
  deleteDialog_actionName: 'Delete',
  deleteDialog_cancel: 'Cancel',

  // replaceDialog
  replaceDialogTitle: 'A file with this name already exists',
  replaceDialogMeesage: 'Would you like to replace it, or keep both',
  replaceDialogPrimary: 'Replace',
  replaceDialogSecondry: 'Keep both',
  replaceDialogCheckbox: 'Apply to all items',

  // emptyStateTitle
  emptyStateTitle_favorites: 'No favorites yet',
  emptyStateTitle_recent: 'Life is going to get easier!',
  emptyStateTitle_sharedwithme: 'Life is going to get easier!',
  emptyStateTitle_teams: 'Add your Teams',
  emptyStateTitle_chatsAndMeetings: 'No files from chats and meetings',
  emptyStateTitle_sharePoint: 'Add your Sites',
  emptyStateTitle_oneDrive: 'No items here yet!',
  emptyStateTitle_drilldown: 'This location is empty',
  emptyStateTitle_drilldownWithView: 'No items found in this view',
  emptyStateTitle_noSearchResultInLocation: 'No search results in this location',
  emptyStateTitle_globalNoSearchResult: 'No search results',
  emptyStateTitle_searchResultLastItem: 'Didn’t find what you looked for?',

  // emptyStateMessage
  emptyStateMessage_favorites: 'Click the star icon next to any file or location and it will show up here',
  emptyStateMessage_recent: 'Your recent files and folders will be displayed here',
  emptyStateMessage_sharedwithme: 'Files and folders shared with you will be displayed here',
  emptyStateMessage_teams:
    'Teams files and locations will be displayed here. Add some Teams that are important to you!',
  emptyStateMessage_chatsAndMeetings: 'Files you send and receive in Teams chats and meetings will show up here',
  emptyStateMessage_sharePoint:
    'Sites files and locations will be displayed here. Add some Sites that are important to you!',
  emptyStateMessage_oneDrive: 'Save something to OneDrive to see your files and folders here',
  emptyStateMessage_drilldown: 'Drag & drop here or',
  emptyStateMessage_noSearchResultInLocation: 'Try to search in all Microsoft 365 for more results',
  emptyStateMessage_searchResultLastItem: 'Try to search in all Microsoft 365 for more results',
  emptyStateMessage_quickSearchWithResults: 'Try to search in all Microsoft 365 for more results',

  // ActionDefaults
  actionDefaults_errorMessage: 'There was an error with the last action',
  actionDefaults_errorTitle: 'Something went wrong',

  // RemoveItem
  removeItem_team_errorTitle: 'Unable to remove team',
  removeItem_team_onSuccessTitle: 'Team removed',
  removeItem_team_actionTitle: 'Remove',
  removeItem_item_actionTitle: 'Delete',
  removeItem_site_errorTitle: 'Unable to remove site',
  removeItem_site_onSuccessTitle: 'Site removed',
  removeItem_insight_errorTitle: 'Unable to delete file',
  removeItem_insight_onSuccessTitle: 'File deleted',
  removeItem_document_errorTitle: 'Unable to delete file',
  removeItem_document_onSuccessTitle: 'File deleted',
  removeItem_folder_errorTitle: 'Unable to delete folder',
  removeItem_folder_onSuccessTitle: 'Folder deleted',

  signIn: 'Sign in with Microsoft',
  signOut: 'Sign out',
  logOutTitle: 'Logging you out from Microsoft 365',
  logOutMessage: 'Disconnecting...',
  allLocations: 'ALL LOCATIONS',
  collapseAll: 'Collapse all locations',

  // ActionToolTip
  actionToolTip_openMenu: 'Open menu',
  actionToolTip_simpleView: 'Simple',
  actionToolTip_detailsView: 'Details (columns)',
  actionToolTip_upload: 'Upload files',
  actionToolTip_emptyStateUpload: 'Upload files',

  actionToolTip_refresh: 'Refresh',
  actionToolTip_share: 'Share',
  actionToolTip_newItem: 'Add new',
  actionToolTip_copyAddress: 'Copy address',
  actionToolTip_openInBrowser: 'Open in browser',
  actionToolTip_openInBrowserApp: 'Open in browser app',
  actionToolTip_openInApp: 'Open in desktop app',
  actionToolTip_download: 'Download',
  actionToolTip_addFavorite: 'Favorite',
  actionToolTip_removeFavorite: 'Unfavorite',
  actionToolTip_manageItemCount: 'Manage...',
  actionToolTip_manage: 'Manage',
  actionToolTip_viewSetting: 'Manage items',
  actionToolTip_itemCountTitle: 'Item amount',
  actionToolTip_itemCountDescription: 'Choose number of items to display',
  actionToolTip_saveEmail: 'Save email',
  actionToolTip_saveEmailDisabled: 'Select one email to save',
  actionToolTip_openInTeamsApp: 'Open in Teams app',
  actionToolTip_openContainingLocation: 'Go to parent location',
  actionToolTip_moreActions: 'More actions',
  actionToolTip_favorite: 'Favorite',
  actionToolTip_copyLink: 'Copy link (Ctrl+L)',
  actionToolTip_copyLinkShort: 'Copy link',
  actionToolTip_emailWithLink: 'Email with link (Ctrl+E)',
  actionToolTip_addLink: 'Add as link',
  actionToolTip_addAttachment: 'Add as an attachment',
  actionToolTip_emailWithLinkShort: 'Email with link',
  actionToolTip_shareToTeams: 'Share to Teams (Ctrl+T)',
  actionToolTip_shareToTeamsShort: 'Share to Teams',
  actionToolTip_editOnSharepoint: 'Edit details (in browser)',
  actionToolTip_reorderA2Z: 'Reorder (A-Z)',
  actionToolTip_reorderZ2A: 'Reorder (Z-A)',
  actionToolTip_searchAllLocations: 'Search all locations',
  actionToolTip_newFolder: 'Folder',
  actionToolTip_newWordDocument: 'Word document',
  actionToolTip_newExcelDocument: 'Excel workbook',
  actionToolTip_newLinkDocument: 'Link',
  actionToolTip_newPowerPointDocument: 'PowerPoint presentation',
  actionToolTip_new: 'New',

  // ToolbarActions
  toolbarActions_home: 'Home',
  toolbarActions_back: 'Back',
  toolbarActions_sort: 'Sort',
  toolbarActions_saveEmail: 'Save email',

  //Lables
  categoryLabel: '[Microsoft 365] Saved in',

  // AllLocatiosToolbar
  allLocatiosToolbar_getSupport: 'Get support',
  allLocatiosToolbar_gotoSettings: 'Settings',
  allLocatiosToolbar_feedback: 'Send feedback',
  allLocatiosToolbar_feedbackTitle: 'Send feedback',

  // Email Preview
  emailPreview_title: 'Save selected email',
  emailsPreview_title: 'Save selected emails',
  // FilePreview
  filePreview_sharedInChat: 'shared with you in a Teams chat',
  filePreview_youSharedInChat: 'You shared in a Teams chat',
  filePreview_youSent: 'You sent this',
  filePreview_youShared: 'You shared this',
  filePreview_shared: 'shared with you',
  filePreview_sentThis: 'sent this',
  filePreview_recentlyOpened: 'You recently opened this',
  filePreview_editedThis: 'edited this',
  filePreview_recentlyEdited: 'You recently edited this',
  filePreview_emailAttachment: 'Email attachment in Outlook',

  // Slider
  SliderFilter: 'Filter',
  SliderFilterOn: 'Show email attachments',

  // NewItemDialogTexts and ItemDialogTexts (Merged for brevity)
  newItemDialogTexts_defaults_createButton: 'Create',
  newItemDialogTexts_document_subTitle: 'File name',
  newItemDialogTexts_document_successNote: 'File created',
  newItemDialogTexts_document_errorTitle: 'File not created',
  newItemDialogTexts_document_errorMessage: 'Unable to create file',
  newItemDialogTexts_folder_subTitle: 'Folder name',
  newItemDialogTexts_folder_successNote: 'Folder created',
  newItemDialogTexts_folder_errorTitle: 'Folder not created',
  newItemDialogTexts_folder_errorMessage: 'Unable to create folder',
  newItemDialogTexts_renameFile_title: 'Rename file',
  newItemDialogTexts_renameFile_subTitle: 'File name',
  newItemDialogTexts_renameFile_successNote: 'File renamed',

  DialogTexts_renameFile_errorTitle: 'File not renamed',
  newItemDialogTexts_renameFile_errorMessage: 'Unable to rename file',
  newItemDialogTexts_renameFile_createButton: 'Rename',
  newItemDialogTexts_renameFolder_title: 'Rename folder',
  newItemDialogTexts_renameFolder_subTitle: 'Folder name',
  newItemDialogTexts_renameFolder_successNote: 'Folder renamed',
  newItemDialogTexts_renameFolder_errorTitle: 'Folder not renamed',
  newItemDialogTexts_renameFolder_errorMessage: 'Unable to rename folder',
  newItemDialogTexts_renameFolder_createButton: 'Rename',

  // ItemDialogTexts
  itemDialogTexts_folderTitle: 'New folder',
  itemDialogTexts_folderSubTitle: 'Folder name',
  itemDialogTexts_fileSubTitle: 'File name',
  itemDialogTexts_urlPath: 'http://url.com',
  itemDialogTexts_defaultUrl: 'http://',
  itemDialogTexts_wordDocumentTitle: 'New Word document',
  itemDialogTexts_linkDocumentTitle: 'New link',
  itemDialogTexts_excelDocumentTitle: 'New Excel workbook',
  itemDialogTexts_powerPointDocumentTitle: 'New PowerPoint presentation',
  itemDialogTexts_cancel: 'Cancel',
  itemDialogTexts_itemExistError: 'An item with this name already exist',
  itemDialogTexts_urlNotValid: 'The URL is not valid',
  itemDialogTexts_itemNameNotValid: 'Common special characters cannot be used',
  itemDialogTexts_requiredField: 'Required field',

  // SortMenuActions
  sortMenuActions_name: 'Name',
  sortMenuActions_dateModified: 'Date modified',
  sortMenuActions_dateCreated: 'Date created',
  sortMenuActions_ascending: 'Ascending',
  sortMenuActions_descending: 'Descending',

  // DoubleClickAction
  doubleClickAction_openInApp: 'Desktop app',
  doubleClickAction_openInBrowserApp: 'Browser app',
  doubleClickAction_openInBrowser: 'Open in browser',
  doubleClickAction_askEveryTime: 'Ask me every time',
  doubleClickAction_downloads: 'Download',
  doubleClickAction_dialogTitle: 'How would you like to open this file?',
  doubleClickAction_appDialogTitle: 'How would you like to open this document?',
  doubleClickAction_checkboxLabel: 'Remember my choice (you can change this later)',

  // NodeNames
  nodeNames_favorites: 'Favorites',
  nodeNames_recent: 'Recent',
  nodeNames_sharedWithMe: 'Shared with me',
  nodeNames_teams: 'Teams',
  nodeNames_search: 'Searching in',
  nodeNames_defaultSearch: 'Search results',
  nodeNames_sharePoint: 'SharePoint',
  nodeNames_oneDrive: 'OneDrive',
  nodeNames_viewAllFiles: ' View all files',
  nodeNames_teamsChatFiles: 'Chats and meetings',
  nodeNames_searchAllLocations: 'Search all locations',

  // Notifications
  notifications_saveEmailStart: 'Saving...',
  notifications_emailWithAttachmentStart: 'Adding attachment...',
  notifications_emailWithAttachmentDone: 'Attachment added',
  notifications_saveEmailDone: 'Saved',
  notifications_copyLink: 'Link copied',
  notifications_addToFavorite: 'Added to favorites',
  notifications_renovedFromFavorite: 'Removed from favorites',
  notifications_openInBrowser: 'Opening in browser...',
  notifications_doubleClick: 'Opening document',

  dragAndDropErrorMessage: 'Please try again',
  dragAndDropFailedTitle: 'Unable to save email',
  dragAndDropItemNotSupportedMeesage: 'This item type is not supported.',
  officeSelectionErrorTitle: 'Office error',
  officeSelectionErrorMessage: 'Please restart the addin',
  // NotificationsError
  notificationsError_uploadFailedTitle: 'Upload failed.',
  notificationsError_saveEmailFailedTitle: 'Save failed.',
  notificationsError_uploadFailedMessage: 'There was an error uploading.',
  notificationsError_saveEmailFailedMessage: 'There was an error saving.',
  notificationsError_drilldownFailedTitle: 'Location not available',
  notificationsError_drilldownFailedMessage: 'You are currently unable to access this location',
  notificationsError_emailWithAttachmentFailedTitle: 'Unable to add',
  notificationsError_emailWithAttachmentFailedMessage:
    'This file could not be added as an attachment. Try sending as a link instead.',

  site: 'site',
  list: 'document library',
  folder: 'folder',
  channel: 'channel',
  team: 'team',
  location: 'location',
  RootSite: ' root site',
  ShareToTeamsIntroStartWith: "I'd like to share",
  ShareToTeamsIntroEndWith: 'with you:',
  quickSearchsearchPlaceholder: 'Search this',
  quickSearchclearTooltipText: 'Clear',
  quickSearchclearFilterText: 'Click to remove',
  quickSearchhistoryTitle: 'HISTORY',
  quickSearchoneDrive: 'Search OneDrive',
  quickSearchchatAndFiles: 'Search chats and meetings',
  quickSearchgeneralSearch: 'Search Microsoft 365',
  results_countResults: 'Results',

  viewsAction_title: 'Views',
  viewsAction_changeViewSuccessMessage: 'Switched to <%= viewname %>',
  advancedFilterAction_title: 'Advanced options',
};
export const de: typeof en = {
  //EHM Dialog
  ehm_title: 'Create an email view in this team',
  ehm_checkmark1: 'Emails saved to this team will automatically include metadata',
  ehm_checkmark2: 'A view will be created with email detail columns (subject, to, from, etc.)',
  ehm_checkboxText: "Don't ask again for this location",
  ehm_primary_button: 'Create view',
  ehm_secondary_button: 'Not now',
  // share to teams dialog
  shareDialogTexts_titleEnd: 'Microsoft Teams',
  shareDialogTexts_shareTo: 'Share to',
  shareDialogTexts_viewMailText: 'View email',
  shareDialogTexts_notifyText: 'Notify everyone in this channel',
  shareDialogTexts_noPreview: 'This message has no body content',
  searchPlaceHolder: 'Search for a person, chat, or channel',
  searchPeoples: 'People',
  searchChannels: 'Channels',
  searchChatsAndGroups: 'Chats and meetings',
  emailProps_msgBody: "I'd like to share this with you",
  emailShareEmail: 'Shared Email',

  successPage_title: 'Your message is on its way to',
  successPage_notification: 'You may need to check your browser',
  errorPage_genericTitle: 'Something went wrong',
  errorPage_genericBody: 'An unexpected error has occurred. Please try again later.',
  errorPage_title: 'Oops, unable to share',
  errorPage_body: `Don"t worry, the file exists in`,
  errorPage_linkCopied: 'Link was copied!',

  // emailProps
  emailProps_from: 'From',
  emailProps_to: 'To',
  emailProps_noSubject: '(no subject)',

  //Email Header Mapping
  createEmailHeaderMappingTeam_title: 'Create an email view in this team channel',
  createEmailHeaderMappingTeam_checkmark1: 'Emails saved to this team channel will automatically include metadata',
  createEmailHeaderMappingSite_title: 'Create an email view in this library',
  createEmailHeaderMappingSite_checkmark1: 'Emails saved to this library will automatically include metadata',
  createEmailHeaderMapping_checkmark2: 'A view will be created with email detail columns (Name, To, From, etc.)',
  createEmailHeaderMapping_checkmark3:
    'Modify email view anytime in the settings under the email header mapping section',
  createEmailHeaderMapping_checkboxText: "Don't ask again for this location",
  createEmailHeaderMapping_primary_button: 'Create view',
  createEmailHeaderMapping_secondary_button: 'Not now',
  createEmailHeaderMapping_success: 'Email view created',
  createEmailHeaderMapping_error: 'Unable to create email view',
  emailHeaderMappingFailed: 'Email header mapping failed',

  // manageLists
  manageLists_clear: 'Clear',
  manageLists_noResults: "We couldn't find any results for '<%= name %>'",

  // uploadIndicator
  cancel_upload: 'Cancel saving',
  upload_canceled: 'Saving canceled',
  upload_complete: '1 item saved!',
  uploading_one_item: 'Saving 1 item',
  fail_upload: '<%= count %>  item failed to save',
  progress_uploaded: 'Saving <%= count %> of <%= totals %> items',
  items_uploaded: '<%= count %> items saved',
  unable_to_upload: 'Upload blocked, still in progress',

  // actiosNames
  actionsNames_undo: 'Undo',
  actionsNames_goBack: 'Go back',
  actionsNames_ok: 'Got it',
  actionsNames_showAllResults: 'Show more results',
  actionsNames_searchAllLocation: 'Search all locations',
  actionsNames_showMoreFilters: 'Show more filters',
  actionsNames_showLessFilters: 'Show less filters',
  actionsNames_breadcrumbsAction: 'Go to parent location',
  actionsNames_backToTop: 'Back to top',
  actionsNames_close: 'Close',

  // tooltip
  tooltip_shareDisabled: 'Please select a chat or channel to enable sharing',
  tooltip_chatAndFilesSearch: 'This feature is coming soon!',

  // QuickSearchFilters
  quickSearchFilters_sharePoint: 'SharePoint',
  quickSearchFilters_oneDrive: 'OneDrive',
  quickSearchFilters_teams: 'Teams',
  quickSearchFilters_team: 'Team',
  quickSearchFilters_channel: 'Channel',
  quickSearchFilters_site: 'Site',
  quickSearchFilters_library: 'Library',
  quickSearchFilters_folder: 'Folder',
  quickSearchFilters_allFiles: 'All files',
  quickSearchFilters_word: 'Word',
  quickSearchFilters_powerPoint: 'PowerPoint',
  quickSearchFilters_excel: 'Excel',
  quickSearchFilters_pdf: 'PDF',
  quickSearchFilters_email: 'Email',

  // dateFormat
  dateFormat_justNow: 'Just now',
  dateFormat_halfAnHour: '30 mins',
  dateFormat_twoHoursAgo: '2 hours ago',
  dateFormat_today: 'Today at',
  dateFormat_yesterday: 'Yesterday at',
  dateFormat_at: 'at',

  // welcomePage
  onBoarding_welcomePageTitle: 'Welcome to New harmon.ie',
  onBoarding_welcomePageMessage:
    'Turn Outlook into a central hub for all your files and locations across Microsoft 365',
  onBoarding_uploadTitle: 'Upload emails and files',
  onBoarding_uploadMessage: 'Drag-and-drop or click to save important content to SharePoint, Teams and OneDrive.',
  onBoarding_shareTitle: 'Share links and attachments',
  onBoarding_shareMessage: 'Share anything from Microsoft 365 in a simple click, keeping everyone in the loop',
  onBoarding_searchTitle: 'Search everything at once',
  onBoarding_searchMessage: "It's never been easier to find files, emails or locations",
  onBoarding_teamsPageTitle: 'Which Teams are you working with?',
  onBoarding_teamsPageMessage:
    'Select the Teams you save to or access data from. These will appear on your home screen',
  onBoarding_sharePointPageTitle: 'Which sites are you working with?',
  onBoarding_sharePointPageMessage:
    'Select the SharePoint sites you save to or access data from. These will appear on your home screen',
  onBoarding_DoneTitle: 'All set up!',
  onBoarding_DoneLoadingTitle: 'Loading your data!',
  onBoarding_requiredNewPremission: 'Need approval for new permissions',

  onBoarding_sharePointPageHeader: 'Set up SharePoint',
  onBoarding_teamsPageHeader: 'Set up Teams',

  onBoarding_selectedTeams: 'teams selected',
  onBoarding_selectedSites: 'sites selected',
  onBoarding_loadMore: 'Load more',
  onBoarding_skip: 'Skip',
  onBoarding_next: 'Next',
  onBoarding_done: 'Done',
  onBoarding_getStarted: 'Get started',
  onBoarding_selectAllSites: 'Select all sites',
  onBoarding_selectAllTeams: 'Select all teams',
  onBoarding_unselectAllSites: 'Unselect all sites',
  onBoarding_unselectAllTeams: 'Unselect all teams',
  onBoarding_unselectAll: 'Unselect all',
  onBoarding_selectAll: 'Select all',
  // Select items modal
  selectItemsModal_title: 'Select items to save',
  selectItemsModal_description: 'Emails and attachments will be saved separately in the chosen location.',
  selectItemsModal_itemsSelected: '<%= selectedItems %> of <%= totalItems %> ITEMS SELECTED',
  selectItemsModal_selectAll: 'Select all',
  selectItemsModal_unselectAll: 'Unselect all',
  selectItemsModal_primaryButton: 'Save',
  selecItemsModal_fetchingEmails: 'Preparing email data...',
  selecItemsModal_unable_to_save: 'Unable to save. Please try again',
  selecItemsModal_item_size: 'Item size',

  // errorMessages
  errorMessages_drilldownNavigation: 'Navigation to this location failed',
  errorMessages_cannotGetItems: 'Cannot retrieve items',
  errorMessages_tryAgain: 'Refresh to try again or contact your administrator.',

  // userManage (teams and sites)
  userManage_teams_title: 'Manage Teams',
  userManage_teams_description: 'Select which teams will be visible in the home screen',
  userManage_teams_availableItems: 'Available teams',
  userManage_teams_addedItems: 'Added teams',
  userManage_teams_placeholderSearch: 'Search Teams',
  userManage_teams_showMoreHint: 'Use the teams search to see more results',
  userManage_teams_emptyState: 'There are currently no teams',
  userManage_teams_errorMessage: 'Cannot retrieve teams',
  userManage_sites_title: 'Manage Sites',
  userManage_sites_description: 'Select which sites will be visible in the home screen',
  userManage_sites_show_teams: 'Include sites connected to Microsoft Teams',
  userManage_sites_availableItems: 'Available sites',
  userManage_sites_addedItems: 'Added Sites',
  userManage_sites_placeholderSearch: 'Search Sites',
  userManage_sites_showMoreHint: 'Use the sites search to see more results',
  userManage_sites_emptyState: 'There are currently no sites',
  userManage_sites_errorMessage: 'Cannot retrieve sites',

  // userSetting
  userSetting_resetdialog_warningTitle: 'Reset user settings',
  userSetting_resetdialog_warningBody: "Are you sure you want to reset user settings? This can't be undone.",
  userSetting_resetdialog_secondaryAction: 'Cancel',
  userSetting_resetdialog_primaryAction: 'Reset',
  userSetting_cleardialog_warningTitle: 'Clear search history',
  userSetting_cleardialog_warningBody: 'Are you sure you want to clear your search history? This can’t be undone.',
  userSetting_cleardialog_secondaryAction: 'Cancel',
  userSetting_cleardialog_primaryAction: 'Clear',
  userSetting_resetTitle: 'Reset settings to default',
  userSetting_signoutdialog_warningTitle: 'Sign out',
  userSetting_signoutdialog_warningBody: 'Are you sure you want to sign out?',
  userSetting_signoutdialog_secondaryAction: 'Cancel',
  userSetting_signoutdialog_primaryAction: 'Sign out',
  userSetting_signOutTitle: 'Disconnect from harmon.ie',
  userSetting_actionName: ' Reset',
  userSetting_documentsTitle: 'Opening documents',
  userSetting_documentsDescription: 'How would you like to open M365 documents?',
  userSetting_emailNameTitle: 'Saved email name',
  userSetting_emailNamesDescription: 'How would you like to name saved emails',
  userSetting_emailNamesDefault: 'Subject only',
  userSetting_emailNamesAdvanced: 'Subject, sender and date',

  userSetting_ehmTitle: 'Email header mapping view',
  userSetting_ehmDescription: 'Create email view and classify emails with email header fields',
  userSetting_ehmCheckboxAllLocations: 'Teams and SharePoint',
  userSetting_ehmCheckboxTeams: 'Teams only',
  userSetting_ehmCheckboxNone: 'None',

  userSetting_themeSelectorTitle: 'Theme',
  userSetting_themeSelectorSystem: 'System',
  userSetting_themeSelectorLight: 'Light',
  userSetting_themeSelectorDark: 'Dark',

  userSetting_treeSettingFileTitle: 'Files to display on tree view',
  userSetting_treeSettingFileDescription:
    'How many files would you like to see when expanding locations on the home screen?',

  userSetting_treeSettingHistoryTitle: 'Tree view history',
  userSetting_treeSettingHistoryDescription: 'Remember expanded sections',

  userSetting_addCategoryTitle: 'Saved email labels in Outlook',
  userSetting_addCategoryDescription: 'Add location category to saved emails',

  // deleteDialog
  deleteDialog_warningTitle: 'Delete item',
  deleteDialog_warningBody: 'Are you sure you want to delete?',
  deleteDialog_actionName: 'Delete',
  deleteDialog_cancel: 'Cancel',

  // replaceDialog
  replaceDialogTitle: 'A file with this name already exists',
  replaceDialogMeesage: 'Would you like to replace it, or keep both',
  replaceDialogPrimary: 'Replace',
  replaceDialogSecondry: 'Keep both',
  replaceDialogCheckbox: 'Apply to all items',

  // emptyStateTitle
  emptyStateTitle_favorites: 'No favorites yet',
  emptyStateTitle_recent: 'Life is going to get easier!',
  emptyStateTitle_sharedwithme: 'Life is going to get easier!',
  emptyStateTitle_teams: 'Add your Teams',
  emptyStateTitle_chatsAndMeetings: 'No files from chats and meetings',
  emptyStateTitle_sharePoint: 'Add your Sites',
  emptyStateTitle_oneDrive: 'No items here yet!',
  emptyStateTitle_drilldown: 'This location is empty',
  emptyStateTitle_drilldownWithView: 'No items found in this view',
  emptyStateTitle_noSearchResultInLocation: 'No search results in this location',
  emptyStateTitle_globalNoSearchResult: 'No search results',
  emptyStateTitle_searchResultLastItem: 'Didn’t find what you looked for?',

  // emptyStateMessage
  emptyStateMessage_favorites: 'Click the star icon next to any file or location and it will show up here',
  emptyStateMessage_recent: 'Your recent files and folders will be displayed here',
  emptyStateMessage_sharedwithme: 'Files and folders shared with you will be displayed here',
  emptyStateMessage_teams:
    'Teams files and locations will be displayed here. Add some Teams that are important to you!',
  emptyStateMessage_chatsAndMeetings: 'Files you send and receive in Teams chats and meetings will show up here',
  emptyStateMessage_sharePoint:
    'Sites files and locations will be displayed here. Add some Sites that are important to you!',
  emptyStateMessage_oneDrive: 'Save something to OneDrive to see your files and folders here',
  emptyStateMessage_drilldown: 'Drag & drop here or',
  emptyStateMessage_noSearchResultInLocation: 'Try to search in all Microsoft 365 for more results',
  emptyStateMessage_searchResultLastItem: 'Try to search in all Microsoft 365 for more results',
  emptyStateMessage_quickSearchWithResults: 'Try to search in all Microsoft 365 for more results',

  // ActionDefaults
  actionDefaults_errorMessage: 'There was an error with the last action',
  actionDefaults_errorTitle: 'Something went wrong',

  // RemoveItem
  removeItem_team_errorTitle: 'Unable to remove team',
  removeItem_team_onSuccessTitle: 'Team removed',
  removeItem_team_actionTitle: 'Remove',
  removeItem_item_actionTitle: 'Delete',
  removeItem_site_errorTitle: 'Unable to remove site',
  removeItem_site_onSuccessTitle: 'Site removed',
  removeItem_insight_errorTitle: 'Unable to delete file',
  removeItem_insight_onSuccessTitle: 'File deleted',
  removeItem_document_errorTitle: 'Unable to delete file',
  removeItem_document_onSuccessTitle: 'File deleted',
  removeItem_folder_errorTitle: 'Unable to delete folder',
  removeItem_folder_onSuccessTitle: 'Folder deleted',

  signIn: 'Sign in with Microsoft',
  signOut: 'Sign out',
  logOutTitle: 'Logging you out from Microsoft 365',
  logOutMessage: 'Disconnecting...',
  allLocations: 'ALL LOCATIONS',
  collapseAll: 'Collapse all locations',

  // ActionToolTip
  actionToolTip_openMenu: 'Open menu',
  actionToolTip_simpleView: 'Simple',
  actionToolTip_detailsView: 'Details (columns)',
  actionToolTip_upload: 'Upload files',
  actionToolTip_emptyStateUpload: 'Upload files',
  actionToolTip_refresh: 'Refresh',
  actionToolTip_share: 'Share',
  actionToolTip_newItem: 'Add new',
  actionToolTip_copyAddress: 'Copy address',
  actionToolTip_openInBrowser: 'Open in browser',
  actionToolTip_openInBrowserApp: 'Open in browser app',
  actionToolTip_openInApp: 'Open in desktop app',
  actionToolTip_download: 'Download',
  actionToolTip_addFavorite: 'Favorite',
  actionToolTip_removeFavorite: 'Unfavorite',
  actionToolTip_manageItemCount: 'Manage...',
  actionToolTip_manage: 'Manage',
  actionToolTip_viewSetting: 'Manage items',
  actionToolTip_itemCountTitle: 'Item amount',
  actionToolTip_itemCountDescription: 'Choose number of items to display',
  actionToolTip_saveEmail: 'Save email',
  actionToolTip_saveEmailDisabled: 'Select one email to save',
  actionToolTip_openInTeamsApp: 'Open in Teams app',
  actionToolTip_openContainingLocation: 'Go to parent location',
  actionToolTip_moreActions: 'More actions',
  actionToolTip_favorite: 'Favorite',
  actionToolTip_copyLink: 'Copy link (Ctrl+L)',
  actionToolTip_copyLinkShort: 'Copy link',
  actionToolTip_emailWithLink: 'Email with link (Ctrl+E)',
  actionToolTip_addLink: 'Add as link',
  actionToolTip_addAttachment: 'Add as an attachment',
  actionToolTip_emailWithLinkShort: 'Email with link',
  actionToolTip_shareToTeams: 'Share to Teams (Ctrl+T)',
  actionToolTip_shareToTeamsShort: 'Share to Teams',
  actionToolTip_editOnSharepoint: 'Edit details (in browser)',
  actionToolTip_reorderA2Z: 'Reorder (A-Z)',
  actionToolTip_reorderZ2A: 'Reorder (Z-A)',
  actionToolTip_searchAllLocations: 'Search all locations',
  actionToolTip_newFolder: 'Folder',
  actionToolTip_newWordDocument: 'Word document',
  actionToolTip_newExcelDocument: 'Excel workbook',
  actionToolTip_newLinkDocument: 'Link',
  actionToolTip_newPowerPointDocument: 'PowerPoint presentation',
  actionToolTip_new: 'New',

  // ToolbarActions
  toolbarActions_home: 'Home',
  toolbarActions_back: 'Back',
  toolbarActions_sort: 'Sort',
  toolbarActions_saveEmail: 'Save email',

  //Lables
  categoryLabel: '[Microsoft 365] Saved in',

  // AllLocatiosToolbar
  allLocatiosToolbar_getSupport: 'Get support',
  allLocatiosToolbar_gotoSettings: 'Settings',
  allLocatiosToolbar_feedback: 'Send feedback',
  allLocatiosToolbar_feedbackTitle: 'Send feedback',

  // Email Preview
  emailPreview_title: 'Save selected email',
  emailsPreview_title: 'Save selected emails',

  // FilePreview
  filePreview_sharedInChat: 'shared with you in a Teams chat',
  filePreview_youSharedInChat: 'You shared in a Teams chat',
  filePreview_youSent: 'You sent this',
  filePreview_youShared: 'You shared this',
  filePreview_shared: 'shared with you',
  filePreview_sentThis: 'sent this',
  filePreview_recentlyOpened: 'You recently opened this',
  filePreview_editedThis: 'edited this',
  filePreview_recentlyEdited: 'You recently edited this',
  filePreview_emailAttachment: 'Email attachment in Outlook',

  // Slider
  SliderFilter: 'Filter',
  SliderFilterOn: 'Show email attachments',

  // NewItemDialogTexts and ItemDialogTexts (Merged for brevity)
  newItemDialogTexts_defaults_createButton: 'Create',
  newItemDialogTexts_document_subTitle: 'File name',
  newItemDialogTexts_document_successNote: 'File created',
  newItemDialogTexts_document_errorTitle: 'File not created',
  newItemDialogTexts_document_errorMessage: 'Unable to create file',
  newItemDialogTexts_folder_subTitle: 'Folder name',
  newItemDialogTexts_folder_successNote: 'Folder created',
  newItemDialogTexts_folder_errorTitle: 'Folder not created',
  newItemDialogTexts_folder_errorMessage: 'Unable to create folder',
  newItemDialogTexts_renameFile_title: 'Rename file',
  newItemDialogTexts_renameFile_subTitle: 'File name',
  newItemDialogTexts_renameFile_successNote: 'File renamed',

  DialogTexts_renameFile_errorTitle: 'File not renamed',
  newItemDialogTexts_renameFile_errorMessage: 'Unable to rename file',
  newItemDialogTexts_renameFile_createButton: 'Rename',
  newItemDialogTexts_renameFolder_title: 'Rename folder',
  newItemDialogTexts_renameFolder_subTitle: 'Folder name',
  newItemDialogTexts_renameFolder_successNote: 'Folder renamed',
  newItemDialogTexts_renameFolder_errorTitle: 'Folder not renamed',
  newItemDialogTexts_renameFolder_errorMessage: 'Unable to rename folder',
  newItemDialogTexts_renameFolder_createButton: 'Rename',

  // ItemDialogTexts
  itemDialogTexts_folderTitle: 'New folder',
  itemDialogTexts_folderSubTitle: 'Folder name',
  itemDialogTexts_fileSubTitle: 'File name',
  itemDialogTexts_urlPath: 'http://url.com',
  itemDialogTexts_defaultUrl: 'http://',
  itemDialogTexts_wordDocumentTitle: 'New Word document',
  itemDialogTexts_linkDocumentTitle: 'New link',
  itemDialogTexts_excelDocumentTitle: 'New Excel workbook',
  itemDialogTexts_powerPointDocumentTitle: 'New PowerPoint presentation',
  itemDialogTexts_cancel: 'Cancel',
  itemDialogTexts_itemExistError: 'An item with this name already exist',
  itemDialogTexts_urlNotValid: 'The URL is not valid',
  itemDialogTexts_itemNameNotValid: 'Common special characters cannot be used',
  itemDialogTexts_requiredField: 'Required field',

  // SortMenuActions
  sortMenuActions_name: 'Name',
  sortMenuActions_dateModified: 'Date modified',
  sortMenuActions_dateCreated: 'Date created',
  sortMenuActions_ascending: 'Ascending',
  sortMenuActions_descending: 'Descending',

  // DoubleClickAction
  doubleClickAction_openInApp: 'Desktop app',
  doubleClickAction_openInBrowserApp: 'Browser app',
  doubleClickAction_openInBrowser: 'Open in browser',
  doubleClickAction_askEveryTime: 'Ask me every time',
  doubleClickAction_downloads: 'Download',
  doubleClickAction_dialogTitle: 'How would you like to open this file?',
  doubleClickAction_appDialogTitle: 'How would you like to open this document?',
  doubleClickAction_checkboxLabel: 'Remember my choice (you can change this later)',

  // NodeNames
  nodeNames_favorites: 'Favorites',
  nodeNames_recent: 'Recent',
  nodeNames_sharedWithMe: 'Shared with me',
  nodeNames_teams: 'Teams',
  nodeNames_search: 'Searching in',
  nodeNames_defaultSearch: 'Search results',
  nodeNames_sharePoint: 'SharePoint',
  nodeNames_oneDrive: 'OneDrive',
  nodeNames_viewAllFiles: ' View all files',
  nodeNames_teamsChatFiles: 'Chats and meetings',
  nodeNames_searchAllLocations: 'Search all locations',

  // Notifications
  notifications_saveEmailStart: 'Saving...',
  notifications_emailWithAttachmentStart: 'Adding attachment...',
  notifications_emailWithAttachmentDone: 'Attachment added',
  notifications_saveEmailDone: 'Saved',
  notifications_copyLink: 'Link copied',
  notifications_addToFavorite: 'Added to favorites',
  notifications_renovedFromFavorite: 'Removed from favorites',
  notifications_openInBrowser: 'Opening in browser...',
  notifications_doubleClick: 'Opening document',

  dragAndDropFailedTitle: 'Unable to save email',
  dragAndDropItemNotSupportedMeesage: 'This item type is not supported.',
  officeSelectionErrorTitle: 'Office error',
  officeSelectionErrorMessage: 'Please restart the addin',
  dragAndDropErrorMessage: 'Please try again',
  // NotificationsError
  notificationsError_uploadFailedTitle: 'Upload failed.',
  notificationsError_saveEmailFailedTitle: 'Save failed.',
  notificationsError_uploadFailedMessage: 'There was an error uploading.',
  notificationsError_saveEmailFailedMessage: 'There was an error saving.',
  notificationsError_drilldownFailedTitle: 'Location not available',
  notificationsError_drilldownFailedMessage: 'You are currently unable to access this location',
  notificationsError_emailWithAttachmentFailedTitle: 'Unable to add',
  notificationsError_emailWithAttachmentFailedMessage:
    'This file could not be added as an attachment. Try sending as a link instead.',

  site: 'site',
  list: 'document library',
  folder: 'folder',
  channel: 'channel',
  team: 'team',
  location: 'location',
  RootSite: ' root site',
  ShareToTeamsIntroStartWith: "I'd like to share",
  ShareToTeamsIntroEndWith: 'with you:',
  quickSearchsearchPlaceholder: 'Search this',
  quickSearchclearTooltipText: 'Clear',
  quickSearchclearFilterText: 'Click to remove',
  quickSearchhistoryTitle: 'HISTORY',
  quickSearchoneDrive: 'Search OneDrive',
  quickSearchchatAndFiles: 'Search chats and meetings',
  quickSearchgeneralSearch: 'Search Microsoft 365',
  results_countResults: 'Results',

  viewsAction_title: 'Views',
  viewsAction_changeViewSuccessMessage: 'Switched to <%= viewname %>',
  advancedFilterAction_title: 'Advanced options',
};
