import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import { strings } from '@vendor/languages';
import { Stack } from '../Stack';
import { Typography } from '../Typography';
import { LottieAnimation, allSetupAnimationData } from '../Lottie';
import { OperatedList } from '../ManageLists/ManageItem';
import { VerticalScrollbars } from '../Scollbars';
import useSlideData from './useSlideData';

interface WelcomeScreenProps {
  currentSlide: number;
  setCurrentSlide?: (currentSlide: number) => void;
  loginCallback?: () => Promise<void>;
  onComplete?: (sitesItems?: OperatedList[], teamsItems?: OperatedList[]) => void;
  sitesItems?: OperatedList[];
  teamsItems?: OperatedList[];
  getOnboardingSites?: () => Promise<OperatedList[]>;
  getOnboardingTeams?: () => Promise<OperatedList[]>;
  showFirstSlide?: boolean;
  isRequirednewPermissions?: boolean;
}

const Onboarding = ({
  currentSlide,
  setCurrentSlide,
  loginCallback,
  onComplete,
  getOnboardingSites,
  getOnboardingTeams,
  showFirstSlide,
}: WelcomeScreenProps) => {
  const theme = useTheme();
  const [isSmallWin, setIsSmallWin] = useState(window.innerHeight <= 400); // state to track window size
  const [items, setItems] = useState<{ sites: OperatedList[]; teams: OperatedList[] }>();
  const [showShadow, setShowShadow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // Handler that's called when the scrollbar updates
  const handleScrollbarUpdate = values => {
    // Check if the content is scrollable by comparing the scrollHeight and clientHeight
    const isScrollable = values.scrollHeight > values.clientHeight;
    setShowShadow(isScrollable);
  };

  useEffect(() => {
    function handleResize() {
      setIsSmallWin(window.innerHeight <= 400);
    }

    window.addEventListener('resize', handleResize);
    handleResize(); // call once to set initial value

    return () => window.removeEventListener('resize', handleResize); // cleanup
  }, []);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        setIsLoading(true);
        if (getOnboardingTeams && getOnboardingSites) {
          const sitesItems = await getOnboardingSites();
          const teamsItems = await getOnboardingTeams();
          setItems({ sites: sitesItems, teams: teamsItems });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to fetch onboarding items:', error);
      } finally {
        setIsLoading(false);
      }
    };

    items === undefined && currentSlide && currentSlide > 0 && fetchItems();
  }, [currentSlide, getOnboardingSites, getOnboardingTeams, items]);

  const { selectedSites, selectedTeams, slideData } = useSlideData({
    currentSlide,
    isLoading,
    items,
    loginCallback,
    setCurrentSlide,
  });

  useEffect(() => {
    const isOnboardingComplete = currentSlide === slideData.length - 1;
    if (isOnboardingComplete && onComplete && isSmallWin) {
      setTimeout(() => {
        onComplete(selectedSites, selectedTeams);
      }, 1500);
    }
  }, [currentSlide, isSmallWin, onComplete, selectedSites, selectedTeams, slideData.length]);

  const { header, title, message, animationData, animationDataTwo, officeImages, footer, visualSlide, manageList } =
    Boolean(showFirstSlide)
      ? slideData[0]
      : currentSlide < slideData.length && currentSlide !== undefined
        ? slideData[currentSlide]
        : slideData[1];
  return (
    <Stack
      direction="column"
      background={theme.palette.backgrounds.primary}
      sx={{
        height: '100%',
        width: '100%',
      }}
      className="hidden-scrollbar"
    >
      {isLoading ? (
        <Stack>
          <LottieAnimation
            options={{
              loop: true,
              autoplay: true,
              animationData: allSetupAnimationData,
            }}
            height={400}
            width={200}
          />
          <Typography variant="OnBoardingBoldTitle" textAlign={'center'}>
            {strings.lang.onBoarding.onBoardingLoadingTitle}
          </Typography>
        </Stack>
      ) : (
        <Stack
          direction="column"
          sx={{
            height: '100%',
            width: '100%',
          }}
        >
          <VerticalScrollbars
            autoHide
            onUpdate={handleScrollbarUpdate}
            className="lottie-disabled-transform"
            height={isSmallWin ? '100%' : ''}
          >
            {header && header}
            {!isSmallWin && animationData && (
              <LottieAnimation
                options={animationData}
                secondaryOptions={animationDataTwo}
                height={400}
                width={currentSlide === 6 ? 200 : undefined}
                handleAnimationComplete={() => {
                  const finishOnboarding = currentSlide === slideData.length - 1;
                  if (finishOnboarding && onComplete) onComplete(selectedSites, selectedTeams);
                }}
              />
            )}

            {!isSmallWin && visualSlide && (
              <Stack marginTop={8} marginBottom={4} alignItems={'center'} width={'100%'} height={'auto'}>
                {visualSlide}
              </Stack>
            )}
            <Stack
              direction="column"
              alignItems="center"
              width={'100%'}
              height={isSmallWin ? '100%' : 'auto'}
              background={theme.palette.backgrounds.primary}
              sx={{ flexGrow: 1, paddingLeft: 2, paddingRight: 2 }}
            >
              <Stack
                spacing={2}
                sx={{
                  ...(isSmallWin && {
                    justifyContent: 'center',
                    height: '100%',
                  }),
                  alignItems: 'center',
                }}
              >
                <Typography variant="OnBoardingBoldTitle" textAlign={'center'}>
                  {title}
                </Typography>
                <Typography
                  variant="H2Regular"
                  sx={{
                    textAlign: 'center',
                    color: theme.palette.texts.secondary,
                  }}
                >
                  {message}
                </Typography>
                {officeImages && officeImages}
              </Stack>
              {/* {isRequirednewPermissions && (
                <Link
                  href="https://harmon.ie/documentation/sharepoint/new/whatsnew"
                  target="_blank" // Opens the link in a new tab
                  rel="noopener noreferrer" // Ensures security for new tab opening
                  sx={{
                    textDecoration: 'none', // Removes underline
                    textAlign: 'center', // Center alignment of the link
                    mt: 3,
                  }}
                >
                  <Typography
                    variant="H1Regular"
                    sx={{
                      textAlign: 'center',
                      color: theme.palette.buttons.accentSecondary,
                    }}
                  >
                    {strings.lang.onBoarding.requiredNewPremission}
                  </Typography>
                </Link>
              )} */}

              {manageList}
            </Stack>
          </VerticalScrollbars>
          <Stack
            alignItems="center"
            justifyContent="flex-end"
            sx={{
              position: 'sticky', // Set the position to sticky
              bottom: 0, // Stick to the bottom
              zIndex: 1000, // Ensure it's above other content (if necessary)
              width: '100%', // Full-width to align with its container
              paddingLeft: 3,
              paddingRight: 3,
              boxShadow: showShadow ? theme.palette.shadow.bottomSheet : 'none',
              marginTop: isSmallWin ? 4 : 0,
            }}
          >
            {footer}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default Onboarding;
