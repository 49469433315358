import { createContext } from 'react';

export type CallbackFunction = () => void | undefined;
export interface UndoAction {
  run: CallbackFunction;
}

type ShowErrorFunction = (title: string, message: string, hasAction: boolean, icon?: React.ReactNode) => void;
export type ShowNotificationFunction = (
  title: string,
  time?: number,
  cancelAction?: UndoAction,
  className?: string,
  icon?: React.ReactNode
) => void;

export interface NotificationContextType {
  showError: ShowErrorFunction;
  showNotification: ShowNotificationFunction;
  hideNotification: () => void;
}

export const NotificationContext = createContext<NotificationContextType>({
  showError: () => undefined,
  showNotification: () => undefined,
  hideNotification: () => undefined,
});

export default NotificationContext;
