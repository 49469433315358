import { SharePointEmptyStateDark, SharePointEmptyStateLight, SharepointMedium, IconProps } from '@storybook';
import { uniqBy as _uniqBy } from 'lodash';
import { Site } from '@microsoft/microsoft-graph-types';
import { SiteExpirationWindow, GraphSiteKeys, GValue, GraphClient, EternalWindow } from '@services';
import { getSortByName } from '@vendor/utils/misc';
import { strings } from '@vendor/languages';
import { OneDriveRoot, TeamsRoot } from '.';
import { SPSite, SPSiteProps } from '../sharePointTypes';
import {
  BaseContainer,
  FetchChildrenProps,
  EmptyStateItem,
  ItemDataWithPaging,
  ItemData,
  MicrosoftApps,
} from '../itemTypes';
import { createItemDataFromJson } from '../misc';
import { ManageSitesAction, publishItemListChanged } from '../actions';
import { RootSectionManager } from './RootSectionManager';
import { SharePointClient } from '../sharePointAPI';

export class SharePointSitesRoot extends BaseContainer {
  private static _instance: SharePointSitesRoot;

  static Settings = new RootSectionManager<SPSiteProps>('SharePointSettings', () => null);
  static NewChildren: boolean;
  static timeStamp: number;
  constructor() {
    super({ type: 'sharepointroot', id: 'root', name: strings.lang.nodeNames.sharePoint });
    this.rootPrefix = 'sharePoint:';
    SharePointSitesRoot.timeStamp = Date.now();
  }

  get isRootSection(): boolean {
    return true;
  }
  get canRefresh(): boolean {
    return false;
  }
  get supportsSortDirection() {
    return true;
  }
  static get instance() {
    if (this._instance === undefined) this._instance = new SharePointSitesRoot();
    return this._instance;
  }

  get locationHaveQuickSearch(): boolean {
    return false;
  }

  override get canReorder(): boolean {
    return true;
  }

  override getNumberOfQuickActions() {
    return 0;
  }

  static save(newState: SPSiteProps[], hasNew: boolean) {
    SharePointSitesRoot.Settings.update(newState, hasNew);
    SharePointSitesRoot.NewChildren = hasNew;
    hasNew &&
      newState.map(item => {
        if (item.isNew) {
          const site = new SPSite(item) as ItemData;
          publishItemListChanged({ added: { [site.apiIdKey]: site }, location: this._instance });
        }
      });
  }

  override get hasNewChildren(): boolean {
    return SharePointSitesRoot.NewChildren;
  }

  override reorderItems(): void {
    SharePointSitesRoot.NewChildren = false;
    SharePointSitesRoot.timeStamp = Date.now();
    SharePointSitesRoot.Settings.reorderItems();
  }

  override resetNewItems(isOpened: boolean) {
    if (isOpened) {
      SharePointSitesRoot.Settings.resetNewItems();
      SharePointSitesRoot.timeStamp = Date.now();
    }
    SharePointSitesRoot.NewChildren = false;
  }

  override get sortDir(): boolean {
    return SharePointSitesRoot.Settings.value?.sortDir || false;
  }

  static async getFollowedSites(gcl: GraphClient, refreshStamp?: number, top?: number): Promise<SPSite[]> {
    const followedSites: GValue<Site> = await gcl
      .api('/me/followedSites')
      .cache(SiteExpirationWindow, refreshStamp)
      .select(GraphSiteKeys)
      .top(top || hrmProvisioning.followedSitesCount)
      .get();
    return followedSites.value.map(
      s =>
        new SPSite({
          graphRoot: '/sites',
          id: s.id || '',
          site: s.webUrl || '',
          name: s.displayName || '',
          _isUserManaged: true,
          siteDescription: s.description,
          OfficeAppLocated: MicrosoftApps.SharePoint,
        })
    );
  }

  static async getFrequentSites(spc: SharePointClient, refreshStamp?: number, top?: number): Promise<SPSite[]> {
    const frequentSites = await spc
      .api(`${spc.rootSite}/_api/v2.1/insights/frequentSites`)
      .cache(SiteExpirationWindow, refreshStamp)
      .top(top || hrmProvisioning.frequentsSitesCount)
      .get();
    return frequentSites.value.map(
      (s: { id: string; sharepointIds: { siteUrl: string }; title: string }) =>
        new SPSite({
          id: s.id,
          site: s.sharepointIds.siteUrl,
          name: s.title,
          _isUserManaged: true,
          graphRoot: '/sites',
          OfficeAppLocated: MicrosoftApps.SharePoint,
          // The feed doesn't provide the site's description.
        })
    );
  }

  private static async getRootSPSite(gcl: GraphClient): Promise<SPSite> {
    const rootItem: Site = await gcl
      .api('/sites/root')
      // Home site URL shouldn't change
      .cache(EternalWindow)
      .select(GraphSiteKeys)
      .get();
    return new SPSite({
      graphRoot: '/sites',
      id: rootItem.id || '',
      site: rootItem.webUrl || '',
      name: rootItem.displayName || '',
      _isUserManaged: true,
      siteDescription: rootItem.description,
      OfficeAppLocated: MicrosoftApps.SharePoint,
    });
  }

  static async getOnBoardingSites(gcl: GraphClient, refreshStamp?: number): Promise<SPSite[]> {
    // Prepare for frequent sites call.
    let frequentRoot: string | undefined;
    let rootSite: SPSite | undefined;
    try {
      // try SharePoint root site
      const root = await this.getRootSPSite(gcl);
      frequentRoot = root.rootSite;
      rootSite = root;
    } catch {}
    if (!frequentRoot) {
      try {
        // try OneDrive instead
        const oneDriveRoot = await OneDriveRoot.getRootLibrary(gcl);
        frequentRoot = oneDriveRoot.rootSite;
      } catch {}
    }

    // We run in parallel - followed sites, frequent sites & fetching all teams' urls.
    const getFollowedF = this.getFollowedSites(gcl, refreshStamp, 25);
    let getFrequentF = Promise.resolve<SPSite[]>([]);
    if (frequentRoot) {
      const spc = new SharePointClient(gcl, frequentRoot);
      getFrequentF = this.getFrequentSites(spc, refreshStamp, 25);
    }
    const [knownTeams, followed, frequent] = await Promise.all([
      TeamsRoot.collectAllTeamsLocations(gcl, Date.now()),
      getFollowedF,
      getFrequentF,
    ]);
    const result = [...followed, ...frequent].filter(s => !knownTeams.bySiteUrl.has(s.site));
    if (result.length === 0 && rootSite) result.push(rootSite);
    return _uniqBy(result, 'id');
  }

  static async getRegisteredSites(gcl: GraphClient, refreshStamp?: number) {
    if (this.Settings.value === null) {
      const initialValue = await this.getOnBoardingSites(gcl, refreshStamp);
      SharePointSitesRoot.Settings.value = {
        registeredItems: initialValue.sort(getSortByName(true)).map(s => s.toJson()),
        sortDir: true,
      };
    }
    return this.Settings.registeredItems.map(s => {
      const item = createItemDataFromJson(s);
      if (s.isNew && s.timeStamp && s.timeStamp > SharePointSitesRoot.timeStamp) {
        item.isNew = true;
      } else {
        item.isNew = undefined;
      }
      return item;
    });
  }

  override async fetchChildren({ gcl, refreshStamp }: FetchChildrenProps): Promise<ItemDataWithPaging> {
    const registeredSites = await SharePointSitesRoot.getRegisteredSites(gcl, refreshStamp);
    return {
      items: new EmptyStateItem({
        name: 'sharePoint',
        inTree: true,
        images: { light: SharePointEmptyStateLight, dark: SharePointEmptyStateDark },
        action: new ManageSitesAction(),
        location: { id: this.id, data: this },
      }).apply(registeredSites, registeredSites.length === 0),
    };
  }

  override getIcon(_expanded: boolean): IconProps {
    return { icon: SharepointMedium, isColorable: false };
  }
}
