import React from 'react';
import { Box, Stack, useTheme } from '@mui/system';
import { Typography } from '../Typography';
import { Scrollbar } from '../Scollbar';
import { IndicatorStatus } from './Indicator';
import { LocationLabelTooltip } from '../Tooltip';
import { splitFilename } from '../../utils';

export interface IndicatorBodyProps {
  status: IndicatorStatus;
  description?: string;
  messageBody: string[] | undefined;
}

export const IndicatorBody = ({ status, description, messageBody }: IndicatorBodyProps) => {
  const theme = useTheme();
  return (
    <Box
      height={
        status === 'progress' ? 16 : status === 'error' ? Math.min(((messageBody?.length || 0) + 3) * 16, 70) : 'auto'
      }
    >
      <Scrollbar>
        {status === 'error' ? (
          <Stack
            sx={{
              width: '100%',
              maxHeight: theme.spacing(8), // Limit height for description to 30px
              overflow: 'hidden',
              pb: theme.spacing(1),
            }}
          >
            <Typography
              color={theme.palette.texts.primary}
              sx={{
                mr: theme.spacing(4),
                display: '-webkit-box',
                textOverflow: 'ellipsis',
                WebkitLineClamp: 2, // Limit to two lines
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                maxWidth: '100%',
                pl: '34px',
              }}
              variant="H3Regular"
            >
              {description}
            </Typography>
          </Stack>
        ) : (
          <LocationLabelTooltip title={description} placement="top">
            <Stack
              sx={{
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                cursor: 'default',
              }}
            >
              <Typography
                color={theme.palette.texts.secondary}
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  maxWidth: '100%',
                  pl: '34px',
                }}
                variant="H3Regular"
              >
                {description}
              </Typography>
            </Stack>
          </LocationLabelTooltip>
        )}
        {status === 'error' &&
          messageBody?.map((item, index) => {
            const { fileName, extension } = splitFilename(item);
            return (
              <Stack
                key={index}
                direction={'row'}
                spacing={0}
                sx={{
                  width: '100%',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                <Typography
                  color={theme.palette.texts.secondary}
                  sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    maxWidth: 'calc(100% - 50px)', // Adjust space for the extension
                    pl: '34px',
                  }}
                  variant="H3Regular"
                >
                  {fileName}
                </Typography>
                <Typography
                  color={theme.palette.texts.secondary}
                  sx={{
                    whiteSpace: 'nowrap',
                  }}
                  variant="H3Regular"
                >
                  {extension}
                </Typography>
              </Stack>
            );
          })}
      </Scrollbar>
    </Box>
  );
};
