export enum SharePointFieldType {
  Text = 'Text',
  Number = 'Number',
  DateTime = 'DateTime',
  Choice = 'Choice',
  MultiChoice = 'MultiChoice',
  Boolean = 'Boolean',
  User = 'User',
  UserMulti = 'UserMulti',
  Lookup = 'Lookup',
  LookupMulti = 'LookupMulti',
  Note = 'Note',
  Currency = 'Currency',
  URL = 'URL',
  ContentTypeId = 'ContentTypeId',
  Computed = 'Computed',
  AverageRating = 'AverageRating',
  TaxonomyFieldType = 'TaxonomyFieldType',
  TaxonomyFieldTypeMulti = 'TaxonomyFieldTypeMulti',
  Calculated = 'Calculated',
}

export interface SharePointField {
  'odata.type': string; // e.g., "SP.Field"
  'odata.id': string; // The full URL to access the field
  'odata.editLink': string; // The link to edit this field in the API
  AutofillInfo: any | null; // Typically null if not autofilled
  AutoIndexed: boolean; // Whether the field is auto-indexed
  CanBeDeleted: boolean; // Whether the field can be deleted
  ClientSideComponentId: string; // The component ID for client-side rendering
  ClientSideComponentProperties: any | null; // Properties for the client-side component
  ClientValidationFormula: string | null; // Formula for client-side validation
  ClientValidationMessage: string | null; // Custom message for validation
  CustomFormatter: any | null; // Custom formatter for field rendering
  DefaultFormula: string | null; // Default value formula
  DefaultValue: string | null; // Default value of the field
  Description: string; // Field description
  Direction: string; // Text direction (e.g., "none", "ltr", "rtl")
  EnforceUniqueValues: boolean; // If true, field values must be unique
  EntityPropertyName: string; // The internal property name
  Filterable: boolean; // Whether the field is filterable
  FromBaseType: boolean; // Whether this field comes from a base content type
  Group: string; // The group this field belongs to (e.g., "Custom Columns")
  Hidden: boolean; // If true, the field is hidden
  Id: string; // The unique GUID of the field
  Indexed: boolean; // Whether the field is indexed
  IndexStatus: number; // The status of the index (0 for not indexed)
  InternalName: string; // The internal name of the field
  IsModern: boolean; // Whether the field is modern (used in modern UI)
  JSLink: any | null; // JSLink for customizing field behavior
  PinnedToFiltersPane: boolean; // Whether the field is pinned to the filters pane
  ReadOnlyField: boolean; // If true, the field is read-only
  Required: boolean; // If true, the field is required
  SchemaXml: string; // The XML definition of the field
  Scope: string; // The scope of the field (e.g., "/path/to/library")
  Sealed: boolean; // If true, the field cannot be modified
  ShowInFiltersPane: number; // Whether the field is shown in the filters pane (0 or 1)
  Sortable: boolean; // Whether the field is sortable
  StaticName: string; // The static name of the field
  Title: string; // The display name of the field
  FieldTypeKind: number; // The numeric value of the field type (e.g., 25 for ContentTypeId)
  TypeAsString: SharePointFieldType; // The field type as a string (e.g., "Text", "Choice")
  TypeDisplayName: string; // The display name of the field type (e.g., "Content Type Id")
  TypeShortDescription: string; // A short description of the field type
  ValidationFormula: string | null; // Validation formula for the field
  ValidationMessage: string | null; // Custom validation message
  DisplayFormat: number;
  OutputType: number;
  DateFormat: number;
}
