import { NullableOption } from '@microsoft/microsoft-graph-types';
import _ from 'lodash';

interface HasName {
  name: string;
}

export const toTitleCase = (value: string | object) => {
  if (typeof value === 'string') return _.startCase(value);
  return Object.keys(value).reduce((res, key) => ({ ...res, [_.startCase(key)]: value[key] }), {});
};

export const getSortByName = (sortDir: boolean) => {
  const dir = sortDir ? 1 : -1;
  const collator = new Intl.Collator(undefined, { sensitivity: 'accent' });
  return (a: HasName, b: HasName) => dir * collator.compare(a.name, b.name);
};

export const oDirname = (path: NullableOption<string> | undefined): string => {
  return path?.slice(0, path.lastIndexOf('/')) || '';
};

export const logError = (error: any, msg?: string) => {
  msg = msg || '';
  if (msg.length > 0) {
    if (!msg.endsWith('.')) msg += '.';
    msg += ' ';
  }

  console.warn(`${msg}More Details: ${error?.message || 'none'}`, error);
};

export function findMaxArrayLength(arr: any[]): number {
  let maxLength = arr.length * 30;

  function checkElement(element: any) {
    if (element?.childs?.length) {
      maxLength = Math.max(maxLength, +element.childs.length * 30 + 55);
      element.childs.forEach(checkElement);
    }
  }

  arr.forEach(checkElement);
  return maxLength;
}

export function findParentWithClass(
  event: React.SyntheticEvent,
  className: string,
  maxDepth: number
): HTMLElement | null {
  let depth = 0;
  let target = event.target as HTMLElement;

  while (target && depth < maxDepth) {
    if (target.classList.contains(className)) {
      return target;
    }
    target = target.parentElement as HTMLElement;
    depth++;
  }

  return null;
}

export const calculateProgress = (fileProgress: boolean[], currentProgress: number): number => {
  const totalTasks = fileProgress.length;
  if (totalTasks === 1) return currentProgress;

  const countCompleted = fileProgress.filter(value => value).length;
  const fractionSize = 100 / totalTasks;
  const fractionCompleted = countCompleted / totalTasks;
  const fractionCurrentProgress = (fractionSize * currentProgress) / 10000;

  return Math.min((fractionCompleted + fractionCurrentProgress) * 100, 100);
};
export const formatBytes = (bytes: number): string => {
  if (bytes < 1024) {
    return `${bytes} bytes`;
  }

  const kilobytes = bytes / 1024;
  const megabytes = kilobytes / 1024;
  const gigabytes = megabytes / 1024;

  const formatValue = (value: number): string => {
    return value % 1 === 0 ? value.toFixed(0) : value.toFixed(2); // Show decimal only if not .00
  };

  if (gigabytes >= 1) {
    return `${formatValue(gigabytes)} GB`;
  } else if (megabytes >= 1) {
    return `${formatValue(megabytes)} MB`;
  } else {
    return `${formatValue(kilobytes)} KB`;
  }
};
