import {
  format,
  differenceInMinutes,
  differenceInHours,
  isToday,
  isThisYear,
  isThisWeek,
  isYesterday,
  isDate,
  parseISO,
  isValid,
} from 'date-fns';
import { strings } from '../languages';

type StringsForDates = {
  justNow: string;
  halfAnHour: string;
  twoHoursAgo: string;
  today: string;
  yesterday: string;
  at: string;
};

export const baseStrings = {
  justNow: 'Just now',
  halfAnHour: '30 mins',
  twoHoursAgo: '2 hours ago',
  today: 'Today at',
  yesterday: 'Yesterday at',
  at: 'at',
};

export const formatDateByTime = (dt: Date | string | undefined, strings: StringsForDates): string => {
  if (!dt) return '';
  const date = isDate(dt) && isValid(dt) ? (dt as Date) : isDate(new Date(dt)) ? new Date(dt) : parseISO(dt as string);
  if (!isValid(date)) return '';
  const now = new Date();
  const localNow = now.toLocaleDateString();
  const localDate = date.toLocaleDateString();
  const diffMinutes = differenceInMinutes(localNow, localDate);
  const diffHours = differenceInHours(localNow, localDate);
  const { justNow, halfAnHour, twoHoursAgo, today, yesterday, at } = strings;

  if (diffMinutes < 30 && diffMinutes > 0) {
    return justNow;
  } else if (diffMinutes < 60 && diffMinutes > 0) {
    return halfAnHour;
  } else if (diffHours <= 2 && diffHours > 0) {
    return twoHoursAgo;
  } else if (isToday(date)) {
    return `${today} ${format(date, 'h:mm aa')}`;
  } else if (isYesterday(date)) {
    return `${yesterday} ${format(date, 'h:mm aa')}`;
  } else if (isThisWeek(date)) {
    return `${format(date, 'EEEE')} ${at} ${format(date, 'h:mm aa')}`;
  } else if (isThisYear(date)) {
    return format(date, 'MMMM d');
  } else {
    return format(date, 'MMMM d, yyyy');
  }
};

/**
 * formattingDate is a function that takes in a date string as an argument and returns the local date string.
 */

export const formattingDate = (date_string: string) => {
  const date = new Date(Date.parse(date_string));
  const localDateString = date.toString().slice(0, date.toString().lastIndexOf(' GMT'));
  return localDateString;
};

// date - if today return time else return in this format 21.05.23
export const formatReceivedDate = (date?: Date) => {
  const today = new Date();
  if (date?.toDateString() === today.toDateString()) {
    // If the date is today, format with time like "14:25 PM"
    return date?.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: false });
  } else {
    // If the date is not today, format with the full date
    return date?.toLocaleDateString('en-GB', { year: '2-digit', month: '2-digit', day: '2-digit' }).replace(/\//g, '.');
  }
};

export const getFriendlyDateDisplay = (modifiedDateStr: string) => {
  return formatDateByTime(modifiedDateStr.toString(), strings.lang.dateFormat);
};
