import { template as _template } from 'lodash';
import { en, fr, de } from './stringsFlat';

export type ByType<T> = {
  [key in any]?: T;
};
function asType<T>(arg: T) {
  return arg;
}

/* If we'll ever need a complex typing.
type NextType<A> = { as<B extends A>(b: B): B };
function extendsType<T>(): NextType<T> {
  return {
    as: <B>(b: B) => b,
  };
}
*/

export interface IndicatorTexts {
  canceled: string;
  complete: string;
  oneItemProgress: string;
  progressDone: (args: any) => string; // Assuming _template function returns a string and takes any type of arguments
  success: (args: any) => string; // Assuming _template function returns a string and takes any type of arguments
  unableToRun: string;
  cancel: string;
  failed: (args: any) => string; // Assuming _template function returns a string and takes any type of arguments
}

export interface ManageListStrings {
  title: string;
  addedItems: string;
  availabeItems: string;
  description?: string;
  emptyState: string;
  placeholderSearch?: string;
  showMoreHint: string;
  errorMessage: string;
  include_teams: string;
}

export interface SafeCallbackStrings {
  errorTitle: string;
  errorMessage: string;
  onStartTitle?: string;
  onSuccessTitle?: string;
  actionTitle?: string;
}

export interface NewItemDialogTexts {
  createButton?: string;
  successNote: string;
  errorTitle: string;
  errorMessage: string;
  subTitle: string;
}

export interface SimpleDialogTexts {
  dialogTitle: string;
  dialogSubTitle: string;
  primaryActionText: string;
  secondaryActionText: string;
  checkboxLabel?: string;
}

const getStrings = (lang: typeof en) => {
  const res = {
    emailProps: {
      from: lang.emailProps_from,
      to: lang.emailProps_to,
      noSubject: lang.emailProps_noSubject,
    },
    manageLists: {
      clear: lang.manageLists_clear,
      noResults: _template(`${lang.manageLists_noResults} '<%= name %>'`),
    },
    actiosNames: {
      undo: lang.actionsNames_undo,
      goBack: lang.actionsNames_goBack,
      ok: lang.actionsNames_ok,
      showAllResults: lang.actionsNames_showAllResults,
      searchAllLocation: lang.actionsNames_searchAllLocation,
      showMoreFilters: lang.actionsNames_showMoreFilters,
      showLessFilters: lang.actionsNames_showLessFilters,
      breadcrumbsAction: lang.actionsNames_breadcrumbsAction,
      backToTop: lang.actionsNames_backToTop,
    },
    tooltip: {
      shareDisabled: lang.tooltip_shareDisabled,
      chatAndFilesSearch: lang.tooltip_chatAndFilesSearch,
    },
    QuickSearchFilters: {
      sharePoint: lang.quickSearchFilters_sharePoint,
      oneDrive: lang.quickSearchFilters_oneDrive,
      teams: lang.quickSearchFilters_teams,
      team: lang.quickSearchFilters_team,
      channel: lang.quickSearchFilters_channel,
      site: lang.quickSearchFilters_site,
      library: lang.quickSearchFilters_library,
      folder: lang.quickSearchFilters_folder,
      allFiles: lang.quickSearchFilters_allFiles,
      word: lang.quickSearchFilters_word,
      powerPoint: lang.quickSearchFilters_powerPoint,
      excel: lang.quickSearchFilters_excel,
      pdf: lang.quickSearchFilters_pdf,
      email: lang.quickSearchFilters_email,
    },
    dateFormat: {
      justNow: lang.dateFormat_justNow,
      halfAnHour: lang.dateFormat_halfAnHour,
      twoHoursAgo: lang.dateFormat_twoHoursAgo,
      today: lang.dateFormat_today,
      yesterday: lang.dateFormat_yesterday,
      at: lang.dateFormat_at,
    },
    replaceDialog: {
      title: lang.replaceDialogTitle,
      message: _template(`${lang.replaceDialogMeesage} '<%= name %>' ?`),
      keepBoth: lang.replaceDialogSecondry,
      replace: lang.replaceDialogPrimary,
      checkbox: lang.replaceDialogCheckbox,
    },
    onBoarding: {
      welcomePageTitle: lang.onBoarding_welcomePageTitle,
      welcomePageMessage: lang.onBoarding_welcomePageMessage,
      uploadTitle: lang.onBoarding_uploadTitle,
      uploadMessage: lang.onBoarding_uploadMessage,
      shareTitle: lang.onBoarding_shareTitle,
      shareMessage: lang.onBoarding_shareMessage,
      searchTitle: lang.onBoarding_searchTitle,
      searchMessage: lang.onBoarding_searchMessage,
      teamsPageTitle: lang.onBoarding_teamsPageTitle,
      teamsPageMessage: lang.onBoarding_teamsPageMessage,
      sharePointPageTitle: lang.onBoarding_sharePointPageTitle,
      sharePointPageMessage: lang.onBoarding_sharePointPageMessage,
      onBoardingDoneTitle: lang.onBoarding_DoneTitle,
      onBoardingLoadingTitle: lang.onBoarding_DoneLoadingTitle,
      sharePointPageHeader: lang.onBoarding_sharePointPageHeader,
      requiredNewPremission: lang.onBoarding_requiredNewPremission,
      teamsPageHeader: lang.onBoarding_teamsPageHeader,
      skip: lang.onBoarding_skip,
      done: lang.onBoarding_done,
      teamsSelected: lang.onBoarding_selectedTeams,
      sitesSelected: lang.onBoarding_selectedSites,
      loadMore: lang.onBoarding_loadMore,
      next: lang.onBoarding_next,
      back: lang.toolbarActions_back,
      getStarted: lang.onBoarding_getStarted,
      selectAllSites: lang.onBoarding_selectAllSites,
      selectAllTeams: lang.onBoarding_selectAllTeams,
      unselectAllSites: lang.onBoarding_unselectAllSites,
      unselectAllTeams: lang.onBoarding_unselectAllTeams,
      selectAll: lang.onBoarding_selectAll,
      unselectAll: lang.onBoarding_unselectAll,
    },
    selectItemsModal: {
      title: lang.selectItemsModal_title,
      description: lang.selectItemsModal_description,
      selectAll: lang.selectItemsModal_selectAll,
      unselectAll: lang.selectItemsModal_unselectAll,
      selectedItems: lang.selectItemsModal_itemsSelected,
      primaryButton: lang.selectItemsModal_primaryButton,
      secondaryActionText: lang.userSetting_resetdialog_secondaryAction,
      prepareEmails: lang.selecItemsModal_fetchingEmails,
      unableToSave: lang.selecItemsModal_unable_to_save,
      itemSize: lang.selecItemsModal_item_size,
    },
    errorMessages: {
      drilldownNavigation: lang.errorMessages_drilldownNavigation,
      cannotGetItems: lang.errorMessages_cannotGetItems,
      tryAgain: lang.errorMessages_tryAgain,
    },
    userManage: {
      teams: asType<ManageListStrings>({
        title: lang.userManage_teams_title,
        description: lang.userManage_teams_description,
        availabeItems: lang.userManage_teams_availableItems,
        addedItems: lang.userManage_teams_addedItems,
        placeholderSearch: lang.userManage_teams_placeholderSearch,
        showMoreHint: lang.userManage_teams_showMoreHint,
        emptyState: lang.userManage_teams_emptyState,
        errorMessage: lang.userManage_teams_errorMessage,
        include_teams: '',
      }),
      sites: asType<ManageListStrings>({
        title: lang.userManage_sites_title,
        description: lang.userManage_sites_description,
        include_teams: lang.userManage_sites_show_teams,
        availabeItems: lang.userManage_sites_availableItems,
        addedItems: lang.userManage_sites_addedItems,
        placeholderSearch: lang.userManage_sites_placeholderSearch,
        showMoreHint: lang.userManage_sites_showMoreHint,
        emptyState: lang.userManage_sites_emptyState,
        errorMessage: lang.userManage_sites_errorMessage,
      }),
    },
    userSetting: {
      resetDialog: asType<SimpleDialogTexts>({
        dialogTitle: lang.userSetting_resetdialog_warningTitle,
        dialogSubTitle: lang.userSetting_resetdialog_warningBody,
        secondaryActionText: lang.userSetting_resetdialog_secondaryAction,
        primaryActionText: lang.userSetting_resetdialog_primaryAction,
      }),
      clearDialog: asType<SimpleDialogTexts>({
        dialogTitle: lang.userSetting_cleardialog_warningTitle,
        dialogSubTitle: lang.userSetting_cleardialog_warningBody,
        secondaryActionText: lang.userSetting_cleardialog_secondaryAction,
        primaryActionText: lang.userSetting_cleardialog_primaryAction,
      }),
      signoutDialog: asType<SimpleDialogTexts>({
        dialogTitle: lang.userSetting_signoutdialog_warningTitle,
        dialogSubTitle: lang.userSetting_signoutdialog_warningBody,
        secondaryActionText: lang.userSetting_signoutdialog_secondaryAction,
        primaryActionText: lang.userSetting_signoutdialog_primaryAction,
      }),
      clearTitle: lang.userSetting_cleardialog_warningTitle,
      resetTitle: lang.userSetting_resetTitle,
      signOutTitle: lang.userSetting_signOutTitle,
      actionName: lang.userSetting_actionName,
      documentOpenTitle: lang.userSetting_documentsTitle,
      documentOpenDescription: lang.userSetting_documentsDescription,
      emailNameTitle: lang.userSetting_emailNameTitle,
      emailNamesDescription: lang.userSetting_emailNamesDescription,
      emailNamesDefault: lang.userSetting_emailNamesDefault,
      emailNamesAdvanced: lang.userSetting_emailNamesAdvanced,

      treeSettingFileTitle: lang.userSetting_treeSettingFileTitle,
      treeSettingFileDescription: lang.userSetting_treeSettingFileDescription,

      treeSettingHistoryTitle: lang.userSetting_treeSettingHistoryTitle,
      treeSettingHistoryDescription: lang.userSetting_treeSettingHistoryDescription,
      themeSelectorTitle: lang.userSetting_themeSelectorTitle,
      themeSelectorSystem: lang.userSetting_themeSelectorSystem,
      themeSelectorLight: lang.userSetting_themeSelectorLight,
      themeSelectorDark: lang.userSetting_themeSelectorDark,
      addCategoryDescription: lang.userSetting_addCategoryDescription,
      addCategoryTitle: lang.userSetting_addCategoryTitle,

      ehmSettingTitle: lang.userSetting_ehmTitle,
      ehmSettingDescription: lang.userSetting_ehmDescription,
      ehmSettingCheckboxAllLocations: lang.userSetting_ehmCheckboxAllLocations,
      ehmSettingCheckboxTeams: lang.userSetting_ehmCheckboxTeams,
      ehmSettingCheckboxNone: lang.userSetting_ehmCheckboxNone,
    },
    deleteDialog: asType<SimpleDialogTexts>({
      dialogTitle: lang.deleteDialog_warningTitle,
      dialogSubTitle: lang.deleteDialog_warningBody,
      primaryActionText: lang.deleteDialog_actionName,
      secondaryActionText: lang.deleteDialog_cancel,
    }),

    slider: {
      filter: lang.SliderFilter,
      showAttachments: lang.SliderFilterOn,
    },
    filePreview: {
      youSent: lang.filePreview_youSent,
      youShared: lang.filePreview_youShared,
      youEdited: lang.filePreview_editedThis,
      shared: lang.filePreview_shared,
      recentlyOpened: lang.filePreview_recentlyOpened,
      recentlyEdited: lang.filePreview_recentlyEdited,
      sharedInChat: lang.filePreview_sharedInChat,
      youSharedInChat: lang.filePreview_youSharedInChat,
      sent: lang.filePreview_sentThis,
      emailAttachment: lang.filePreview_emailAttachment,
    },

    emptyStateTitle: {
      favorites: lang.emptyStateTitle_favorites,
      recent: lang.emptyStateTitle_recent,
      sharedwithme: lang.emptyStateTitle_sharedwithme,
      teams: lang.emptyStateTitle_teams,
      chatsAndMeetings: lang.emptyStateTitle_chatsAndMeetings,
      sharePoint: lang.emptyStateTitle_sharePoint,
      oneDrive: lang.emptyStateTitle_oneDrive,
      drilldown: lang.emptyStateTitle_drilldown,
      drilldownWithView: lang.emptyStateTitle_drilldownWithView,
      noSearchResultInLocation: lang.emptyStateTitle_noSearchResultInLocation,
      globalNoSearchResult: lang.emptyStateTitle_globalNoSearchResult,
      searchResultLastItem: lang.emptyStateTitle_searchResultLastItem,
    },
    emptyStateMessage: {
      favorites: lang.emptyStateMessage_favorites,
      recent: lang.emptyStateMessage_recent,
      sharedwithme: lang.emptyStateMessage_sharedwithme,
      teams: lang.emptyStateMessage_teams,
      chatsAndMeetings: lang.emptyStateMessage_chatsAndMeetings,
      sharePoint: lang.emptyStateMessage_sharePoint,
      oneDrive: lang.emptyStateMessage_oneDrive,
      drilldown: lang.emptyStateMessage_drilldown,
      noSearchResultInLocation: lang.emptyStateMessage_noSearchResultInLocation,
      searchResultLastItem: lang.emptyStateMessage_searchResultLastItem,
      quickSearchWithResults: lang.emptyStateMessage_quickSearchWithResults,
    },
    actionDefaults: asType<SafeCallbackStrings>({
      errorMessage: lang.actionDefaults_errorMessage,
      errorTitle: lang.actionDefaults_errorTitle,
      onStartTitle: undefined,
      onSuccessTitle: undefined,
    }),
    removeItem: asType<ByType<Partial<SafeCallbackStrings>>>({
      team: {
        errorTitle: lang.removeItem_team_errorTitle,
        onSuccessTitle: lang.removeItem_team_onSuccessTitle,
        actionTitle: lang.removeItem_team_actionTitle,
      },
      site: {
        errorTitle: lang.removeItem_site_errorTitle,
        onSuccessTitle: lang.removeItem_site_onSuccessTitle,
        actionTitle: lang.removeItem_team_actionTitle,
      },
      insight: {
        errorTitle: lang.removeItem_insight_errorTitle,
        onSuccessTitle: lang.removeItem_insight_onSuccessTitle,
        actionTitle: lang.removeItem_item_actionTitle,
      },
      document: {
        errorTitle: lang.removeItem_document_errorTitle,
        onSuccessTitle: lang.removeItem_document_onSuccessTitle,
        actionTitle: lang.removeItem_item_actionTitle,
      },
      folder: {
        errorTitle: lang.removeItem_folder_errorTitle,
        onSuccessTitle: lang.removeItem_folder_onSuccessTitle,
        actionTitle: lang.removeItem_item_actionTitle,
      },
    }),
    signIn: lang.signIn,
    signOut: lang.signOut,
    logOutTitle: lang.logOutTitle,
    logOutMessage: lang.logOutMessage,
    allLocations: lang.allLocations,
    collapseAll: lang.collapseAll,
    actionToolTip: {
      openMenu: lang.actionToolTip_openMenu,
      simpleView: lang.actionToolTip_simpleView,
      detailsView: lang.actionToolTip_detailsView,
      upload: lang.actionToolTip_upload,
      refresh: lang.actionToolTip_refresh,
      share: lang.actionToolTip_share,
      newItem: lang.actionToolTip_newItem,
      copyAddress: lang.actionToolTip_copyAddress,
      openInBrowser: lang.actionToolTip_openInBrowser,
      openInBrowserApp: lang.actionToolTip_openInBrowserApp,
      openInApp: lang.actionToolTip_openInApp,
      download: lang.actionToolTip_download,
      addfavorite: lang.actionToolTip_addFavorite,
      removefavorite: lang.actionToolTip_removeFavorite,
      manageItemCount: lang.actionToolTip_manageItemCount,
      manage: lang.actionToolTip_manage,
      viewSetting: lang.actionToolTip_viewSetting,
      itemCountTitle: lang.actionToolTip_itemCountTitle,
      itemCountDescription: lang.actionToolTip_itemCountDescription,
      saveEmailDisabled: lang.actionToolTip_saveEmailDisabled,
      saveEmailOnError: `${lang.officeSelectionErrorTitle}: ${lang.officeSelectionErrorMessage}`,
      openInTeamsApp: lang.actionToolTip_openInTeamsApp,
      openContainingLocation: lang.actionToolTip_openContainingLocation,
      moreActions: lang.actionToolTip_moreActions,
      favorite: lang.actionToolTip_favorite,
      copyLink: lang.actionToolTip_copyLink,
      copyLinkShort: lang.actionToolTip_copyLinkShort,
      emailWithLink: lang.actionToolTip_emailWithLink,
      addLink: lang.actionToolTip_addLink,
      addAttachment: lang.actionToolTip_addAttachment,
      emailWithLinkShort: lang.actionToolTip_emailWithLinkShort,
      shareToTeams: lang.actionToolTip_shareToTeams,
      shareToTeamsShort: lang.actionToolTip_shareToTeamsShort,
      editOnSharepoint: lang.actionToolTip_editOnSharepoint,
      reorderA2Z: lang.actionToolTip_reorderA2Z,
      reorderZ2A: lang.actionToolTip_reorderZ2A,
      searchAllLocations: lang.actionToolTip_searchAllLocations,
      newFolder: lang.actionToolTip_newFolder,
      newWordDocument: lang.actionToolTip_newWordDocument,
      newExcelDocument: lang.actionToolTip_newExcelDocument,
      newLinkDocument: lang.actionToolTip_newLinkDocument,
      newPowerPointDocument: lang.actionToolTip_newPowerPointDocument,
      new: lang.actionToolTip_new,
    },
    toolbarActions: {
      home: lang.toolbarActions_home,
      back: lang.toolbarActions_back,
      sort: lang.toolbarActions_sort,
      saveEmail: lang.toolbarActions_saveEmail,
    },
    allLocatiosToolbar: {
      getSupport: lang.allLocatiosToolbar_getSupport,
      gotoSettings: lang.allLocatiosToolbar_gotoSettings,
      feedback: lang.allLocatiosToolbar_feedback,
      feedbackTitle: lang.allLocatiosToolbar_feedbackTitle,
    },
    newItemDialogTexts: {
      defaults: {
        createButton: lang.newItemDialogTexts_defaults_createButton,
      },
      document: {
        subTitle: lang.newItemDialogTexts_document_subTitle,
        successNote: lang.newItemDialogTexts_document_successNote,
        errorTitle: lang.newItemDialogTexts_document_errorTitle,
        errorMessage: lang.newItemDialogTexts_document_errorMessage,
      },
      folder: {
        subTitle: lang.newItemDialogTexts_folder_subTitle,
        successNote: lang.newItemDialogTexts_folder_successNote,
        errorTitle: lang.newItemDialogTexts_folder_errorTitle,
        errorMessage: lang.newItemDialogTexts_folder_errorMessage,
      },
      renameFile: {
        title: lang.newItemDialogTexts_renameFile_title,
        subTitle: lang.newItemDialogTexts_renameFile_subTitle,
        successNote: lang.newItemDialogTexts_renameFile_successNote,
        errorTitle: lang.newItemDialogTexts_renameFolder_errorTitle,
        errorMessage: lang.newItemDialogTexts_renameFile_errorMessage,
        createButton: lang.newItemDialogTexts_renameFile_createButton,
      },
      renameFolder: {
        title: lang.newItemDialogTexts_renameFolder_title,
        subTitle: lang.newItemDialogTexts_renameFolder_subTitle,
        successNote: lang.newItemDialogTexts_renameFolder_successNote,
        errorTitle: lang.newItemDialogTexts_renameFolder_errorTitle,
        errorMessage: lang.newItemDialogTexts_renameFolder_errorMessage,
        createButton: lang.newItemDialogTexts_renameFolder_createButton,
      },
    },
    labels: {
      category: lang.categoryLabel,
    },
    itemDialogTexts: {
      folderTitle: lang.itemDialogTexts_folderTitle,
      folderSubTitle: lang.itemDialogTexts_folderSubTitle,
      fileSubTitle: lang.itemDialogTexts_fileSubTitle,
      urlPath: lang.itemDialogTexts_urlPath,
      defaultUrl: lang.itemDialogTexts_defaultUrl,
      wordDocumentTitle: lang.itemDialogTexts_wordDocumentTitle,
      linkDocumentTitle: lang.itemDialogTexts_linkDocumentTitle,
      excelDocumentTitle: lang.itemDialogTexts_excelDocumentTitle,
      powerPointDocumentTitle: lang.itemDialogTexts_powerPointDocumentTitle,
      cancel: lang.itemDialogTexts_cancel,
      itemExistError: lang.itemDialogTexts_itemExistError,
      urlNotValid: lang.itemDialogTexts_urlNotValid,
      itemNameNotValid: lang.itemDialogTexts_itemNameNotValid,
      requiredField: lang.itemDialogTexts_requiredField,
    },
    sortMemuActions: {
      name: lang.sortMenuActions_name,
      dateModified: lang.sortMenuActions_dateModified,
      dateCreated: lang.sortMenuActions_dateCreated,
      ascending: lang.sortMenuActions_ascending,
      descending: lang.sortMenuActions_descending,
    },
    doubleClickAction: {
      openInApp: lang.doubleClickAction_openInApp,
      openInBrowserApp: lang.doubleClickAction_openInBrowserApp,
      openInBrowser: lang.doubleClickAction_openInBrowser,
      askEveryTime: lang.doubleClickAction_askEveryTime,
      downloads: lang.doubleClickAction_downloads,
      dialogTitle: lang.doubleClickAction_dialogTitle,
      appDialogTitle: lang.doubleClickAction_appDialogTitle,
      checkboxLabel: lang.doubleClickAction_checkboxLabel,
    },
    nodeNames: {
      favorites: lang.nodeNames_favorites,
      recent: lang.nodeNames_recent,
      sharedWithMe: lang.nodeNames_sharedWithMe,
      teams: lang.nodeNames_teams,
      search: lang.nodeNames_search,
      defaultSearch: lang.nodeNames_defaultSearch,
      sharePoint: lang.nodeNames_sharePoint,
      oneDrive: lang.nodeNames_oneDrive,
      viewAllFiles: lang.nodeNames_viewAllFiles,
      teamsChatFiles: lang.nodeNames_teamsChatFiles,
      searchAllLocations: lang.nodeNames_searchAllLocations,
    },
    notifications: {
      emailWithAttachmentStart: lang.notifications_emailWithAttachmentStart,
      emailWithAttachmentDone: lang.notifications_emailWithAttachmentDone,
      copyLink: lang.notifications_copyLink,
      addToFavorite: lang.notifications_addToFavorite,
      renovedFromFavorite: lang.notifications_renovedFromFavorite,
      openInBrowser: lang.notifications_openInBrowser,
      doubleClick: lang.notifications_doubleClick,
    },
    saveEmail: asType<SafeCallbackStrings>({
      errorTitle: lang.notificationsError_saveEmailFailedTitle,
      errorMessage: lang.notificationsError_saveEmailFailedMessage,
      onStartTitle: lang.notifications_saveEmailStart,
      onSuccessTitle: lang.notifications_saveEmailDone,
      actionTitle: lang.actionToolTip_saveEmail,
    }),
    emailPreview: {
      emailPreviewTitle: lang.emailPreview_title,
      emailsPreviewTitle: lang.emailsPreview_title,
    },
    dragAndDrop: {
      errorTitle: lang.dragAndDropFailedTitle,
      errorMessage: lang.dragAndDropErrorMessage,
      onStartTitle: lang.notifications_saveEmailStart,
      onSuccessTitle: lang.notifications_saveEmailDone,
    },
    notificationsError: {
      uploadFailedTitle: lang.notificationsError_uploadFailedTitle,
      officeSelectionErrorTitle: lang.officeSelectionErrorTitle,
      officeSelectionErrorMessage: lang.officeSelectionErrorMessage,
      dragAndDropFailedTitle: lang.dragAndDropFailedTitle,
      dragAndDropItemNotSupporteddMessage: lang.dragAndDropItemNotSupportedMeesage,
      uploadFailedMessage: lang.notificationsError_uploadFailedMessage,
      drilldownFailedTitle: lang.notificationsError_drilldownFailedTitle,
      drilldownFailedMessage: lang.notificationsError_drilldownFailedMessage,
      emailWithAttachmentFailedTitle: lang.notificationsError_emailWithAttachmentFailedTitle,
      emailWithAttachmentFailedMessage: lang.notificationsError_emailWithAttachmentFailedMessage,
    },
    results: {
      countResults: lang.results_countResults,
    },
    misc: {
      locations: {
        site: lang.site,
        list: lang.list,
        folder: lang.folder,
        channel: lang.channel,
        team: lang.team,
        location: lang.location,
      },
      rootSite: lang.RootSite,
      clearTooltipText: lang.quickSearchclearTooltipText,
      shareToTeamsIntro: _template(
        `${lang.ShareToTeamsIntroStartWith} <b><%= name %></b> ${lang.ShareToTeamsIntroEndWith}:`
      ),
    },
    quickSearch: {
      searchPlaceholder: data =>
        _template(`${lang.quickSearchsearchPlaceholder} <%= patch %>`)({
          patch: res.misc.locations[data.patch] || data.patch,
        }),
      clearTooltipText: lang.quickSearchclearTooltipText,
      clearFilterText: lang.quickSearchclearFilterText,
      historyTitle: lang.quickSearchhistoryTitle,
      oneDrive: lang.quickSearchoneDrive,
      chatAndFiles: lang.quickSearchchatAndFiles,
      generalSearch: lang.quickSearchgeneralSearch,
      searchAllLocation: lang.nodeNames_searchAllLocations,
    },
    indicatorTexts: asType<IndicatorTexts>({
      canceled: lang.upload_canceled,
      complete: lang.upload_complete,
      oneItemProgress: lang.uploading_one_item,
      progressDone: _template(lang.progress_uploaded),
      success: _template(lang.items_uploaded),
      unableToRun: lang.unable_to_upload,
      cancel: lang.cancel_upload,
      failed: _template(lang.fail_upload),
    }),
    ehm_dialog: {
      ehmTeam_title: lang.createEmailHeaderMappingTeam_title,
      ehmTeam_checkmark1: lang.createEmailHeaderMappingTeam_checkmark1,
      ehmSite_title: lang.createEmailHeaderMappingSite_title,
      ehmSite_checkmark1: lang.createEmailHeaderMappingSite_checkmark1,
      ehm_checkmark2: lang.createEmailHeaderMapping_checkmark2,
      ehm_checkmark3: lang.createEmailHeaderMapping_checkmark3,
      ehm_checkboxText: lang.createEmailHeaderMapping_checkboxText,
      ehm_primary_button: lang.ehm_primary_button,
      ehm_secondary_button: lang.ehm_secondary_button,
      ehm_errorTitle: lang.createEmailHeaderMapping_error,
      ehm_successTitle: lang.createEmailHeaderMapping_success,
    },
    shareDialogTexts: {
      titleStart: lang.ShareToTeamsIntroStartWith,
      titleEnd: lang.ShareToTeamsIntroEndWith,
      shareTo: lang.shareDialogTexts_shareTo,
      viewMailText: lang.shareDialogTexts_viewMailText,
      notifyText: lang.shareDialogTexts_notifyText,
      noPreview: lang.shareDialogTexts_noPreview,
    },
    search: {
      placeHolder: lang.searchPlaceHolder,
      noResults: lang.emptyStateTitle_globalNoSearchResult,
      peoples: lang.searchPeoples,
      channels: lang.searchChannels,
      chatAndGroups: lang.searchChatsAndGroups,
    },
    email: {
      msgBody: lang.emailProps_msgBody,
      from: lang.emailProps_from,
      to: lang.emailProps_to,
      shareEmail: lang.emailShareEmail,
    },
    emailHeaderMapping: {
      errorTitle: lang.emailHeaderMappingFailed,
    },
    actions: {
      cancel: lang.deleteDialog_cancel,
      share: lang.actionToolTip_share,
      copyLink: lang.actionToolTip_copyLinkShort,
      gotoTeams: lang.actionToolTip_openInTeamsApp,
      close: lang.actionsNames_close,
    },
    successPage: {
      title: lang.successPage_title,
      notification: lang.successPage_notification,
    },
    errorPage: {
      genericTitle: lang.errorPage_genericTitle,
      genericBody: lang.errorPage_genericBody,
      title: lang.errorPage_title,
      body: lang.errorPage_body,
      linkCopied: lang.errorPage_linkCopied,
    },
    viewsAction: {
      title: lang.viewsAction_title,
      changeViewSuccessMessage: _template(lang.viewsAction_changeViewSuccessMessage),
    },
    advancedFilterAction: {
      title: lang.advancedFilterAction_title,
    },
  };
  return res;
};

export const strings = { lang: getStrings(en) };

const setStrings = (lang: typeof en) => {
  strings.lang = getStrings(lang);
};

export const setLanguage = (lang: string): void => {
  switch (lang) {
    case 'en-US':
      setStrings(en);
      break;
    case 'fr-FR':
      setStrings(fr);
      break;
    case 'de-DE':
      setStrings(de);
      break;
  }
};
