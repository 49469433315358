import { ViewItem, RefreshMedium } from '@storybook';
import { useLocation, useNavigate } from 'react-router-dom';
import { strings } from '@vendor/languages';
import { useGraphClient } from '@services';
import { ActionRendererHookType, BaseReadActionClass, ItemContainer, ItemData, RefreshAction } from '~/utilities';
import { DrilldownState, navigateDrilldown } from '~/components/Tree/Renderers/DrilldownHandler';

const useLocationRefreshAction: ActionRendererHookType = ({ nodes, useCallback }) => {
  const gcl = useGraphClient();
  const location = nodes[0].data as ItemContainer;
  const { state } = useLocation() as DrilldownState;
  const navigate = useNavigate();
  const onClick = useCallback(async () => {
    if (location.hasFolder) {
      try {
        const folder = await location.getFolder(gcl);
        RefreshAction.markRefreshStamp(folder.list, Date.now());
      } catch {} // ignore errors here
    }
    navigateDrilldown(navigate, location, true, Date.now(), state.newItems, state?.view);
  }, [gcl, location, navigate, state.newItems, state?.view]);
  return {
    title: strings.lang.actionToolTip.refresh,
    icon: RefreshMedium,
    onClick,
  };
};

export class LocationRefreshAction extends BaseReadActionClass {
  readonly trackedName = 'RefreshItem';
  readonly useRenderer = useLocationRefreshAction;

  isQuickAction(data: ItemData) {
    return data.isRootSection ? 'only' : undefined;
  }

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return nodes[0].data.canRefresh;
  }
}
