import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { MIN_THUMB_SIZE } from './common';

export const ScrollbarWrap = styled(Box, { shouldForwardProp: prop => prop !== 'noHorizontal' })<{
  noHorizontal?: boolean;
}>`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  padding-bottom: ${props => (props.noHorizontal ? `0px` : '15px')};
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
  user-select: none;
`;

export const ScrollbarContainer = styled(Box, { shouldForwardProp: prop => prop !== 'noHorizontal' })<{
  noHorizontal?: boolean;
}>`
  width: 100%;
  height: 100%;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
  user-select: none;
`;

export const Debugger = styled('div')`
  position: fixed;
  z-index: 1000;
  padding: 10px;
  display: flex;
  flex-direction: column;
  top: 0px;
  left: 0px;
  opacity: 0.5;
  color: white;
  background: black;
  & > div {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    & > span {
      margin-right: 10px;
    }
  }
`;

export const ScrollBarIndicator = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'horizontal' &&
    prop !== 'vertical' &&
    prop !== 'withThrottling' &&
    prop !== 'verticalOffset' &&
    prop !== 'horizontalOffset',
})<{
  horizontal?: boolean;
  vertical?: boolean;
  withThrottling?: boolean;
  verticalOffset?: number;
  horizontalOffset?: number;
}>`
  ${props => props.vertical && 'top: 0px;'}
  ${props => props.vertical && `right: ${props.horizontalOffset || 0}px;`};
  ${props => props.horizontal && 'left: 0px;'}
  ${props => props.horizontal && `bottom: ${props.verticalOffset || 0}px;`};
  position: absolute;
  z-index: 10;
  ${props => (props.vertical ? `height: ${MIN_THUMB_SIZE}px;` : 'height: 30px;')};
  ${props => (props.horizontal ? `width: ${MIN_THUMB_SIZE}px;` : 'width: 30px;')};
  padding: 10px;
  transition-timing-function: linear;
  ${props => props.withThrottling && 'transition: all 0.2s;'};
  display: flex;
  opacity: 0;
  visibility: hidden;
  transform: translateX(0);
  &.onScroll {
    opacity: 1;
    visibility: visible;
  }
`;

export const ScrollIndicatorBody = styled('div')`
  ${({ theme }) => `
    flex: 1;
    background: ${theme.palette.scrollBar.default};
    border-radius: 5px;
    cursor: pointer;
    overflow: hidden;
    &:hover {
      background: ${theme.palette.scrollBar.hover};
    }
    .onPress & {
      background: ${theme.palette.scrollBar.pressed};
    }
  `}
`;
