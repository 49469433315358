import React, { useCallback, useContext } from 'react';
import {
  ContextMenuContext,
  MenuAction,
  MouseEventsContext,
  MouseEventsInterface,
  ViewItem,
  AdvancedFiltersMedium,
  styled,
  ContainerHandlers,
} from '@storybook';
import { strings } from '@vendor/languages';
import { useLocation } from 'react-router-dom';
import { generateSubMenuSortAction } from '~/modules/Drilldown/DrilldownToolbar/Actions';
import { DrilldownState } from '~/components/Tree/Renderers/DrilldownHandler';
import { ItemData } from '../itemTypes';
import { BaseMenuActionClass } from './BaseMenuAction';
import { ActionRendererHookType, BaseAction } from './BaseAction';
import { generateActionMenu } from './ContextMenuAction';
import { SPListViewWithOrderBy } from '../sharePointTypes/SPListViewFactory';
const BlueCircle = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '0px', // Adjust the position to match your needs
  right: '0px', // Adjust the position to match your needs
  width: '10px', // Size of the blue circle
  height: '10px',
  borderRadius: '5px',
  border: `2px solid ${theme.palette.backgrounds.primary}`, // Corrected from backgrounds.primary
  backgroundColor: `${theme.palette.texts.accentSecondary}`, // Corrected from texts.accentSecondary
}));

const IconWrapper = styled('div')(() => ({
  position: 'relative',
  display: 'inline-block',
}));

const useAdvancedFilterRender: ActionRendererHookType = ({ trigger, action, nodes, handlers }) => {
  const node = nodes[0];
  const menuAction = action as BaseMenuActionClass;
  const { handleContextMenu, open } = useContext(ContextMenuContext);
  const { waitForAction, actionDone } = useContext<MouseEventsInterface>(MouseEventsContext);

  const openMenu = useCallback(
    (e: React.MouseEvent) => {
      const actions: MenuAction[] = generateActionMenu(menuAction.actions, [node], trigger, handlers);
      const sortAction: MenuAction = generateSubMenuSortAction();
      actions?.unshift(sortAction);

      if (menuAction.isContextMenu) e['contextmenu'] = true;
      e['no-expand'] = true;
      e['no-open'] = true;
      if (actions.length) {
        waitForAction?.();
        handleContextMenu?.(e, actions, actionDone);
      }
    },
    [
      actionDone,
      handleContextMenu,
      handlers,
      menuAction.actions,
      menuAction.isContextMenu,
      node,
      trigger,
      waitForAction,
    ]
  );

  return {
    'aria-controls': open ? 'basic-menu' : undefined,
    'aria-haspopup': 'true',
    'aria-expanded': open ? 'true' : undefined,
    onClick: openMenu,
    opened: open && !handlers?.isSelected(node),
    title: menuAction.title,
    icon: menuAction.icon,
  };
};
export class AdvancedFilterAction extends BaseMenuActionClass {
  private handlers: ContainerHandlers<ItemData>;
  constructor(subMenu: BaseAction[], handlers: ContainerHandlers<ItemData>) {
    super(subMenu);
    this.handlers = handlers;
  }

  readonly title = strings.lang.advancedFilterAction.title;

  isSortApplied(): boolean {
    //it's hacky, need to figure out how to do it better
    const {
      state: { view, orderBy },
      // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useLocation() as DrilldownState;
    //special edge case where the view is on default and not defined but orderBy is defined
    return (!view && orderBy) || view instanceof SPListViewWithOrderBy;
  }

  private isModified() {
    return this.handlers.hasNewItems() || this.isSortApplied() || this.actions.find(action => action.isDirty());
  }

  get icon() {
    const Icon = this.isModified() ? (
      <IconWrapper>
        <AdvancedFiltersMedium />
        <BlueCircle />
      </IconWrapper>
    ) : (
      <AdvancedFiltersMedium />
    );
    return Icon;
  }

  isQuickAction() {
    return 'only';
  }

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return nodes?.[0]?.data?.hasFolder;
  }

  override useRenderer = useAdvancedFilterRender;
}
