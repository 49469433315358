export interface ConfigurationState {
  auth: { clientId: string | undefined };
}
export class ConfigurationBase implements ConfigurationState {
  auth: { clientId: string | undefined };
  version: string;
  whatsNewNotificationStorageType: string;
  whatsNewNotificationUrl: string;
  constructor() {
    this.auth = {
      clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
    };
    this.version = '0.6.1';
    this.whatsNewNotificationStorageType = process.env.REACT_APP_NOTIFICATION_STORAGE || 'OfficeUserSettingManager';
    this.whatsNewNotificationUrl = process.env.REACT_APP_NOTIFICATION_URL || 'https://notifications.harmon.ie';
  }
}
export class Configuration extends ConfigurationBase {
  constructor() {
    super();
    this.auth = {
      clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
    };
  }
}

export class AppConfig {
  private static instance: Configuration;

  static getInstance(): Configuration {
    if (!this.instance) {
      this.instance = new Configuration();
    }
    return this.instance;
  }
}

export default AppConfig.getInstance();
