import { IndicatorRequestHandler } from '@storybook';
import { UploadStep } from '~/context/UploadContext';
import { publishItemListChanged } from '~/utilities';
import { ErrorTokens } from '~/utilities/networkAndCache/ErrorTokens';
import { UploadItemProvider } from '~/utilities/uploadItem/UploadItemProvider';

export const useUploadStep = (): UploadStep => {
  const execute = async (
    uploadItemProvider: UploadItemProvider,
    _index: number,
    actionsRef: any,
    _isMultipleUploads: boolean,
    progressHandler?: IndicatorRequestHandler
  ) => {
    try {
      const newItem = await uploadItemProvider.uploadItem(progressHandler);
      newItem && uploadItemProvider.setNewItem(newItem);
      actionsRef.current?.push(uploadItemProvider.newItem?.name);
    } catch (e: any) {
      if (ErrorTokens.itemExist === e.code) {
        uploadItemProvider.isExist = true;
      } else throw e;
    }
  };
  const clean = () => {};

  return { clean: clean, execute: execute };
};

export const publishNewItem = (): UploadStep => {
  const execute = async (uploadItemProvider: UploadItemProvider) => {
    const newItem = uploadItemProvider.newItem;
    publishItemListChanged({
      location: uploadItemProvider.itemContainer,
      added: {
        [newItem!.apiIdKey]: newItem!,
      },
    });
  };

  const clean = () => {};

  return { clean: clean, execute: execute };
};
