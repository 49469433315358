export { default as LottieAnimation } from './LottieAnimation';
export type { LottieAnimationProps } from './LottieAnimation';
export const MiniLoader = './Animations/mini-loader/lottie/Loader_loop.json';
import emptyFavoriteAnimation from './Animations/Favorite/lottie/Star_closed.json';
import filledFavoriteAnimation from './Animations/Favorite/lottie/Star_opened.json';
import dropCloseAnimation from './Animations/Bubble/lottie/Drop_Close.json';
import dropOpenAnimation from './Animations/Bubble/lottie/Drop_Open.json';
import uploadAnimationDark from './Animations/Onboarding/Main_SaveEmails&Files dark.json';
import uploadAnimationLight from './Animations/Onboarding/Main_SaveEmails&Files.json';
import shareAnimationDark from './Animations/Onboarding/Main_ShareFile dark.json';
import shareAnimationLight from './Animations/Onboarding/Main_ShareFile.json';
import searchAnimationDark from './Animations/Onboarding/Main_Search dark.json';
import searchAnimationLight from './Animations/Onboarding/Main_Search.json';
import welcomeAnimationDark from './Animations/Onboarding/Main_Welcome_Dark.json';
import welcomeAnimationLight from './Animations/Onboarding/Main_Welcome_Light.json';
import welcomeAnimationLoopDark from './Animations/Onboarding/Main_Welcom_Dark_Loop.json';
import welcomeAnimationLoopLight from './Animations/Onboarding/Main_Welcom_Light_Loop.json';
import allSetupAnimation from './Animations/Onboarding/All set up.json';
import emaiHeaderMappingSharePointLight from './Animations/EHM/Main_Modal_Sharepoint_Light.json';
import emaiHeaderMappingSharePointDark from './Animations/EHM/Main_Modal_SharePoint_Dark_slower.json';
import emaiHeaderMappingTeamsLight from './Animations/EHM/Main_Modal_Teams_Light.json';
import emaiHeaderMappingTeamsDark from './Animations/EHM/Main_Modal_Teams_Dark.json';

export const emptyFavoriteAnimationData = emptyFavoriteAnimation;
export const filledFavoriteAnimationData = filledFavoriteAnimation;
export const dropOpenAnimationData = dropOpenAnimation;
export const dropCloseAnimationData = dropCloseAnimation;
export const uploadAnimationDataDark = uploadAnimationDark;
export const uploadAnimationDataLight = uploadAnimationLight;
export const shareAnimationDataDark = shareAnimationDark;
export const shareAnimationDataLight = shareAnimationLight;
export const searchAnimationDataDark = searchAnimationDark;
export const searchAnimationDataLight = searchAnimationLight;
export const welcomeAnimationDataDark = welcomeAnimationDark;
export const welcomeAnimationDataLight = welcomeAnimationLight;
export const welcomeAnimationDataLoopDark = welcomeAnimationLoopDark;
export const welcomeAnimationDataLoopLight = welcomeAnimationLoopLight;
export const allSetupAnimationData = allSetupAnimation;
export const emaiHeaderMappingAnimationDataSharePointDark = emaiHeaderMappingSharePointDark;
export const emaiHeaderMappingAnimationDataSharePointLight = emaiHeaderMappingSharePointLight;
export const emaiHeaderMappingAnimationDataTeamsDark = emaiHeaderMappingTeamsDark;
export const emaiHeaderMappingAnimationDataTeamsLight = emaiHeaderMappingTeamsLight;
