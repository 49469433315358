import { Theme, Components } from '@mui/material/styles';
export default (theme: Theme) => {
  const getLoaderStyle = ({ size, color }: { size?: string; color?: string }) => ({
    '.MuiCircularProgress-root': {
      height: `${size} `,
      width: `${size} `,
      color,
    },
  });

  const boxShadow = theme.shadows[1];

  const styleOverrides: Components['MuiButton'] = {
    defaultProps: {
      disableRipple: true,
      size: 'medium',
      variant: 'secondary',
    },
    styleOverrides: {
      root: {
        ...theme.typography.button,
        borderRadius: 6,
        padding: theme.spacing(1, 3),
        transition: 'none',
        textTransform: 'inherit',
        boxShadow: 'none',
        '&:disabled': {
          pointerEvents: 'all',
          cursor: 'not-allowed',
        },
        ':hover:enabled': {
          boxShadow: 'none',
        },
        '&:active:enabled': {
          boxShadow: 'none',
        },
        '&.Mui-disabled': {
          boxShadow: 'none',
          pointerEvents: 'auto',
        },
        'svg:not([data-nofill]),svg:not([data-nofill]) path': {
          fill: 'currentcolor',
        },
      },
      endIcon: {
        marginLeft: theme.spacing(1),
        marginRight: 0,
      },
      startIcon: {
        marginRight: theme.spacing(1),
        marginLeft: 0,
      },
      sizeMedium: {
        height: '32px',
        minWidth: '32px',
      },
      sizeSmall: {
        fontWeight: 400,
        padding: '0 8px',
        minWidth: 0,
      },
    },
    variants: [
      {
        props: { variant: 'threeDotsButtons' },
        style: {
          ...getLoaderStyle({ size: '18px', color: theme.palette.texts.disabled }),
          backgroundColor: theme.palette.backgrounds.secondary,
          color: theme.palette.texts.quaternary,
          height: 22,
          minWidth: 22,
          maxWidth: 22,
          marginLeft: '-5px',
          zIndex: 0,
          '&:hover:enabled': {
            color: theme.palette.texts.primary,
            backgroundColor: 'transparent',
          },
          '&:active:enabled': {
            color: theme.palette.buttons.accentTertiary,
            backgroundColor: 'transparent',
          },
          '&.MuiButton-selected': {
            color: theme.palette.buttons.accentPrimary,
            backgroundColor: 'transparent',
          },
          '&.MuiButton-selected:hover': {
            color: theme.palette.buttons.accentTertiary,
            backgroundColor: theme.palette.buttons.tertiary,
          },
          '&.Mui-disabled, &.Mui-disabled:hover': {
            color: theme.palette.texts.disabled,
          },
        },
      },
      {
        props: { variant: 'threeDotsButtonsOnAccent' },
        style: {
          ...getLoaderStyle({ size: '18px' }),
          backgroundColor: 'transparent',
          color: theme.palette.texts.onAccentPrimary,
          height: 22,
          minWidth: 22,
          maxWidth: 22,
          marginLeft: '-5px',
          zIndex: 0,
          '&:hover:enabled': {
            color: theme.palette.texts.onAccentPrimary,
            backgroundColor: 'transparent',
          },
          '&:active:enabled': {
            color: theme.palette.texts.onAccentSecondary,
          },
          '&.MuiButton-selected': {
            color: theme.palette.buttons.tertiary,
            backgroundColor: 'transparent',
          },
          '&.MuiButton-selected:hover': {
            color: theme.palette.buttons.accentTertiary,
            backgroundColor: 'transparent',
          },
          '&.Mui-disabled, &.Mui-disabled:hover': {
            color: theme.palette.texts.disabled,
          },
        },
      },
      {
        props: { variant: 'favoriteFastAction' },
        style: {
          ...getLoaderStyle({ size: '18px', color: theme.palette.texts.disabled }),
          backgroundColor: 'transparent',
          color: theme.palette.texts.accentPrimary,
          height: 22,
          minWidth: 22,
          maxWidth: 22,
          zIndex: 1,
          '&:hover:enabled': {
            color: theme.palette.texts.accentPrimary,
            backgroundColor: theme.palette.buttons.secondary,
          },
          '&:active:enabled': {
            color: theme.palette.texts.accentPrimary,
            backgroundColor: theme.palette.buttons.tertiary,
          },
          '&.MuiButton-selected': {
            color: theme.palette.buttons.accentPrimary,
            backgroundColor: 'transparent',
          },
          '&.MuiButton-selected:hover': {
            color: theme.palette.buttons.accentTertiary,
            backgroundColor: theme.palette.buttons.tertiary,
          },
          '&.Mui-disabled, &.Mui-disabled:hover': {
            color: theme.palette.texts.disabled,
          },
        },
      },
      {
        props: { variant: 'fastActions' },
        style: {
          ...getLoaderStyle({ size: '18px', color: theme.palette.texts.disabled }),
          backgroundColor: theme.palette.backgrounds.secondary,
          color: theme.palette.texts.quaternary,
          height: 22,
          minWidth: 22,
          maxWidth: 22,
          zIndex: 1,
          '&:hover:enabled': {
            color: theme.palette.texts.accentPrimary,
            backgroundColor: theme.palette.buttons.tertiary,
          },
          '&:active:enabled': {
            color: theme.palette.texts.accentSecondary,
            backgroundColor: theme.palette.buttons.tertiary,
          },
          '&.MuiButton-selected': {
            color: theme.palette.buttons.accentPrimary,
            backgroundColor: 'transparent',
          },
          '&.MuiButton-selected:hover': {
            color: theme.palette.buttons.accentTertiary,
            backgroundColor: theme.palette.buttons.tertiary,
          },
          '&.Mui-disabled, &.Mui-disabled:hover': {
            color: theme.palette.texts.disabled,
          },
        },
      },
      {
        props: { variant: 'fastActionsOnAccent' },
        style: {
          ...getLoaderStyle({ size: '18px' }),
          backgroundColor: 'transparent',
          color: theme.palette.texts.onAccentPrimary,
          height: 22,
          minWidth: 22,
          maxWidth: 22,
          '&:hover:enabled': {
            color: theme.palette.texts.onAccentPrimary,
            backgroundColor: theme.palette.buttons.accentSecondary,
          },
          '&:active:enabled': {
            color: theme.palette.texts.onAccentSecondary,
          },
          '&.MuiButton-selected': {
            color: theme.palette.buttons.tertiary,
            backgroundColor: 'transparent',
          },
          '&.MuiButton-selected:hover': {
            color: theme.palette.buttons.accentTertiary,
            backgroundColor: 'transparent',
          },
          '&.Mui-disabled, &.Mui-disabled:hover': {
            color: theme.palette.texts.disabled,
          },
        },
      },
      {
        props: { variant: 'primary' },
        style: {
          ...getLoaderStyle({ size: '18px', color: theme.palette.texts.onAccentPrimary }),
          color: theme.palette.texts.onAccentPrimary,
          backgroundColor: theme.palette.buttons.accentPrimary,
          boxShadow,
          ':hover:enabled': {
            backgroundColor: theme.palette.buttons.accentSecondary,
            boxShadow,
          },
          '&:active:enabled': {
            backgroundColor: theme.palette.buttons.accentTertiary,
            boxShadow,
          },
          '&.Mui-focusVisible': {
            backgroundColor: theme.palette.buttons.accentTertiary,
            boxShadow: theme.palette.shadow.focus,
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.buttons.disabled,
            color: theme.palette.texts.disabled,
          },
          '& .MuiCircularProgress-root': {
            // Override styles for the loading spinner
            color: theme.palette.texts.onAccentPrimary,
            width: '20px !important', // Adjust as needed
            height: '20px !important', // Adjust as needed
          },
          '&.Mui-disabled .MuiCircularProgress-root': {
            // Override styles for the loading spinner when button is disabled
            color: theme.palette.texts.primary,
          },
        },
      },
      {
        props: { variant: 'secondary' },
        style: {
          ...getLoaderStyle({ size: '18px', color: theme.palette.texts.disabled }),
          color: theme.palette.texts.accentPrimary,
          border: `solid 1px ${theme.palette.texts.accentPrimary}`,
          '&:hover:enabled': {
            backgroundColor: theme.palette.buttons.primary,
            border: `solid 1px ${theme.palette.texts.accentPrimary}`,
          },
          '&:active:enabled': {
            backgroundColor: theme.palette.buttons.secondary,
            border: `solid 1px ${theme.palette.texts.accentPrimary}`,
          },
          '&.Mui-focusVisible': {
            backgroundColor: theme.palette.buttons.primary,
            border: `solid 1px ${theme.palette.texts.accentPrimary}`,
            boxShadow: theme.palette.shadow.focus,
          },
          '&.Mui-disabled, &.Mui-disabled:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.texts.disabled,
            border: `solid 1px ${theme.palette.texts.disabled}`,
          },
        },
      },
      {
        props: { variant: 'EmptyStateAction' },
        style: {
          color: theme.palette.texts.onAccentPrimary,
          width: '100%',
          background: theme.palette.buttons.accentPrimary,
          borderRadius: theme.spacing(1),
          cursor: 'default',
          '&:hover:enabled': {
            color: theme.palette.texts.onAccentPrimary,
            backgroundColor: theme.palette.buttons.accentSecondary,
            'svg path': {
              fill: theme.palette.texts.onAccentPrimary,
            },
          },
          '&:active:enabled': {
            color: theme.palette.texts.onAccentPrimary,
            backgroundColor: theme.palette.buttons.accentTertiary,
            'svg path': {
              fill: theme.palette.texts.onAccentPrimary,
            },
          },
          '&.MuiButton-selected': {
            color: theme.palette.buttons.accentPrimary,
            backgroundColor: 'transparent',
            'svg path': {
              fill: theme.palette.texts.onAccentPrimary,
            },
          },
          '&.MuiButton-selected:hover': {
            color: theme.palette.buttons.accentTertiary,
            backgroundColor: theme.palette.buttons.tertiary,
            'svg path': {
              fill: theme.palette.texts.onAccentPrimary,
            },
          },
        },
      },
      {
        props: { variant: 'MenuActions' },
        style: {
          backgroundColor: 'transparent',
          color: theme.palette.texts.primary,
          padding: theme.spacing(1, 2),
          justifyContent: 'flex-start',
          cursor: 'default',
          'svg:not([data-nofill]),svg:not([data-nofill]) path': {
            fill: theme.palette.texts.primary,
          },
          '&:hover:enabled': {
            color: theme.palette.texts.accentSecondary,
            backgroundColor: theme.palette.buttons.primary,
            'svg:not([data-nofill]),svg:not([data-nofill]) path': {
              fill: theme.palette.texts.accentSecondary,
            },
          },
          '&:active:enabled': {
            backgroundColor: theme.palette.buttons.secondary,
            color: theme.palette.texts.accentSecondary,
            'svg:not([data-nofill]),svg:not([data-nofill]) path': {
              fill: theme.palette.texts.accentSecondary,
            },
          },
          '&.MuiButton-selected': {
            color: theme.palette.buttons.tertiary,
            backgroundColor: 'transparent',
            'svg:not([data-nofill]),svg:not([data-nofill]) path': {
              fill: theme.palette.buttons.tertiary,
            },
          },
          '&.MuiButton-selected:hover': {
            color: theme.palette.buttons.accentTertiary,
            backgroundColor: 'transparent',
          },
          '&.Mui-disabled, &.Mui-disabled:hover': {
            color: theme.palette.texts.disabled,
            'svg,svg path': {
              fill: theme.palette.texts.disabled,
            },
          },
        },
      },
      {
        props: { variant: 'MenuActionsWarningColor' },
        style: {
          backgroundColor: 'transparent',
          cursor: 'default',
          padding: theme.spacing(1, 2),
          color: theme.palette.status.dangerPrimary,
          justifyContent: 'flex-start',
          'svg:not([data-nofill]),svg:not([data-nofill]) path': {
            fill: theme.palette.status.dangerPrimary,
          },
          '&:hover:enabled': {
            color: theme.palette.status.dangerPrimary,
            backgroundColor: theme.palette.buttons.dangerPrimary,
            'svg:not([data-nofill]),svg:not([data-nofill]) path': {
              fill: theme.palette.status.dangerPrimary,
            },
          },
          '&:active:enabled': {
            backgroundColor: theme.palette.buttons.dangerSecondary,
            color: theme.palette.status.dangerPrimary,
            'svg:not([data-nofill]),svg:not([data-nofill]) path': {
              fill: theme.palette.status.dangerPrimary,
            },
          },
        },
      },
      {
        props: { variant: 'text' },
        style: {
          ...getLoaderStyle({ size: '18px', color: theme.palette.texts.quaternary }),
          padding: theme.spacing(1),
          backgroundColor: 'transparent',
          color: theme.palette.texts.quaternary,
          borderRadius: theme.spacing(1),
          '&:hover:enabled': {
            backgroundColor: 'transparent',
            color: theme.palette.texts.accentPrimary,
          },
          '&:active:enabled': {
            backgroundColor: 'transparent',
            color: theme.palette.buttons.accentTertiary,
          },
          '&.Mui-focusVisible': {
            boxShadow: theme.palette.shadow.focus,
          },
          '&.Mui-disabled, &.Mui-disabled:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.texts.disabled,
          },
        },
      },
      {
        props: { variant: 'outlinedWarning' },
        style: {
          ...getLoaderStyle({ size: '18px', color: theme.palette.texts.quaternary }),
          backgroundColor: 'transparent',
          color: theme.palette.status.dangerPrimary,
          border: `solid 1px ${theme.palette.status.dangerPrimary}`,
          borderRadius: theme.spacing(1),
          '&:hover:enabled': {
            backgroundColor: theme.palette.buttons.dangerPrimary,
            color: theme.palette.status.dangerPrimary,
            border: `solid 1px ${theme.palette.status.dangerPrimary}`,
          },
          '&:active:enabled': {
            backgroundColor: theme.palette.buttons.dangerSecondary,
            color: theme.palette.status.dangerPrimary,
            border: `solid 1px ${theme.palette.status.dangerPrimary}`,
          },
          '&.Mui-focusVisible': {
            backgroundColor: theme.palette.buttons.dangerSecondary,
            boxShadow: theme.palette.status.dangerPrimary,
            border: `solid 1px ${theme.palette.status.dangerPrimary}`,
          },
          '&.Mui-disabled, &.Mui-disabled:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.texts.disabled,
          },
        },
      },
      {
        props: { variant: 'IconButtons' },
        style: {
          ...getLoaderStyle({ size: '18px', color: theme.palette.texts.disabled }),
          backgroundColor: 'transparent',
          color: theme.palette.texts.quaternary,
          height: 22,
          minWidth: 22,
          maxWidth: 22,
          padding: 0,
          '&:hover:enabled': {
            backgroundColor: 'transparent',
            color: theme.palette.texts.primary,
          },
          '&:active:enabled': {
            backgroundColor: 'transparent',
            color: theme.palette.buttons.accentTertiary,
          },
          '&.Mui-disabled, &.Mui-disabled:hover': {
            cursor: 'default',
            color: theme.palette.texts.disabled,
          },
        },
      },
      {
        props: { variant: 'IconButtonsSmall' },
        style: {
          ...getLoaderStyle({ size: '18px', color: theme.palette.texts.disabled }),
          'svg:not([data-nofill]),svg:not([data-nofill])': {
            fill: `${theme.palette.texts.onAccentPrimary} !important`,
          },
          height: 16,
          minWidth: 16,
          maxWidth: 16,
          padding: 0,
          '&:hover:enabled': {
            'svg:not([data-nofill]),svg:not([data-nofill])': {
              fill: `${theme.palette.texts.onAccentPrimary} !important`,
            },
            'svg:not([data-nofill]),svg:not([data-nofill]) rect': {
              fill: `${theme.palette.buttons.accentSecondary} !important`,
            },
            'svg[data-nofill] , svg[data-nofill] rect': {
              stroke: `${theme.palette.outlinesAndStrokes.tertiary} !important`,
              strokeOpacity: 1,
              fill: 'red !importnat',
            },
          },
          '&:active:enabled': {
            'svg:not([data-nofill]),svg:not([data-nofill])': {
              fill: `${theme.palette.texts.onAccentPrimary} !important`,
            },
            'svg:not([data-nofill]),svg:not([data-nofill]) rect': {
              fill: `${theme.palette.buttons.accentTertiary} !important`,
            },
            'svg[data-nofill] path, svg[data-nofill] rect': {
              strokeOpacity: 1,
              stroke: `${theme.palette.outlinesAndStrokes.accent} !important`,
            },
          },
          '&.Mui-disabled, &.Mui-disabled:hover': {
            'svg:not([data-nofill]),svg:not([data-nofill])': {
              fill: `${theme.palette.texts.disabled} !important`,
            },
            'svg:[data-nofill],svg:[data-nofill] rect': {
              stroke: `${theme.palette.outlinesAndStrokes.disabled} !important`,
            },
            cursor: 'default',
          },
        },
      },
      {
        props: { variant: 'IconButtonsDialog' },
        style: {
          ...getLoaderStyle({ size: '18px', color: theme.palette.texts.disabled }),
          backgroundColor: 'transparent',
          color: theme.palette.texts.quaternary,
          height: 22,
          padding: 0,
          '&:hover:enabled': {
            backgroundColor: 'transparent',
            color: theme.palette.texts.primary,
          },
          '&:active:enabled': {
            backgroundColor: 'transparent',
            color: theme.palette.buttons.accentTertiary,
          },
          '&.Mui-disabled, &.Mui-disabled:hover': {
            cursor: 'default',
            color: theme.palette.texts.disabled,
          },
        },
      },
      {
        props: { variant: 'DrawerButton' },
        style: {
          ...getLoaderStyle({ size: '18px', color: theme.palette.texts.disabled }),
          backgroundColor: theme.palette.backgrounds.quaternary,
          color: theme.palette.texts.quaternary,
          'svg:not([data-nofill]),svg:not([data-nofill]) path': {
            fill: theme.palette.texts.quaternary,
          },
          height: 22,
          minWidth: 22,
          maxWidth: 22,
          padding: 0,
          '&:hover:enabled': {
            backgroundColor: theme.palette.buttons.primary,
            color: theme.palette.texts.primary,
            'svg:not([data-nofill]),svg:not([data-nofill]) path': {
              fill: theme.palette.texts.accentPrimary,
            },
          },
        },
      },
    ],
  };
  return styleOverrides;
};
