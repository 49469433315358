import { ViewItem } from '@storybook';
import { strings } from '@vendor/languages';
import { ItemData } from '../itemTypes';
import { BaseAction } from './BaseAction';
import { ContextMenuAction } from './ContextMenuAction';
import { DownloadAction } from './DownloadAction';
import { FavoriteAction } from './FavoriteAction';
import { ManageItemCountAction } from './ManageItemCountAction';
import { ManageSitesAction } from './ManageSitesAction';
import { ManageTeamsAction } from './ManageTeamsAction';
import { OpenInOfficeAppAction } from './OpenInAppAction';
import { OpenInBrowserAction, OpenDocumentInBrowserAction } from './OpenInBrowserAction';
import { OpenInTeamsAction } from './OpenInTeamsAction';
import { RefreshAction } from './RefreshAction';
import { RemoveItemAction } from './RemoveItemAction';
import { SaveEmailAction } from './SaveEmailAction';
import { ShareAction } from './ShareAction';
import { UploadAction } from './UploadAction';
import { CopyLink } from './CopyLink';
import { CopyAddressAction } from './CopyAddress';
import { EmailWithLink } from './EmailWithLink';
import { ShareToTeams } from './ShareToTeams';
import { NewFolderAction } from './NewFolderAction';
import { EditOnSharepointAction } from './EditOnSharepointAction';
import { OpenContaingLocationAction } from './OpenContainingLocation';
import { ReOrderAToZAction } from './ReOrderAToZ';
import { EmailWithAttachment } from './EmailWithAttachment';
import { NewLinkDocumentAction } from './NewLinkDocumentAction';
import { NewOfficeDocumentAction } from './NewOfficeDocument';
import { DocumentTemplateType } from './ActionsUtils';
import { RenameItemAction } from './RenameItemAction';
import { GroupAction } from './GroupeAction';
import { NewAction } from './NewAction';
import { BaseMenuActionClass } from './BaseMenuAction';

export const getAllContextActions = (): BaseAction[] => [
  new SaveEmailAction(),
  new ShareAction([new CopyLink(), new EmailWithLink(), new EmailWithAttachment(), new ShareToTeams()]),
  new FavoriteAction(),
  new GroupAction([
    new OpenInBrowserAction(),
    new OpenDocumentInBrowserAction(true),
    new OpenDocumentInBrowserAction(false),
    new OpenInOfficeAppAction(),
    new OpenInTeamsAction(),
    new OpenContaingLocationAction(),
  ]),
  new NewAction([
    new NewFolderAction(),
    new NewOfficeDocumentAction(DocumentTemplateType.Word, strings.lang.actionToolTip.newWordDocument),
    new NewOfficeDocumentAction(DocumentTemplateType.Excel, strings.lang.actionToolTip.newExcelDocument),
    new NewOfficeDocumentAction(DocumentTemplateType.PowerPoint, strings.lang.actionToolTip.newPowerPointDocument),
    new NewLinkDocumentAction(),
  ]),
  new UploadAction(),
  new ManageSitesAction(),
  new ManageTeamsAction(),
  new GroupAction([new ReOrderAToZAction(), new RefreshAction(), new RenameItemAction(), new RemoveItemAction()]),
  new ManageItemCountAction(),
  new CopyAddressAction(),
  new DownloadAction(),
  new EditOnSharepointAction(),
];

export const getQuickActionsList = (
  nodes: Array<ViewItem<ItemData>>,
  allActions: BaseAction[],
  noQuickActions: boolean,
  isTopBarItem: boolean = false
): { quickActions: BaseAction[]; contextAction: BaseAction[]; actionsStructure: BaseAction[] } => {
  const node = nodes[0];

  const quickActions: BaseAction[] = [];

  const filterActions = (actions: BaseAction[]) => {
    return actions.reduce((filteredActions, action) => {
      if (action.isHandled(nodes)) {
        if (action instanceof GroupAction) {
          const filteredSubActions = filterActions(action.actions);
          if (filteredSubActions.length > 0) {
            action.actions = filteredSubActions;
            filteredActions.push(action);
          }
        } else if (action instanceof BaseMenuActionClass && action.actions.length > 0) {
          const filteredSubActions = filterActions(action.actions);
          if (filteredSubActions.length > 0) {
            action.actions = filteredSubActions;
            const quickActionResult = action.isQuickAction?.(node.data, isTopBarItem || nodes.length > 1);
            if (quickActionResult && !noQuickActions) {
              quickActions.push(action);
            }
            if (quickActionResult !== 'only' || noQuickActions) {
              filteredActions.push(action);
            }
          }
        } else {
          if (action.isHandled(nodes)) {
            const quickActionResult = action.isQuickAction?.(node.data, isTopBarItem || nodes.length > 1);
            if (quickActionResult && !noQuickActions) {
              quickActions.push(action);
            }
            if (quickActionResult !== 'only' || noQuickActions) {
              filteredActions.push(action);
            }
          }
        }
      }
      return filteredActions;
    }, [] as BaseAction[]);
  };

  const actionsStructure = filterActions(allActions);
  const contextAction = actionsStructure.length > 0 ? [new ContextMenuAction(actionsStructure)] : [];

  return { quickActions, contextAction, actionsStructure };
};
