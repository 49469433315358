import { toTitleCase } from '@vendor/utils/misc';
import mixpanel from 'mixpanel-browser';
import { ContainerHandlers, ViewItem } from '@storybook';
import _ from 'lodash';
import { ActionItemType, ActionTrigger, BaseAction, TrackedEventName, TrackedEventType } from '../actions';
import { Folder, ItemData } from '../itemTypes';
import { Channel } from '../graphTypes';
import { Search } from '../search';
import { sidebarInit } from './sidebarInit';
import { AppConfig } from '../../../../services/src/config';

export const cloudVersion = AppConfig.getInstance().version;

export const SidebarInitKey = 'SidebarInit';

const getDomain = (email: string) => {
  return (email && email.split('@')[1]) || email;
};

export const initUser = (profile: Office.UserProfile) => {
  if (profile) {
    const identifier = profile.emailAddress;
    mixpanel.init('54960d6214916469afe2f7bc120fb603', { api_host: 'https://analytics.harmon.ie' }); //TODO -> Move to .env!!
    mixpanel.identify(identifier);
    mixpanel.people.set({
      $email: identifier,
      Domain: getDomain(identifier), // Not a built-in proerty & aligns with desktop.
      $cloud_edition: 'Preview',
      $name: profile.displayName,
      $cloud_Version: cloudVersion,
      $office_Language: Office.context.displayLanguage.replace(/-.*/, ''),
    });
    PubSub.subscribeOnce(SidebarInitKey, sidebarInit);
  }
};

const constantData = toTitleCase({
  cloudVersion,
  outlookType: Office.context?.platform,
  officeHost: Office.context?.host,
});

export const trackEvent = (eventName: TrackedEventName, data = {}) => {
  try {
    if (mixpanel && mixpanel?.get_distinct_id()) {
      const titledEventName = _.startCase(eventName);
      data = { ...toTitleCase(data), ...constantData };
      if (globalThis.hrmProvisioning.logMixpanel) console.warn(titledEventName, data);
      mixpanel.track(titledEventName, data);
    }
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.error({ errorMessage: error?.message });
  }
};

interface SimpleGraphError {
  message?: {
    code: string;
    message: {
      value?: string;
      lang?: string;
    };
  };
  name?: string;
}

interface InternalGraphError {
  body?: string;
  errorCode?: string;
  code?: string;
  statusCode?: number;
  errorMessage?: string;
  message?: string;
  name?: string;
}

export const trackErrorEvent = ({ data, eventName }: TrackedEventType, error: Error) => {
  const simpleGraphError = error as any as SimpleGraphError;
  const internalGraphError = error as any as InternalGraphError;
  const errorProps = simpleGraphError?.message?.code
    ? {
        code: simpleGraphError.message.code,
        message: simpleGraphError.message.message?.value,
      }
    : internalGraphError.body
      ? {
          code: internalGraphError.errorCode || internalGraphError.code,
          message: internalGraphError.errorMessage || internalGraphError.message,
          statusCode: internalGraphError.statusCode,
        }
      : {
          name: error.name,
          message: error.message,
        };
  return trackEvent('SidebarError', {
    ...errorProps,
    ...data,
    eventName,
  });
};

const allowedItemTypeToActionItemType = (data: ItemData): ActionItemType => {
  const type = data?.type || 'home';
  switch (type) {
    case 'site':
      return 'Site';
    case 'channel': {
      const chType = (data as Channel).membershipType;
      return chType == 'private' ? 'Private Channel' : chType == 'shared' ? 'Shared Channel' : 'Channel';
    }
    case 'insight':
    case 'document':
      return 'Document';
    case 'folder':
      return (data as Folder).isDocumentSet ? 'Document Set' : 'Folder';
    case 'onedriveroot':
      return 'OneDrive';
    case 'list':
      return 'Library';
    case 'teamschatfiles':
    case 'recentroot':
    case 'sharedwithmeroot':
      return 'View Library';
    case 'sharepointroot':
      return 'Sites List';
    case 'favoritesroot':
      return 'Favorites List';
    case 'teamsroot':
      return 'Teams List';
    case 'team':
      return 'Team';
    case 'home':
      return 'Home Location';
    case 'SearchResults': {
      const searchLocation = (data as Search).location;
      if (searchLocation) return allowedItemTypeToActionItemType(searchLocation);
      return 'Home Location';
    }
  }
  return 'None';
};

export const createActionEvent = (
  action: BaseAction,
  trigger: ActionTrigger,
  nodes: ViewItem<ItemData>[],
  handlers: ContainerHandlers<ItemData>,
  ctx?: any
): TrackedEventType => {
  const data = nodes[0]?.data;
  const targetView = nodes[0]?.['options']
    ? 'All Locations'
    : data?.isAdvancedSearchItem
      ? 'Search Results'
      : data?.isSearchItem
        ? 'Quick Search'
        : 'Sidebar View';
  let rootType = allowedItemTypeToActionItemType(data);
  for (let parent: ViewItem<ItemData> | undefined = nodes[0]; parent; parent = handlers.getParent(parent))
    rootType = allowedItemTypeToActionItemType(parent.data);

  const res: TrackedEventType = {
    eventName: action.trackedName,
    data: {
      actionType: action.type,
      trigger,
      itemsCount: nodes.length || undefined,
      targetApp: nodes[0]?.data?.OfficeAppLocated,
      targetView,
      itemType: data && allowedItemTypeToActionItemType(data),
      rootType,
    },
  };
  return action?.transformEvent?.(res, ctx) || res;
};
