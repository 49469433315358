import { DocLibraryMedium, IconProps } from '@storybook';
import { FolderExpirationWindow, GraphClient, STAMP_NOCACHE } from '@services';
import { logError } from '@vendor/utils/misc';
import { SharePointField } from '~/utilities/metadata/SharePointFieldType';
import { getDocumentOdataIdFromUrl, SharePointClient, SPCBaseItem, SPCFolder } from '../sharePointAPI';
import {
  AccessUrls,
  BaseContainer,
  BaseItemProps,
  FetchChildrenProps,
  ItemData,
  ItemDataWithPaging,
  MicrosoftApps,
} from '../itemTypes';
import { LibraryViewScope, ListView, View } from '../itemTypes/ListView';
import { SPDocument } from './SPDocument';
import { SPFolder } from './SPFolder';
import { SPSite } from './SPSite';
import { ViewCreationParameters } from '../manageProperties/ManageProperties';
import { createView } from '../views/CreateView';
import { RefreshAction } from '../actions';

interface RenderAsListProps {
  Row: SPCBaseItem[];
  NextHref?: string;
}

interface SPListProps extends BaseItemProps {
  site: SPSite;
  serverRelativeUrl: string;
  OfficeAppLocated: MicrosoftApps;
}

export class SPList extends BaseContainer {
  readonly site: SPSite;
  readonly rootFolder: SPFolder;
  readonly OfficeAppLocated: MicrosoftApps;

  constructor(props: SPListProps) {
    super({ ...props, type: 'list' });
    const { site, OfficeAppLocated } = props;
    this.site = site;
    this.OfficeAppLocated = OfficeAppLocated || MicrosoftApps.SharePoint;
    this.rootFolder = new SPFolder({
      metadata: {
        id: `${this.apiId}/rootFolder`,
        FileRef: props.serverRelativeUrl,
        FileLeafRef: 'root folder',
        Modified: new Date(),
        UniqueId: '00000000-0000-0000-0000-000000000000',
      },
      list: this,
      filters: ['Forms', 'Microsoft Teams Chat Files', 'Microsoft Teams Data'],
    });
  }

  protected fillJson(res: SPListProps & { site: string }): void {
    super.fillJson(res);
    res.site = this.site.toJson();
    res.serverRelativeUrl = this.rootFolder.serverRelativeUrl;
    res.OfficeAppLocated = this.OfficeAppLocated;
  }

  get parent() {
    return this.site;
  }

  private schema?: SharePointField[];

  async getSchema(spc: SharePointClient, refetch?: boolean): Promise<SharePointField[]> {
    // refetch: undefined - local cahe, 'false' - DB cache & true - network.
    if (!this.schema || refetch !== undefined) {
      const refreshStamp = await RefreshAction.markRefreshStamp(this, refetch ? Date.now() : undefined);
      const res = await spc.api(`${this.apiId}/fields`).cache(FolderExpirationWindow, refreshStamp).get();
      this.schema = res.value;
    }
    return this.schema!;
  }

  get siteUrl() {
    return this.site.site;
  }
  get serverRelativeUrl(): string {
    return this.rootFolder.serverRelativeUrl;
  }
  get pathOrDescription(): string {
    return `${this.rootFolder.pathOrDescription}`;
  }
  get secondLineContent(): string | null {
    const appName = this.OfficeAppLocated === 'SP' ? 'SharePoint' : this.OfficeAppLocated;
    return `${appName} • ${this.parent.name}`;
  }

  async getViews(spc: SharePointClient, refreshStamp?: number): Promise<View[]> {
    try {
      await this.getSchema(spc, false);
      const res = await spc.api(`${this.apiId}/views`).cache(FolderExpirationWindow, refreshStamp).get();
      if (res?.value) {
        const views: View[] = res.value;
        return views;
      }
    } catch (e) {
      logError(e);
    }
    return [];
  }

  override async getAccessUrls(): Promise<AccessUrls | undefined> {
    return await this.rootFolder.getAccessUrls();
  }

  override async getContentTypes(gcl: GraphClient): Promise<any> {
    const spc = new SharePointClient(gcl, this.rootSite);
    const res = await spc.api(`${this.apiId}/contenttypes`).get();
    return res;
  }

  async createEmailView(spc: SharePointClient) {
    const emailViewParams: ViewCreationParameters = {
      title: 'Emails',
      rowLimit: 30,
      viewFields: ['DocIcon', 'hrmV2HasAttachments', 'Name', 'hrmV2From', 'hrmV2To', 'hrmV2Recieved'],
      query: `<Where><Or><Eq><FieldRef Name=\"ContentType\" /><Value Type=\"Text\">Email</Value></Eq><Eq><FieldRef Name=\"ContentType\" /><Value Type=\"Text\">Folder</Value></Eq></Or></Where>`,
      viewType: 'HTML',
    };

    try {
      await createView(spc, this.apiId, emailViewParams);
    } catch (error: any) {
      logError(`Error creating email view: ${error}`);
    }
  }

  async addEmailContentTypeAndView(gcl: GraphClient, contentTypeId: string) {
    const spc = new SharePointClient(gcl, this.rootSite);
    const list_url = `${this.apiId}/contenttypes/addAvailableContentType`;
    try {
      const emailContentType = await spc.api(list_url).post({
        contentTypeId: `${contentTypeId}`,
      });
      await this.createEmailView(spc);
      await this.getSchema(spc, true);
      PubSub.publish('refreshViews');
      return emailContentType;
    } catch (error) {
      logError(`Error adding content type ${contentTypeId} to list: ${error}`);
      throw error;
    }
  }

  override async addOrCreateEmailContentType(gcl: GraphClient): Promise<any> {
    try {
      const spc = new SharePointClient(gcl, this.rootSite);
      let contentTypes = await this.getSiteContentTypes(gcl);
      const emailContentType = contentTypes.value.find(
        (contentType: any) => contentType.Name === 'Email' || contentType.Name === 'E-mail'
      );
      if (!emailContentType) {
        await this.createEmailContentType(spc, gcl);
        contentTypes = await this.site.getContentTypes(gcl);
        const emailContentType = contentTypes.value.find(
          (contentType: any) => contentType.Name === 'Email' || contentType.Name === 'E-mail'
        );
        contentTypes = await this.addEmailContentTypeAndView(gcl, emailContentType.Id.StringValue);
      } else {
        contentTypes = await this.addEmailContentTypeAndView(gcl, emailContentType.Id.StringValue);
      }

      return contentTypes;
    } catch (e) {
      throw e;
    }
  }

  async getSiteContentTypes(gcl: GraphClient): Promise<any> {
    return await this.site.getContentTypes(gcl);
  }
  override async createEmailContentType(spc: SharePointClient, gcl: GraphClient): Promise<void> {
    try {
      return await this.site.createEmailContentType(spc, gcl);
    } catch (e) {
      throw e;
    }
  }

  override async getFolder(): Promise<SPFolder> {
    return this.rootFolder;
  }

  override get hasFolder(): boolean {
    return true;
  }

  override get rootSite(): string {
    return this.site.rootSite;
  }

  async resolveByID(gcl: GraphClient, view: ListView, ID: number): Promise<ItemData> {
    view = view
      .combineWithQuery(`<Eq><FieldRef Name=\"ID\"/><Value Type=\"INTEGER\">${ID}</Value></Eq>`)
      .combineWithScope(LibraryViewScope.RecursiveAll);
    const data = await this.rootFolder.getItemsFromView(gcl, view!, 1, STAMP_NOCACHE);
    return data.items[0];
  }

  private async renderAsStreamStep(
    spc: SharePointClient,
    folder: SPFolder,
    view: ListView,
    count: number,
    next?: string,
    refreshStamp?: number,
    valueToCache?: RenderAsListProps
  ): Promise<RenderAsListProps> {
    const enableCache = next === undefined;
    next = next || `?RootFolder=${encodeURIComponent(folder.serverRelativeUrl)}`;
    return await spc
      .api(`${this.apiId}/RenderListDataAsStream${next}`)
      .cache(FolderExpirationWindow, refreshStamp)
      .configureCache(enableCache, valueToCache)
      .post({
        parameters: {
          DatesInUtc: true,
          AddRequiredFields: false,
          RenderOptions: 0x4002,
          ViewXml: view.exportXml(count),
        },
      });
  }

  async renderAsStream(
    spc: SharePointClient,
    folder: SPFolder,
    view: ListView,
    origCount: number,
    refreshStamp?: number,
    next?: string
  ): Promise<ItemDataWithPaging> {
    const rows: SPCBaseItem[] = [];
    let count = origCount;
    const isFirstRequest = next === undefined;
    let requestCount = 0;
    let singleRes;
    do {
      singleRes = await this.renderAsStreamStep(spc, folder, view, count, next, refreshStamp);
      rows.push(...singleRes.Row);
      next = singleRes.NextHref;
      count -= singleRes.Row.length;
      requestCount++;
    } while (next && count > 0);

    if (isFirstRequest && requestCount > 1) {
      // Cache value in DB if we had multiple requests
      await this.renderAsStreamStep(spc, folder, view, origCount, undefined, undefined, {
        ...singleRes,
        Row: rows,
      });
    }
    const schema = await this.getSchema(spc);
    return {
      items: rows.map(v =>
        v.ContentTypeId.startsWith('0x0101')
          ? new SPDocument(folder, {
              ...v,
              id: getDocumentOdataIdFromUrl(this.siteUrl, v.FileRef),
              File_x0020_Size: Number(v.File_x0020_Size),
            })
          : new SPFolder({
              metadata: {
                ...v,
                ServerRelativeUrl: v.ServerRelativeUrl,
                FileLeafRef: v.FileLeafRef,
                Modified: v.Modified,
                UniqueId: v.UniqueId,
                ContentTypeId: v.ContentTypeId,
                File_x0020_Size: Number(v.File_x0020_Size),
                progId: (v as SPCFolder).ProgId,
              },
              list: this,
            })
      ),
      view,
      schema,
      pageToken: next,
    };
  }

  override fetchChildren(props: FetchChildrenProps): Promise<ItemDataWithPaging> {
    return this.rootFolder.fetchChildren(props, this);
  }
  override getIcon(_expanded: boolean): IconProps {
    return { icon: DocLibraryMedium, isColorable: true };
  }
}
