import React, { ReactElement } from 'react';
import { Tooltip, Button, Typography, ViewItem, Stack, BAVariant, SVGComponent } from '@harmon.ie-ce/storybook';
import { styled } from '@mui/system';

export const ActionStack = styled(Stack)`
  width: 100%;
`;

const RightIcon = styled('div')(() => ({
  position: 'fixed',
  top: '50%',
  transform: 'translateY(-50%)', // Centers the icon vertically
  right: 0,
  marginRight: 5,
}));
interface BaseActionUIProps {
  tooltipTitle: string | ReactElement;
  title: string | ReactElement;
  BaseActionVariant: BAVariant;
  Icon?: SVGComponent | ReactElement;
  node?: ViewItem<any>;
  disabled?: boolean;
  hideAction?: boolean;
  opened?: boolean;
  tooltipClassName?: string;
  EndIcon?: ReactElement;
}
export const BaseActionUI = ({
  Icon,
  tooltipTitle,
  title,
  disabled,
  opened,
  tooltipClassName,
  BaseActionVariant,
  EndIcon,
}: BaseActionUIProps) => {
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);
  const renderIconElement = typeof Icon === 'function' ? <Icon /> : Icon;
  return (
    <Tooltip
      className={tooltipClassName}
      title={tooltipTitle}
      open={(isTooltipOpen && !BaseActionVariant.withText) || (isTooltipOpen && React.isValidElement(tooltipTitle))}
      onClick={() => setIsTooltipOpen(false)}
      onMouseEnter={() => setIsTooltipOpen(true)}
      onMouseLeave={() => setIsTooltipOpen(false)}
    >
      {BaseActionVariant.withText ? (
        <Button
          size="medium"
          variant={BaseActionVariant.variant}
          onDoubleClick={e => e.stopPropagation()}
          opened={opened}
          disabled={disabled}
        >
          {renderIconElement}

          <Typography
            variant="H2Regular"
            sx={{
              cursor: 'default',
              ml: 2,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              flexShrink: 1, // Prevent text from growing and wrapping
              maxWidth: EndIcon !== undefined ? 'calc(100% - 30px)' : '100%', // Adjust max width to make space for the icon
            }}
          >
            {title}
          </Typography>
          <RightIcon>{EndIcon}</RightIcon>
        </Button>
      ) : (
        <Button
          size="medium"
          variant={BaseActionVariant.variant}
          onDoubleClick={e => e.stopPropagation()}
          opened={opened}
          disabled={disabled}
        >
          {renderIconElement}
        </Button>
      )}
    </Tooltip>
  );
};
