import React from 'react';
import ItemNodeContentText from '../../../NavigationItem/components/ItemNodeContentText';

interface NumberCellProps {
  value: number;
}

export const NumberCell: React.FC<NumberCellProps> = ({ value }) => {
  return <ItemNodeContentText highLightSecondLine={false}>{value.toFixed(2)}</ItemNodeContentText>; // Example: rendering number with 2 decimal places
};
