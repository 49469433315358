import { Theme, Components } from '@mui/material/styles';

export default (theme: Theme) => {
  const appBarStyleOverrides: Components['MuiSnackbar'] = {
    styleOverrides: {
      root: {
        '&.indicator-still-uploading': {
          marginBottom: theme.spacing(27),
        },
        '&.indicator': {
          width: '100%', // Set width to 100%
          maxWidth: '100%', // Set maxWidth to 100%
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
        marginBottom: theme.spacing(3),
        padding: theme.spacing(0, 0),
        maxWidth: theme.spacing(56),
        width: 'max-content',
        boxShadow: theme.palette.shadow.primary,
        borderRadius: theme.spacing(1),
        zIndex: 1300,
        '& .MuiSnackbarContent-root': {
          paddingTop: '3px',
          paddingBottom: '3px',
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
          '& .MuiSnackbarContent-message': {},
          backgroundColor: theme.palette.tooltips.primary,
          backgroundImage: 'none',
          color: theme.palette.texts.onAccentPrimary,
          bottom: theme.spacing(0),
          minWidth: theme.spacing(0),
          '& .MuiTypography-root': {
            whiteSpace: 'nowrap',
          },
        },
      },
      anchorOriginBottomCenter: {
        [theme.breakpoints.down('sm')]: {
          left: '50%',
          right: 'auto',
          transform: 'translateX(-50%)',
        },
      },
    },
  };
  return appBarStyleOverrides;
};
