import {
  EllipseSelected,
  Menu,
  MenuItem,
  Typography,
  Divider,
  OrderByInfo,
  SortAction,
  SortCheckbox,
  Ellipse,
} from '@storybook';
import { FormControlLabel, styled } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { strings } from '@vendor/languages';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGraphClient } from '@services';
import { DEFAULT_VIEW } from '~/utilities';
import { ListView } from '~/utilities/itemTypes/ListView';
import { DrilldownState, navigateDrilldown } from '../Tree/Renderers/DrilldownHandler';

// Map of supported fields for sorting with their display labels
const OrderBySupportedFields = new Map<string, string>(
  Object.entries({
    FileLeafRef: strings.lang.sortMemuActions.name, // ASC
    Modified: strings.lang.sortMemuActions.dateModified, // DES
    Created: strings.lang.sortMemuActions.dateCreated, // DES
  })
);

export const SortMenu = styled(Menu)`
  margin-top: ${({ theme }) => theme.spacing(2)};
  display: flex;
  justify-content: flex-end;
  .MuiPaper-root {
    margin: ${({ theme }) => theme.spacing(0)};
    display: flex;
    justify-content: flex-end;
    left: unset !important;
    margin-right: ${({ theme }) => theme.spacing(4)};
    width: ${({ theme }) => theme.spacing(37)};
    .MuiList-root {
      width: 100%;
    }
  }
`;

export interface SortDialogProps {
  anchorEl?: HTMLElement | null;
  setAnchorEl?: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  headless?: boolean;
}

const SortMenuItem = ({
  label,
  checked,
  onClick,
  value,
}: {
  label: string;
  checked: boolean;
  onClick: () => void;
  value: string;
}) => (
  <MenuItem sx={{ width: '100%' }} key={value}>
    <FormControlLabel
      sx={{ width: '100%' }}
      label={
        <Typography variant="H2Regular" noWrap>
          {label}
        </Typography>
      }
      control={
        <SortCheckbox
          disableRipple
          checked={checked}
          onClick={onClick}
          value={value}
          icon={<Ellipse />}
          checkedIcon={<EllipseSelected />}
        />
      }
    />
  </MenuItem>
);

export const SortMenuAction = ({ anchorEl, setAnchorEl, headless }: SortDialogProps) => {
  const { state: drilldownState } = useLocation() as DrilldownState;
  const { location, refreshStamp, newItems } = drilldownState;
  const [customSortAvailable, setCustomSortAvailable] = React.useState(false);

  const [view, setView] = React.useState<ListView | undefined>(drilldownState.view);
  const [orderBy, setOrderBy] = React.useState<OrderByInfo>(
    drilldownState.orderBy ?? { field: 'FileLeafRef', dir: true } // Default sort by "name" in ascending order
  );

  const gcl = useGraphClient();
  const navigate = useNavigate();

  useEffect(() => {
    const initDefaultView = async () => {
      if (!view) {
        const folder = await location.getFolder(gcl);
        const defaultView = (await folder.getViews(gcl, false, refreshStamp)).find(v => v.isDefault) ?? DEFAULT_VIEW;
        setView(defaultView);
        setOrderBy(defaultView.orderBy?.[0] ?? { field: 'FileLeafRef', dir: true });
      } else {
        setView(view);
        setOrderBy(view.orderBy?.[0] ?? { field: 'FileLeafRef', dir: true });
      }

      // Ensure "Current view order" is always available if the field is not supported
      if (orderBy && !OrderBySupportedFields.has(orderBy.field)) {
        setCustomSortAvailable(true);
      } else {
        setCustomSortAvailable(false);
      }
    };

    initDefaultView();
  }, [gcl, location, refreshStamp, view, orderBy]);

  const onSelect = useCallback(
    (value: OrderByInfo) => {
      setAnchorEl?.(null);
      if (value.field === orderBy.field && value.dir === orderBy.dir) return;
      const isNameField = value.field === 'FileLeafRef'; // Name field
      const isDateField = value.field === 'Modified' || value.field === 'Created'; // Date fields
      // Toggle behavior: if it's the same field, reverse the direction.
      const updatedValue = {
        ...value,
        dir: value.field === orderBy.field ? !orderBy.dir : isNameField ? true : isDateField ? false : value.dir,
      };

      if (view) {
        const newView = view.combineWithOrderBy([updatedValue]);
        setView(newView);
        setOrderBy(updatedValue);
        navigateDrilldown(navigate, location, true, refreshStamp, newItems, newView, updatedValue);
      }
    },
    [navigate, location, setAnchorEl, refreshStamp, newItems, view, orderBy]
  );

  const handleSortChange = useCallback(
    (dir: boolean) => {
      if (orderBy) {
        onSelect({ field: orderBy.field || '', dir });
      }
    },
    [onSelect, orderBy]
  );

  const renderSortItems = useCallback(() => {
    const items = Array.from(OrderBySupportedFields).map(([key, value]) => (
      <MenuItem key={key} sx={{ width: '100%' }}>
        <SortAction
          actionName={value}
          checked={orderBy?.field === key}
          onSelect={onSelect}
          itemValue={{ field: key, dir: orderBy?.dir }}
        />
      </MenuItem>
    ));

    if (customSortAvailable) {
      items.push(
        <MenuItem key="customSort" sx={{ width: '100%' }}>
          <SortAction
            actionName={`Current view sort`}
            checked={orderBy.field === view?.orderBy?.[0]?.field}
            onSelect={() => onSelect(orderBy)}
            itemValue={orderBy}
          />
        </MenuItem>
      );
    }

    return items;
  }, [onSelect, orderBy, view?.orderBy, customSortAvailable]);

  const sortItems = useMemo(
    () => (
      <>
        <Divider sx={{ width: '100%' }} />
        <SortMenuItem
          label={strings.lang.sortMemuActions.ascending}
          checked={orderBy?.dir ?? true}
          onClick={() => !orderBy?.dir && handleSortChange(true)}
          value={strings.lang.sortMemuActions.ascending}
        />
        <SortMenuItem
          label={strings.lang.sortMemuActions.descending}
          checked={!orderBy?.dir}
          onClick={() => orderBy?.dir && handleSortChange(false)}
          value={strings.lang.sortMemuActions.descending}
        />
      </>
    ),
    [handleSortChange, orderBy]
  );

  return headless ? (
    <>
      {renderSortItems()}
      {sortItems}
    </>
  ) : (
    <SortMenu sx={{ width: '100%' }} open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl?.(null)}>
      {renderSortItems()}
      {sortItems}
    </SortMenu>
  );
};
