import { Theme, Components } from '@mui/material/styles';

export default (theme: Theme) => {
  const styleOverrides: Components['MuiCheckbox'] = {
    styleOverrides: {
      root: {
        padding: theme.spacing(1),
        margin: theme.spacing(0, 1),
        '&.default-checkbox': {
          '& svg': {
            width: '17px',
            height: '17px',
            borderWidth: '1px',
            borderRadius: theme.spacing(1),
            borderStyle: 'solid',
            borderColor: theme.palette.outlinesAndStrokes.secondary,
            fill: 'rgba(0,0,0,0)',
          },
          '&.Mui-checked svg': {
            borderColor: theme.palette.buttons.accentPrimary,
            background: theme.palette.texts.accentPrimary,
          },

          '&:hover': {
            backgroundColor: 'transparent',
            '& svg': {
              borderColor: theme.palette.outlinesAndStrokes.tertiary,
              // Adjust hover fill color for svg here if necessary
            },
            '&.Mui-checked svg, svg rect': {
              fill: theme.palette.buttons.accentSecondary,
              borderColor: theme.palette.buttons.accentSecondary,
            },
          },
          '&:active': {
            // Add styles for pressed state here
            '& svg': {
              borderColor: theme.palette.outlinesAndStrokes.accent,
              // Adjust pressed fill color for svg here if necessary
            },
            '&.Mui-checked svg, svg rect': {
              fill: theme.palette.buttons.accentTertiary,
              borderColor: theme.palette.buttons.accentTertiary,
            },
          },
          '&.Mui-disabled': {
            // Add styles for disabled state here
            cursor: 'default',
            color: theme.palette.texts.disabled,
            '& svg': {
              borderColor: theme.palette.outlinesAndStrokes.disabled,
              fill: theme.palette.buttons.disabled,
            },
          },
          '&.left-aligned-checkbox': {
            paddingLeft: 0,
            marginLeft: 0,
          },
        },
        '&.share-checkbox': {
          '&+.MuiTypography-root': {
            fontSize: '12px',
            lineHeight: '16px',
          },
          '&.MuiButtonBase-root': {
            margin: 0,
          },
          '&.Mui-checked': {
            ['svg']: {
              borderColor: theme.palette.texts.accentPrimary,
              background: theme.palette.texts.accentPrimary,
              paddingTop: '3px',
              paddingLeft: '2.5px',
            },
          },
          ['svg']: {
            fill: 'rgba(0,0,0,0)',
            width: '16px',
            height: '16px',
            borderWidth: '1px',
            borderRadius: theme.spacing(1),
            borderStyle: 'solid',
            borderColor: theme.palette.outlinesAndStrokes.tertiary,
          },
        },
      },
    },
  };
  return styleOverrides;
};
