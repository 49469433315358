import { isArray } from 'lodash';
import { SharePointFieldType } from '~/utilities/metadata/SharePointFieldType';

export const cellIsEmpty = (type: SharePointFieldType, headerName: string, value: any): boolean => {
  if (
    (value === '' || (isArray(value) && value.length === 0)) &&
    type !== SharePointFieldType.Computed &&
    headerName !== 'DocIcon' &&
    type !== SharePointFieldType.AverageRating &&
    type !== SharePointFieldType.Boolean
  ) {
    return true;
  }
  if (
    (type == SharePointFieldType.User && value?.[0]?.value === '') ||
    (type == SharePointFieldType.Lookup && value?.[0]?.lookupValue === '')
  ) {
    return true;
  } else return false;
};

export const calculateQuickActionIndex = (schemaFieldsMap, possibleFieldMappings) => {
  let quickActionIndex = 0;
  if (schemaFieldsMap?.[0] && schemaFieldsMap?.[0].TypeAsString === SharePointFieldType.Text) {
    return quickActionIndex;
  }
  if (
    schemaFieldsMap?.[1] &&
    schemaFieldsMap?.[1].TypeAsString === SharePointFieldType.Text &&
    !possibleFieldMappings.Attachments.includes(schemaFieldsMap?.[1].StaticName)
  ) {
    return 1;
  }
  for (let i = 0; i < schemaFieldsMap?.length; i++) {
    const schemaField = schemaFieldsMap[i];
    if (!schemaField) continue;

    const isAttachmentsType = schemaField && possibleFieldMappings.Attachments.includes(schemaField.StaticName);
    const isTypeColumn =
      schemaField.TypeAsString === SharePointFieldType.Computed && schemaField.InternalName === 'DocIcon';

    quickActionIndex += (isAttachmentsType ? 1 : 0) + (isTypeColumn ? 1 : 0);

    // Break if it's neither an attachment type nor an icon type
    if (!isAttachmentsType && !isTypeColumn) {
      break;
    }
  }

  return quickActionIndex;
};

export const calculateColumnWidths = (isIconColumn: boolean) => {
  const width = isIconColumn ? 35 : 150;
  return width;
};
