import React, { useMemo } from 'react';
import { Stack } from '@mui/material';
import ItemNodeContentText from '../../../NavigationItem/components/ItemNodeContentText';
import { HiddenCountChip } from './HiddenCountChip';

interface TextCellProps {
  value: string[] | string; // The array of choice values or a single string
  isArray: boolean; // This flag indicates if value is an array
  columnWidth: number; // The width of the column (sent from the parent component)
}

// Utility function to estimate the width of text based on character count
const estimateTextWidth = (text: string, isLastItem: boolean = false) => {
  const baseWidth = 10; // Approximate width per character in pixels
  const padding = isLastItem ? 0 : 20; // Remove padding for the last visible item
  return text.length * baseWidth + padding;
};

// Function to trim text to fit into a given width while ensuring a minimum number of characters
const trimTextToFit = (text: string, availableWidth: number, minVisibleChars = 6) => {
  const baseWidth = 10; // Width of each character
  const maxVisibleChars = Math.max(Math.floor(availableWidth / baseWidth), minVisibleChars); // Show at least minVisibleChars
  return text.slice(0, maxVisibleChars) + (text.length > maxVisibleChars ? '...' : '');
};

export const TextCell: React.FC<TextCellProps> = ({ value, isArray, columnWidth }) => {
  const textMargin = 8; // Margin between text components
  const chipWidth = 30; // Width of the "+X" hidden count chip

  // Ensure value is always treated as an array
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const valueArray = isArray ? (value as string[]) : [value as string];

  // Calculate which texts can be shown based on available space and text width conditions
  const visibleTextCount = useMemo(() => {
    if (valueArray.length === 1) {
      return 1; // Show the only value directly if array length is one
    }

    let availableWidth = columnWidth; // Start with the full column width
    let count = 0;

    for (const text of valueArray) {
      const isLastItem = count === valueArray.length - 1;
      const textWidth = estimateTextWidth(text, isLastItem) + textMargin;

      // If the text fits or there's space for part of it, count it as visible
      if (availableWidth >= textWidth) {
        availableWidth -= textWidth;
        count++;
      } else {
        // Reserve space for partial text and the chip if applicable
        if (availableWidth > chipWidth) {
          count++; // Include the partially visible text in the count
        }
        availableWidth -= chipWidth; // Reserve space for the hidden count chip
        break;
      }
    }

    return count;
  }, [columnWidth, valueArray, textMargin, chipWidth]);

  // Render text components and the hidden count chip when needed
  const renderTextComponents = (values: string[]) => {
    if (values.length === 0) return null;

    const visibleValues = values.slice(0, visibleTextCount);
    const hiddenCount = values.length - visibleTextCount;
    const availableWidth = columnWidth - (hiddenCount > 0 ? chipWidth : 0); // Adjust width for chip

    return (
      <>
        {visibleValues.map((val, index) => {
          const isLastItem = index === visibleValues.length - 1;
          return (
            <ItemNodeContentText highLightSecondLine={false} key={index}>
              {/* If it's the last visible item and there's still space, trim it to fit */}
              {isLastItem && hiddenCount > 0 ? trimTextToFit(val, availableWidth) : val}
            </ItemNodeContentText>
          );
        })}

        {hiddenCount > 0 && <HiddenCountChip hiddenCount={hiddenCount} />}
      </>
    );
  };

  return valueArray.length ? (
    <Stack
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row', // Arrange items in a row
        whiteSpace: 'nowrap', // Prevent wrapping in Stack
        overflow: 'hidden', // Ensure the overflow doesn't break layout
        height: '100%',
        width: '100%', // Set the container width to fit multiple text items
        padding: 0, // Ensure no extra padding adds space issues
        margin: 0, // Avoid extra margin adding extra space
      }}
      direction={'row'}
    >
      {renderTextComponents(valueArray)}
    </Stack>
  ) : null;
};
