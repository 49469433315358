import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Snackbar, Slide, LinearProgress, Paper, useTheme } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { IndicatorTexts } from '@vendor/languages';
import paperFold from '../Lottie/Animations/Success/Main_PaperFold.json';
import { LottieAnimation } from '../Lottie';
import { IndicatorHeader } from './IndicatorHeader';
import { IndicatorBody } from './IndicatorBody';
import { IndicatorFooter } from './IndicatorFooter';
import { Skeleton } from '../Skeleton';

export type IndicatorStatus = 'error' | 'progress' | 'success' | 'canceled';

export interface IndicatorRequestHandler {
  updateProgress: (index: number, progress: number) => void;
  needToCancel: () => boolean;
  inProgress: () => boolean;
  getCurrentProgressIndex: () => React.MutableRefObject<number>;
}

interface IndicatorProps {
  dateKey?: string;
  status: IndicatorStatus;
  indicatorStrings: IndicatorTexts;
  calculateProgress(fileProgress: boolean[], currentProgress: number): number;
  fileProgress: boolean[];
  currentProgress: number;
  currentProgressIndex: number;
  activeFileIndex?: number;
  messageBody?: string[];
  onComplete?: () => void;
  onCancel?: () => void;
  onClose: () => void;
  onExited?: () => void;
  open: boolean;
  isCanceling?: boolean;
  errorMessage?: string;
}

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const Indicator: React.FC<IndicatorProps> = ({
  currentProgress = 0,
  fileProgress = [],
  activeFileIndex,
  calculateProgress,
  indicatorStrings,
  onCancel,
  onComplete,
  open,
  status,
  onClose,
  isCanceling,
  onExited,
  messageBody,
  dateKey,
  currentProgressIndex,
  errorMessage,
}) => {
  const { cancel, canceled, complete, oneItemProgress, failed, success, progressDone } = indicatorStrings;
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isHover, setIsHover] = useState(false);
  const animationComplete = useRef(false);

  const theme = useTheme();
  const isProgressShow = useMemo(() => status === 'success' || status === 'progress', [status]);
  const displayProgress = useMemo(
    () => calculateProgress(fileProgress, currentProgress),
    [calculateProgress, currentProgress, fileProgress]
  );

  const readyToFinish = useMemo(() => {
    if (
      fileProgress.every(item => item) ||
      (fileProgress.slice(0, -1).every(item => item) && currentProgress === 100)
    ) {
      return true;
    } else {
      return false;
    }
  }, [fileProgress, currentProgress]);

  const description = useMemo(() => {
    if (status === 'canceled') {
      return success({ count: currentProgressIndex });
    }
    if (status === 'error' && errorMessage) {
      return errorMessage;
    }
    return messageBody?.[activeFileIndex!] || ''; // Use the non-null assertion operator since activeFileIndex might be undefined
  }, [status, errorMessage, messageBody, activeFileIndex, success, currentProgressIndex]);

  const title = useMemo(() => {
    const totalItems = fileProgress.length;
    const uploadedItems = fileProgress.filter(Boolean).length;
    if (status === 'error') {
      return failed({ count: totalItems });
    }
    if (status === 'canceled') {
      return canceled;
    }
    if (fileProgress.length == 1) {
      if (status === 'success') {
        return complete;
      }
      return oneItemProgress;
    } else {
      if (uploadedItems === totalItems) {
        return success({ count: totalItems });
      }
      return progressDone({ count: uploadedItems + 1, totals: totalItems });
    }
  }, [fileProgress, status, failed, canceled, oneItemProgress, complete, progressDone, success]);

  const progressColor = useMemo(() => (status === 'progress' || status === 'canceled' ? 'primary' : status), [status]);

  const onTransitionEnd = useCallback(() => {
    if (readyToFinish && onComplete) onComplete();
  }, [readyToFinish, onComplete]);

  const hasCancel = useCallback(() => {
    if (typeof activeFileIndex === 'number' && messageBody) {
      return messageBody.length > 1 && activeFileIndex !== messageBody.length - 1;
    }
    return false;
  }, [activeFileIndex, messageBody]);

  useEffect(() => {
    animationComplete.current = false;
    if (status !== 'progress' && !isHover) {
      timeoutRef.current = setTimeout(onClose, status === 'success' ? 4000 : 8000);
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isHover, onClose, status, fileProgress]);

  return (
    <Snackbar
      TransitionProps={{
        onExited: onExited,
      }}
      open={open}
      TransitionComponent={SlideTransition}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className="indicator"
    >
      <Box sx={{ width: '100%' }}>
        {status === 'success' && (
          <LottieAnimation
            options={{ loop: false, autoplay: true, animationData: paperFold }}
            width={animationComplete.current ? 0 : 290}
            height={animationComplete.current ? 0 : 128}
            handleAnimationComplete={() => (animationComplete.current = true)}
          />
        )}
        <Paper
          sx={{
            position: 'relative',
            zIndex: 10000,
            width: '100%',
            borderRadius: 2,
            overflow: 'hidden',
            marginTop: theme.spacing(-6),
            background: theme.palette.backgrounds.primary,
          }}
        >
          {isProgressShow &&
            (displayProgress === 0 ? (
              <Skeleton
                variant="rectangular"
                width="100%"
                height={3}
                sx={{ backgroundColor: theme.palette.status.defaultPrimary }}
              />
            ) : (
              <LinearProgress
                key={dateKey}
                variant="determinate"
                sx={{ height: 3 }}
                color={progressColor}
                value={displayProgress}
                onTransitionEnd={onTransitionEnd}
              />
            ))}
          <Stack spacing={1} p={3}>
            <IndicatorHeader title={title} status={status} onClose={onClose} />
            <IndicatorBody status={status} description={description} messageBody={messageBody} />
            {status === 'progress' && (
              <IndicatorFooter cancel={cancel} isCanceling={isCanceling} onCancel={onCancel} hasCancel={hasCancel()} />
            )}
          </Stack>
        </Paper>
      </Box>
    </Snackbar>
  );
};

export default Indicator;
