import { ThemeType } from '@vendor/types/ui';
import React, { createContext, FC, PropsWithChildren, useCallback, useContext, useState } from 'react';
import { onBoardingState } from '~/modules/Onboarding/OnboardingFlow';
import {
  EmailHeaderMappingChoices,
  FavoritesRoot,
  OfficeUserSettingManager,
  OpenAction,
  SharePointSitesRoot,
  TeamsRoot,
} from '../../../utilities';

export interface SettingsContextInterface {
  theme?: ThemeType;
  changeTheme?: (theme?: ThemeType) => void;
  changeGeneralNumber?: (number: number) => void;
  changeOpenSettings?: (action: OpenAction) => void;
  generaFilesCount?: number;
  openFilesSettings?: OpenAction;
  showTreeHistory?: boolean;
  addCategory?: boolean;
  changeAddCategory?: (addCategory: boolean) => void;
  emailNameSettings?: boolean;
  changeEmailNameSettings?: (saveEmailwithUniqueNames: boolean) => void;
  changeShowHistory?: (chose: boolean) => void;
  resetSetting?: () => void;
  setOpenFilesSettings?: React.Dispatch<React.SetStateAction<OpenAction>>;
  changeEmailHeaderMapping?: (ehmChoice: EmailHeaderMappingChoices) => void;
  emailHeaderMapping?: EmailHeaderMappingChoices;
}

export const ehm_black_list = new OfficeUserSettingManager<string[]>('EmailHeaderMappingBlackList', () => []);
export const SearchHistoryState = new OfficeUserSettingManager<string[]>('SearchHistoryState', () => []);
export const DownloadOrBrowserState = new OfficeUserSettingManager<OpenAction>(
  'DownloadOrBrowserState',
  () => OpenAction.CHOOSE
);
export const OpenInDesktopAppOrBrowserState = new OfficeUserSettingManager<OpenAction>(
  'OpenInDesktopAppOrBrowserState',
  () => OpenAction.CHOOSE
);
export const SelectedTheme = new OfficeUserSettingManager<ThemeType>('SelectedTheme', () => 'system');
export const ShowTreeHistory = new OfficeUserSettingManager<string>('ShowTreeHistorySetting', () => 'true');

// Count states
export const SharedCountState = new OfficeUserSettingManager<number>('SharedCountState', () => 5);
export const ChatsCountState = new OfficeUserSettingManager<number>('ChatsCountState', () => 5);
export const RecentCountState = new OfficeUserSettingManager<number>('RecentCountState', () => 5);
export const SPFilesCountState = new OfficeUserSettingManager<number>('SPFilesCountState', () => 5);
export const RecentFolders = new OfficeUserSettingManager<any[]>('RecentFolders', () => []);
export const WhatsNewNotificationSeen = new OfficeUserSettingManager<string[]>('WhatsNewNotificationSeen', () => []);
export const AddCategory = new OfficeUserSettingManager<string>('AddCategory', () => 'true');
export const UseUniqueNames = new OfficeUserSettingManager<string>('UseUniqueNames', () => 'false');
export const EmailHeaderMapping = new OfficeUserSettingManager<EmailHeaderMappingChoices>(
  'EmailHeaderMapping',
  () => EmailHeaderMappingChoices.TeamsOnly
);

export const SettingsContext = createContext<SettingsContextInterface>({});

export const SettingProvider: FC<PropsWithChildren> = ({ children }) => {
  const [showTreeHistory, setShowTreeHistory] = useState<boolean>(ShowTreeHistory.value === 'true');
  const [addCategory, setAddCategory] = useState<boolean>(AddCategory.value === 'true');

  const [theme, setTheme] = useState<ThemeType>(SelectedTheme.value);
  const [generaFilesCount, setGeneralCount] = useState<number>(
    Math.min(SharedCountState.value, ChatsCountState.value, RecentCountState.value, SPFilesCountState.value)
  );
  const [openFilesSettings, setOpenFilesSettings] = useState<OpenAction>(OpenInDesktopAppOrBrowserState.value);
  const [emailNameSettings, setEmailNameSettings] = useState<boolean>(UseUniqueNames.value === 'true'); // Default to using just the email name
  const [emailHeaderMapping, setEmailHeaderMapping] = useState<EmailHeaderMappingChoices>(EmailHeaderMapping.value);

  const changeEmailNameSettings = useCallback((saveEmailwithUniqueNames: boolean) => {
    UseUniqueNames.value = saveEmailwithUniqueNames.toString();
    setEmailNameSettings(saveEmailwithUniqueNames);
  }, []);

  const changeEmailHeaderMapping = (ehmChoice: EmailHeaderMappingChoices) => {
    EmailHeaderMapping.value = ehmChoice;
    setEmailHeaderMapping(ehmChoice);
  };

  const changeOpenSettings = useCallback((action: OpenAction) => {
    OpenInDesktopAppOrBrowserState.value = action;
    setOpenFilesSettings(action);
  }, []);

  const changeAddCategory = useCallback((addCategory: boolean) => {
    AddCategory.value = addCategory.toString();
    setAddCategory(addCategory);
  }, []);

  const changeGeneralNumber = useCallback((number: number) => {
    ChatsCountState.value = number;
    SharedCountState.value = number;
    RecentCountState.value = number;
    SPFilesCountState.value = number;
    setGeneralCount(number);
  }, []);

  const changeTheme = useCallback((theme?: ThemeType) => {
    if (!theme) return;
    SelectedTheme.value = theme;
    setTheme(theme);
  }, []);

  const changeShowHistory = useCallback((choise: boolean) => {
    ShowTreeHistory.value = choise.toString();
    setShowTreeHistory(choise);
  }, []);

  const resetSetting = useCallback(() => {
    OpenInDesktopAppOrBrowserState.reset(true);
    SelectedTheme.reset(true);
    ShowTreeHistory.reset(true);
    SharedCountState.reset(true);
    ChatsCountState.reset(true);
    RecentCountState.reset(true);
    RecentFolders.reset(true);
    WhatsNewNotificationSeen.reset(true);
    FavoritesRoot.instance.resetNewItems(true);
    TeamsRoot.instance.resetNewItems(true);
    SharePointSitesRoot.instance.resetNewItems(true);
    onBoardingState.value = 1;
    setShowTreeHistory(true);
    setTheme('system');
    setGeneralCount(5);
    setOpenFilesSettings(OpenAction.CHOOSE);
    AddCategory.reset(true);
    PubSub.publish('resetSetting', {});
  }, []);

  return (
    <SettingsContext.Provider
      value={{
        theme,
        changeTheme,
        changeGeneralNumber,
        changeOpenSettings,
        generaFilesCount,
        openFilesSettings,
        showTreeHistory,
        addCategory,
        changeShowHistory,
        resetSetting,
        setOpenFilesSettings,
        changeAddCategory,
        emailNameSettings,
        changeEmailNameSettings,
        changeEmailHeaderMapping,
        emailHeaderMapping,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => {
  return useContext(SettingsContext);
};
