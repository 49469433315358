import { Chip, useTheme } from '@mui/material';
import React from 'react';

export const EmptyCell = ({}) => {
  const theme = useTheme();
  return (
    <Chip
      sx={{
        backgroundColor: theme.palette.chip.primary,
        borderRadius: '8px',
        cursor: 'default',
        whiteSpace: 'nowrap', // Prevent text wrapping
        overflow: 'hidden', // Hide overflow
        textOverflow: 'ellipsis', // Show ellipsis for overflowed text
        height: '90%',
        marginBottom: '2px !important',
        minWidth: theme.spacing(20),

        '&:hover': {
          backgroundColor: theme.palette.chip.secondary,
        },
        '&.active': {
          backgroundColor: theme.palette.chip.tertiary,
        },
      }}
    />
  );
};
