import React, { FC, Suspense, useCallback, useEffect, useState } from 'react';
import { createMsalInstance } from '@services';
import { MsalProvider } from '@azure/msal-react';
import { createRoot } from 'react-dom/client';
import { Provider } from '@storybook';
import { IPublicClientApplication } from '@azure/msal-browser';
import { OnOfficeReady, ReactAppInit, ReactAppInitData } from '~/taskpane';
import { asyncSubscribe, asyncSubscribeOnce } from '~/hooks/useSubscribe';
import { lightOrDark, setConfig } from '~/utilities';
import { SettingProvider, useSettings } from '~/modules/Settings/SettingContext';
import LoadingScreen from '~/modules/Login/LoadingScreen';
import { IndicatorProvider } from '~/context/IndicatorProvider';

const DragAndDropGlobal = React.lazy(() => import('./DragAndDropGlobal'));
const App = React.lazy(() => import('~/taskpane/App'));

const AppWithSettings: FC = () => {
  const { theme } = useSettings();
  let isDark = theme === 'dark';
  if (theme === 'system') isDark = lightOrDark(Office.context?.officeTheme?.bodyBackgroundColor);
  const [msalInstance, setMsalInstance] = useState<IPublicClientApplication>();
  const initMsal = useCallback(async () => setMsalInstance(await createMsalInstance()), []);
  useEffect(() => {
    initMsal();
  }, [initMsal]);

  return (
    <Provider themeMode={isDark ? 'dark' : 'light'}>
      <IndicatorProvider>
        <DragAndDropGlobal>
          {msalInstance && (
            <MsalProvider instance={msalInstance}>
              <Suspense fallback={<LoadingScreen></LoadingScreen>}>
                <App />
              </Suspense>
              <div id="poppoverContainer" />
            </MsalProvider>
          )}
        </DragAndDropGlobal>
      </IndicatorProvider>
    </Provider>
  );
};

const onOfficeInit = async () => {
  setConfig();
  const container = createRoot(document.getElementById('container') as HTMLElement);

  container.render(
    <SettingProvider>
      <AppWithSettings />
    </SettingProvider>
  );
};

const RefreshStamp = Date.now(); // Ensure that getting all teams is refreshed exactly once!

const onReactInit = async ({ account, gcl }: ReactAppInitData) => {
  const { initializeDB } = await import('@services');
  const { TeamsRoot } = await import('~/utilities');

  await initializeDB();
  if (account)
    // Start collecting team urls in BG...
    TeamsRoot.collectAllTeamsLocations(gcl, RefreshStamp);
};

export const AppInit = () => {
  asyncSubscribeOnce(OnOfficeReady, onOfficeInit);
  asyncSubscribe(ReactAppInit, onReactInit);
};
