import { IconProps } from '@storybook';
import { BaseItem } from './BaseItem';
import { ItemContainer } from './ItemContainer';
import { ItemData } from './ItemData';

interface ViewFilesProps {
  name: string;
  parent: ItemContainer;
}

export class ViewFilesItem extends BaseItem {
  readonly data: ItemContainer;

  constructor({ name, parent: data }: ViewFilesProps) {
    super({ type: 'viewfiles', id: `viewFiles:${name}`, name: name });
    this.data = data;
  }
  get isVirtual(): boolean {
    return true;
  }

  getIcon(_expanded: boolean): IconProps {
    throw new Error('Method not implemented.');
  }

  apply(items: ItemData[], length: number, cond = true) {
    return cond && items.length >= length ? [...items, this] : items;
  }
}
