import React from 'react';
import ItemNodeContentText from '../../../NavigationItem/components/ItemNodeContentText';

interface DateTimeCellProps {
  value: string; // Assuming SharePoint returns date as a string
  dataAndTime: boolean; // Determines if both date and time should be shown
}

export const DateTimeCell: React.FC<DateTimeCellProps> = ({ value, dataAndTime }) => {
  // Function to format the date based on the input value and whether to show time
  const formatDate = (value: string): string => {
    const friendlyFormatRegex = /^[A-Za-z]+\s+\d{1,2}(,\s*\d{4})?$/;

    // If the value matches a friendly format (e.g., "September 18"), return it unchanged
    if (friendlyFormatRegex.test(value)) {
      return value;
    }

    const date = new Date(value);

    // If the date is invalid (e.g., invalid date string), return the original value
    if (isNaN(date.getTime())) {
      return value;
    }

    if (dataAndTime) {
      // Format the date to include hours and minutes but exclude seconds
      return date.toLocaleString(undefined, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false, // Use 24-hour format (set to true for 12-hour with AM/PM)
      });
    }

    // If only the date is needed, return the localized date format
    return date.toLocaleDateString();
  };

  return <ItemNodeContentText highLightSecondLine={false}>{formatDate(value)}</ItemNodeContentText>;
};
