import React, { useCallback, useMemo } from 'react';
import {
  GridColDef,
  GridFetchRowsParams,
  GridRenderCellParams,
  GridRowProps,
  GridTreeNodeWithRender,
  GridValidRowModel,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { debounce } from '@mui/material';
import { AttachmentSmall, Box, CustomHeaderCell, CustomIcon, TypeMedium, ViewItem, VirtualizedTable } from '@storybook';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { FavoritesRoot, ItemData, QuickActionManager } from '~/utilities';
import { getViewRowTableRenderer } from '~/components/Tree/Renderers/GetNodeRenderer';
import { UseDrilldownResult } from '~/components/Tree/Renderers/DrilldownHandler';
import { SharePointFieldType } from '~/utilities/metadata/SharePointFieldType';
import { possibleFieldMappings } from '~/utilities/manageProperties/ManageProperties';
import SharePointCell from './cell/SharePointCell';
import { calculateColumnWidths, calculateQuickActionIndex } from './cell/SharePointCellHelper';

type TableManagerProps = Omit<
  UseDrilldownResult,
  'pageToken' | 'pagginationText' | 'resultCount' | 'counter' | 'loadingRef'
>;

const TableManager: React.FC<TableManagerProps> = ({
  listHandlers,
  selectedView,
  items,
  newItems,
  mappedItems,
  selection,
  nextToken,
  fetchPage,
  schema,
}) => {
  const apiRef = useGridApiRef();
  const [columnWidths, setColumnWidths] = React.useState<{ [key: string]: number }>({});
  const CustomAttachmentSmall = CustomIcon(AttachmentSmall, true);
  const CustomTypeMedium = CustomIcon(TypeMedium, true);

  const handleColumnResize = useCallback((params: { colDef: GridColDef; width: number }) => {
    setColumnWidths(prev => ({
      ...prev,
      [params.colDef.field]: params.width,
    }));
  }, []);
  const debouncedHandleColumnResize = useMemo(() => debounce(handleColumnResize, 300), [handleColumnResize]);

  // Render custom row component
  const renderRow = useCallback(
    (rowProps: GridRowProps) => {
      const node = rowProps.row as ViewItem<ItemData>;
      const renderer = getViewRowTableRenderer(node.data.type);
      return renderer?.({
        node,
        handlers: listHandlers,
        onSelect: () => rowProps.onClick,
        onToggle: () => undefined,
        highlightString: undefined,
        gridRowProps: rowProps,
      });
    },
    [listHandlers]
  );

  // Fetch rows from the server
  const fetchRows = useCallback(
    async (params: GridFetchRowsParams) => {
      await fetchPage(nextToken, items);
      const rows = mappedItems;
      updateDataGridRows(apiRef, params, rows);
      return {
        slice: rows.slice(params.firstRowToRender, params.lastRowToRender),
        total: rows.length,
      };
    },
    [fetchPage, nextToken, items, mappedItems, apiRef]
  );

  const debouncedFetchRows = useMemo(() => debounce(fetchRows, 300), [fetchRows]);

  // Determine row size
  const getRowSize = useCallback(
    (items: ViewItem<ItemData>[], index: number) =>
      newItems.length && newItems.length > index ? 32 : items[index]?.data?.size || 32,
    [newItems]
  );

  const columns = useMemo(() => {
    // Create a map of InternalName to schema field for quick lookup
    const schemaMap = new Map(schema?.map(field => [field.InternalName, field]) || []);
    const schemaFieldsMap = selectedView?.stateView.viewFields.map(fieldName => {
      return schemaMap.get(fieldName);
    });
    const quickActionIndex = calculateQuickActionIndex(schemaFieldsMap, possibleFieldMappings);
    // Map over the schemaFieldsMap to generate the columns configuration
    return schemaFieldsMap?.map((schemaField, index) => {
      const isAttachmentsType = schemaField && possibleFieldMappings.Attachments.includes(schemaField.StaticName);
      const isTypeColumn =
        schemaField &&
        schemaField.TypeAsString === SharePointFieldType.Computed &&
        schemaField?.InternalName === 'DocIcon';
      const columnWidth = (schemaField && calculateColumnWidths(isTypeColumn || Boolean(isAttachmentsType))) || 150;
      return {
        field: schemaField?.InternalName || '',
        headerName: schemaField?.Title || '',
        renderCell: (params: GridRenderCellParams<GridValidRowModel, any, any, GridTreeNodeWithRender>) => {
          const item = params.row as ViewItem<ItemData>;
          if (!schemaField) {
            return null;
          }
          return (
            <Box sx={{ display: 'flex' }}>
              <SharePointCell
                field={schemaField}
                item={params.row as ViewItem<ItemData>}
                isSelected={listHandlers.isSelected(item)}
                isTypeIcon={Boolean(isTypeColumn)}
                columnWidth={(schemaField && columnWidths[schemaField.InternalName]) ?? columnWidth}
              />
              {/* Render QuickActionManager only once based on the pre-calculated index */}
              {quickActionIndex === index && (
                <Box sx={{ marginLeft: 'auto' }}>
                  <QuickActionManager
                    node={item}
                    handlers={listHandlers}
                    isFavoriteItem={FavoritesRoot.AllFavorites.isFavorite(item.data)}
                  />
                </Box>
              )}
            </Box>
          );
        },
        renderHeader: params => (
          <CustomHeaderCell
            headerCellName={params.colDef.headerName}
            Icon={isAttachmentsType ? <CustomAttachmentSmall /> : isTypeColumn ? <CustomTypeMedium /> : undefined}
          />
        ),
        minWidth: columnWidth,
        maxWidth: 400,
        width: (schemaField && columnWidths[schemaField.InternalName]) ?? columnWidth,
      };
    });
  }, [schema, selectedView?.stateView.viewFields, columnWidths, listHandlers, CustomAttachmentSmall, CustomTypeMedium]);

  return (
    <VirtualizedTable
      columns={columns || []}
      rows={mappedItems}
      getSize={getRowSize}
      renderRow={renderRow}
      rowSelectionModel={Array.from(selection.values()).map(item => item.id)}
      onFetchRows={debouncedFetchRows}
      rowsCount={calculateRowsCount(nextToken, mappedItems)}
      apiRef={apiRef}
      handleColumnResize={debouncedHandleColumnResize}
    />
  );
};

function updateDataGridRows(apiRef: React.MutableRefObject<GridApiPremium>, params: GridFetchRowsParams, rows: any[]) {
  apiRef.current.unstable_replaceRows(
    params.firstRowToRender,
    rows.slice(params.firstRowToRender, params.lastRowToRender)
  );
}

function calculateRowsCount(nextToken: string | undefined, mappedItems: ViewItem<ItemData>[]) {
  if (nextToken !== undefined) {
    return mappedItems.length + 20;
  }
  return mappedItems.length !== 0 ? mappedItems.length : 20;
}

export default TableManager;
