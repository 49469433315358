import { SVGComponent, ViewItem, IconProps } from '@storybook';
import { StackProps } from '@mui/material';
import { BaseAction } from '../actions';
import { BaseItem } from './BaseItem';
import { ItemData } from './ItemData';
import { ItemContainer } from './ItemContainer';

interface EmptyStateProps {
  name: string;
  images?: { light: SVGComponent; dark: SVGComponent };
  action?: BaseAction;
  location?: ViewItem<ItemData>;
  componentProps?: StackProps;
  nestingLevel?: number;
  noBackground?: boolean;
  isSearchItem?: boolean;
  inTree?: boolean;
  isEmptyDrilldown?: boolean;
  isSaveableLocation?: boolean;
  size?: number;
}

export class EmptyStateItem extends BaseItem {
  images?: { light: SVGComponent; dark: SVGComponent };
  action?: BaseAction;
  location?: ViewItem<ItemData>;
  componentProps?: StackProps;
  nestingLevel?: number;
  noBackground = false;
  readonly size: number;
  isSearchItem = false;
  inTree = false;
  isEmptyDrilldown: boolean;
  isSaveableLocation = false;

  constructor({
    name,
    action,
    location,
    componentProps,
    images,
    nestingLevel,
    noBackground,
    isSearchItem,
    inTree,
    size,
    isEmptyDrilldown,
    isSaveableLocation,
  }: EmptyStateProps) {
    super({ type: 'empty', id: `empty:${name}`, name: name });
    this.action = action;
    this.location = location;
    this.images = images;
    this.componentProps = componentProps;
    this.nestingLevel = nestingLevel || 1;
    this.noBackground = noBackground || false;
    this.isSearchItem = isSearchItem || false;
    this.inTree = inTree || false;
    this.isEmptyDrilldown = isEmptyDrilldown || false;
    this.isSaveableLocation = isSaveableLocation || false;
    this.size = size || 300;
  }

  // TODO: Create isSerialized function!!
  protected fillJson(res: EmptyStateProps): void {
    super.fillJson(res);
  }

  //Allow drag and drop to empty state item!
  override get parent() {
    return this.location?.data as ItemContainer;
  }
  get isVirtual(): boolean {
    return true;
  }

  apply(items: ItemData[], show: boolean) {
    if (show) items.push(this);
    return items;
  }

  getIcon(_expanded: boolean): IconProps {
    throw new Error('Method not implemented.');
  }
}
