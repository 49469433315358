import React from 'react';
import { Link } from '@mui/material';
import ItemNodeContentText from '../../../NavigationItem/components/ItemNodeContentText';

interface LinkCellProps {
  value: string;
}

export const LinkCell: React.FC<LinkCellProps> = ({ value }) => {
  const isValidUrl = (value: string): boolean => {
    try {
      new URL(value);
      return true;
    } catch (_) {
      return false;
    }
  };

  return isValidUrl(value) ? (
    <Link
      onClick={() => {
        window.open(value);
      }}
    >
      <ItemNodeContentText highLightSecondLine={false}>{value}</ItemNodeContentText>
    </Link>
  ) : (
    <ItemNodeContentText highLightSecondLine={false}>{value}</ItemNodeContentText>
  );
};
