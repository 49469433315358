import { OneDriveEmptyStatLight, OneDriveEmptyStateDark, OneDriveMedium, IconProps } from '@storybook';
import { Drive } from '@microsoft/microsoft-graph-types';
import { GraphWebUrlIdsKeys, GraphClient, EternalWindow } from '@services';
import { oDirname } from '@vendor/utils/misc';
import { strings } from '@vendor/languages';
import { SPFolder, SPList, SPSite } from '../sharePointTypes';
import {
  BaseContainer,
  FetchChildrenProps,
  EmptyStateItem,
  Folder,
  AccessUrls,
  MicrosoftApps,
  ItemDataWithPaging,
} from '../itemTypes';
import { getLibraryOdataIdFromListId } from '../sharePointAPI';

export class OneDriveRoot extends BaseContainer {
  constructor() {
    super({ type: 'onedriveroot', id: 'OneDrive:', name: strings.lang.nodeNames.oneDrive });
    this.rootPrefix = 'oneDrive:';
  }

  override get isVirtual(): boolean {
    return false;
  }
  override get hasFolder(): boolean {
    return true;
  }
  override get canOpenInBrowser(): boolean {
    return true;
  }
  override get isRootSection(): boolean {
    return true;
  }
  get OfficeAppLocated() {
    return MicrosoftApps.OneDrive;
  }

  override getNumberOfQuickActions(isDrilldown: boolean): number {
    return isDrilldown ? 3 : 2;
  }

  async getFolder(gcl: GraphClient): Promise<SPFolder> {
    return await (await OneDriveRoot.getRootLibrary(gcl)).getFolder();
  }
  override async getContentTypes(gcl: GraphClient): Promise<any> {
    const folder = await this.getFolder(gcl);
    return await folder.getContentTypes(gcl);
  }

  override async getAccessUrls(gcl: GraphClient): Promise<AccessUrls | undefined> {
    return await ((await this.getFolder(gcl)) as Folder).getAccessUrls(gcl);
  }

  static async getRootLibrary(gcl: GraphClient): Promise<SPList> {
    const oneDriveRoot: Drive = await gcl.api('/me/drive').cache(EternalWindow).select(GraphWebUrlIdsKeys).get();
    const oneDriveUrl = new URL(oneDriveRoot.webUrl || '');
    const oneDriveSiteUrl = oDirname(oneDriveUrl.href);
    return new SPList({
      name: oneDriveSiteUrl.slice(oneDriveSiteUrl.length + 1),
      serverRelativeUrl: decodeURI(oneDriveUrl.pathname),
      site: SPSite.fromSharepointIds(oneDriveRoot.sharePointIds, MicrosoftApps.OneDrive),
      id: getLibraryOdataIdFromListId(oneDriveRoot.sharePointIds?.siteUrl, oneDriveRoot.sharePointIds?.listId),
      OfficeAppLocated: MicrosoftApps.OneDrive,
    });
  }

  override async fetchChildren(props: FetchChildrenProps): Promise<ItemDataWithPaging> {
    const oneDriveDocumentsLibrary = await OneDriveRoot.getRootLibrary(props.gcl);
    const folder = await oneDriveDocumentsLibrary.getFolder();
    const oneDriveItems = await folder.fetchChildren(props, this);
    return {
      items: new EmptyStateItem({
        name: 'oneDrive',
        inTree: true,
        images: { light: OneDriveEmptyStatLight, dark: OneDriveEmptyStateDark },
      }).apply(oneDriveItems.items, oneDriveItems.items.length === 0),
    };
  }

  override getIcon(_expanded: boolean): IconProps {
    return { icon: OneDriveMedium, isColorable: false };
  }
}
