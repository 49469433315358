import React, { useCallback as useCallbackOrig } from 'react';
import { useNavigate } from 'react-router-dom';
import { Onboarding, Stack, useTheme } from '@storybook';
import { BaseReadActionClass, OfficeUserSettingManager, createUseCallbackWrap, showLoginPopup } from '~/utilities';
import { getEmptyHandlers } from '~/components/Tree/Renderers/QuickSearchHandler';
import { AllLocations } from '~/utilities/virtualRoots';
import { onBoardingDone, onBoardingState } from '../Onboarding/OnboardingFlow';

class SignInAction extends BaseReadActionClass {
  readonly trackedName = 'SignIn';
  isHandled(): boolean {
    return true;
  }
}

const Login = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const newPermissions = new OfficeUserSettingManager<number>('newPermissions', () => 0);

  const useCallback = createUseCallbackWrap(
    new SignInAction(),
    'Other',
    [],
    useCallbackOrig,
    getEmptyHandlers(AllLocations.instance)
  );

  const afterSignIn = useCallbackOrig(async () => {
    if (newPermissions.value === 0) {
      newPermissions.value = newPermissions.value + 1;
    }
    PubSub.publish('onSigin', {});
    if (onBoardingDone.value) {
      navigate('/home');
    } else navigate('/');
  }, []);
  const loginCallback = useCallback(async () => showLoginPopup(afterSignIn), [afterSignIn]);

  return (
    <Stack
      sx={{ height: '100%', width: '100%' }}
      className="hidden-scrollbar"
      background={theme.palette.backgrounds.primary}
    >
      <Onboarding
        loginCallback={loginCallback}
        showFirstSlide={true}
        currentSlide={onBoardingState.value}
        isRequirednewPermissions={newPermissions.value === 0}
      />
    </Stack>
  );
};

export default Login;
