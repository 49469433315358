import { logError } from '@vendor/utils/misc';
import { SharePointClient } from '../sharePointAPI';

interface ViewCreationParameters {
  title: string;
  rowLimit: number;
  viewFields: string[];
  query?: string;
  viewType?: string;
}

export const createView = async (spc: SharePointClient, listUrl: string, viewParams: ViewCreationParameters) => {
  const url = `${listUrl}/views`;
  const requestBody = {
    __metadata: { type: 'SP.View' },
    Title: viewParams.title,
    RowLimit: viewParams.rowLimit,
    ViewQuery: viewParams.query || '<OrderBy><FieldRef Name="ID"/></OrderBy>',
    ViewType: viewParams.viewType || 'HTML',
    PersonalView: false,
    Paged: true,
  };

  try {
    // First, create the view
    const response = await spc.api(url).post(requestBody);
    const viewId = response.Id;

    // Remove all existing fields from the view
    const viewFieldsUrl = `${listUrl}/views(guid'${viewId}')/viewfields`;
    const existingFieldsResponse = await spc.api(viewFieldsUrl).get();
    const existingFields = existingFieldsResponse.Items;

    for (const field of existingFields) {
      const removeFieldUrl = `${viewFieldsUrl}/removeviewfield('${field}')`;
      await spc.api(removeFieldUrl).post({});
    }

    // Add the specified fields to the view
    for (const field of viewParams.viewFields) {
      const addFieldUrl = `${viewFieldsUrl}/addviewfield('${field}')`;
      await spc.api(addFieldUrl).post({});
    }
  } catch (error) {
    logError(`Error creating view ${viewParams.title}: ${error}`);
    throw error;
  }
};
