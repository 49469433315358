import React from 'react';
import { List, ListItem, ListItemIcon } from '@mui/material';
import { Typography } from '../Typography';

export interface CustomListProps {
  items: string[];
  Bullet: React.ReactNode;
}

//TODO add the CheckMarkSmall as a prop
const CustomList = ({ items, Bullet }: CustomListProps) => {
  return (
    <List sx={{ padding: 0 }}>
      {items.map((item, index) => (
        <ListItem key={index} sx={{ paddingLeft: 0, paddingTop: 2, paddingBottom: 0 }}>
          <ListItemIcon>{Bullet}</ListItemIcon>
          <Typography variant="H2Regular">{item}</Typography>
        </ListItem>
      ))}
    </List>
  );
};

export default CustomList;
