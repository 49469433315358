import { Chip, useTheme, Stack } from '@mui/material';
import React, { useMemo } from 'react';
import { HiddenCountChip } from './HiddenCountChip';

interface ChoiceCellProps {
  value: string[] | string; // The array of choice values or a single string
  isArray: boolean; // This flag indicates if value is an array
  columnWidth: number; // The width of the column (sent from the parent component)
}

export const ChoiceCell: React.FC<ChoiceCellProps> = ({ value, isArray, columnWidth }) => {
  const theme = useTheme();
  const chipWidth = 60; // Approximate width of a chip
  const chipMargin = 8; // Approximate margin between chips
  const remainingChipWidth = 30; // Width for the "+X" chip that shows hidden count

  // Ensure value is always an array for the calculation
  const valueArray = isArray ? (value as string[]) : [value as string];

  // Calculate how many chips can fit within the available width
  const visibleChipsCount = useMemo(() => {
    const totalChips = valueArray.length;
    // Subtract space for the "+X" chip, then calculate how many chips can fit
    const availableChips = Math.floor((columnWidth - remainingChipWidth) / (chipWidth + chipMargin));
    return Math.min(totalChips, availableChips);
  }, [columnWidth, valueArray.length, chipWidth, chipMargin]);

  // Helper function to render chips
  const renderChips = (values: string[]) => {
    if (values.length === 0) return null;

    const visibleValues = values.slice(0, visibleChipsCount);
    const hiddenCount = values.length - visibleChipsCount;

    return (
      <>
        {visibleValues.map((val, index) => (
          <Chip
            key={index}
            label={val}
            sx={{
              backgroundColor: theme.palette.chip.primary,
              borderRadius: '8px',
              cursor: 'default',
              display: 'flex', // Ensure flex layout
              justifyContent: 'center', // Center horizontally
              alignItems: 'center', // Center vertically
              whiteSpace: 'nowrap', // Prevent text wrapping
              overflow: 'hidden', // Hide overflow
              textOverflow: 'ellipsis', // Show ellipsis if text overflows
              padding: theme.spacing(0.5, 1), // Adjust padding inside the chip
              height: theme.spacing(5), // Set height for the chip
              marginBottom: '2px !important',
              minWidth: theme.spacing(7), // Ensure a minimum width
              maxWidth: '100%', // Allow the chip to grow as much as possible

              '& .MuiChip-label': {
                width: '100%', // Make sure the label takes full width of the chip
                overflow: 'hidden', // Hide overflow inside label
                textOverflow: 'ellipsis', // Apply ellipsis only when needed
                whiteSpace: 'nowrap', // Prevent wrapping
                textAlign: 'left', // Align text to the left
                fontFamily: 'Urbanist', // Set font-family to Urbanist
                fontSize: '12px',
                fontWeight: '440',
                lineHeight: '18px',
                color: theme.palette.texts.primary,
              },

              '&:hover': {
                backgroundColor: theme.palette.chip.secondary,
              },
            }}
          />
        ))}

        {hiddenCount > 0 && <HiddenCountChip hiddenCount={hiddenCount} />}
      </>
    );
  };

  return valueArray.length ? (
    <Stack
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row', // Arrange items in a row
        whiteSpace: 'nowrap', // Prevent wrapping in Stack
        overflow: 'hidden', // Ensure the overflow doesn't break layout
        height: '100%',
        width: '100%', // Set the container width to fit multiple chips
        padding: 0, // Ensure no extra padding adds space issues
        margin: 0, // Avoid extra margin adding extra space
      }}
      spacing={1}
      direction={'row'}
    >
      {renderChips(valueArray)}
    </Stack>
  ) : (
    <></>
  );
};
