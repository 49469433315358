import React from 'react';
import { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-premium';
import ItemNodeContentText from '../../../NavigationItem/components/ItemNodeContentText';
import { Stack } from '../../../Stack';
import CellContentIcon from './CellContentIcon';

export interface CustomCellProps {
  params: GridRenderCellParams<GridValidRowModel>;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  isColorable?: boolean;
  isSelected?: boolean;
  quickActions?: React.ReactNode;
}
export const CustomCell = ({ params, Icon, isSelected, isColorable, quickActions }: CustomCellProps) => {
  return (
    <Stack direction="row">
      {Icon && (
        <CellContentIcon isColorable={isColorable} isSelected={isSelected}>
          <Icon />
        </CellContentIcon>
      )}
      <ItemNodeContentText highLightSecondLine={false}>{params.value}</ItemNodeContentText>
      {quickActions && quickActions}
    </Stack>
  );
};
