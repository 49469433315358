import { ShareWithMeEmptyStateDark, ShareWithMeEmptyStateLight, SharedWithMeMedium, IconProps } from '@storybook';
import { DriveItem, SharedInsight } from '@microsoft/microsoft-graph-types';
import { GValue, RecentExpirationWindow, GraphBatchRequest } from '@services';
import { strings } from '@vendor/languages';
import { SharedCountState } from '~/modules/Settings/SettingContext';
import { FilterEmailAttachments, getSortByDate, InsightItem, InsightTypedFilter } from '../graphTypes';
import { BaseContainer, FetchChildrenProps, ItemDataWithPaging, MicrosoftApps } from '../itemTypes';
import { EmptyStateItem } from '../itemTypes';
import { SPFolder, SPList, SPSite } from '../sharePointTypes';
import { OfficeUserSettingManager } from '../stateManager';
import { encodeSpecialCharacters, getFolderOdataIdFromUrl, getLibraryOdataIdFromListId } from '../sharePointAPI';
import { RecentRoot } from './RecentRoot';

export class SharedWithMeRoot extends BaseContainer {
  static SharedCountState = SharedCountState;
  static SharedFilterState = new OfficeUserSettingManager<boolean>('SharedFilterState', () => false);
  constructor() {
    super({ type: 'sharedwithmeroot', id: 'root', name: strings.lang.nodeNames.sharedWithMe });
    this.rootPrefix = 'shaerdwithme:';
  }

  get stateCount(): OfficeUserSettingManager<number> {
    return SharedWithMeRoot.SharedCountState;
  }

  onChangeFilter(checked: boolean): void {
    SharedWithMeRoot.SharedFilterState.value = checked;
  }

  get filterState(): boolean {
    return SharedWithMeRoot.SharedFilterState.value;
  }

  get isRootSection(): boolean {
    return true;
  }

  get locationHaveQuickSearch(): boolean {
    return false;
  }

  get serverRelativeUrl(): string {
    return '';
  }

  override getNumberOfQuickActions(): number {
    return 1;
  }

  override get itemCanSelected(): boolean {
    return false;
  }

  override async fetchChildren({ gcl, refreshStamp }: FetchChildrenProps): Promise<ItemDataWithPaging> {
    const { account, encodedUsername } = RecentRoot.getAccountAndName(gcl);
    const gb = new GraphBatchRequest(gcl.client);
    const sharedFilter = SharedWithMeRoot.SharedFilterState.value
      ? InsightTypedFilter
      : InsightTypedFilter + FilterEmailAttachments;
    // get documents that shared with me
    const sharedFromInsight = await gb
      .api('/me/insights/shared')
      .cache(RecentExpirationWindow, refreshStamp)
      .filter(`${sharedFilter} AND (lastShared/sharedBy/address ne '${encodeSpecialCharacters(encodedUsername)}')`)
      .top(35)
      .get();

    // get folders that shared with me
    const sharedFolders = await gb
      .api('me/drive/sharedWithMe')
      .cache(RecentExpirationWindow, refreshStamp)
      .top(25)
      .get();

    await gb.runAll();
    const { value: sharedItems }: GValue<SharedInsight> = await sharedFromInsight();
    const { value: sharedFoldersDriveApi }: GValue<DriveItem> = await sharedFolders();

    const sharedFoldersList = sharedFoldersDriveApi
      .filter(v => v.folder != null)
      .slice(0, 25)
      .map(f => f.remoteItem)
      .map(f => {
        const spIds = f?.sharepointIds;
        const siteUrl = new URL(spIds?.siteUrl || '');
        const libName = decodeURIComponent(f?.webUrl?.replace(siteUrl.href, '').split('/')[1] || '');
        const folderUrl = new URL(f?.webDavUrl || '');
        const decodedFolderPath = decodeURIComponent(folderUrl.pathname);
        const folder = new SPFolder({
          metadata: {
            id: getFolderOdataIdFromUrl(siteUrl.href, decodedFolderPath),
            FileRef: decodedFolderPath,
            FileLeafRef: f?.name || '',
            UniqueId: f?.sharepointIds?.listItemUniqueId || '',
            Modified: new Date(f?.lastModifiedDateTime || ''),
          },
          list: new SPList({
            name: libName,
            id: getLibraryOdataIdFromListId(spIds?.siteUrl, spIds?.listId),
            serverRelativeUrl: `${siteUrl.pathname}/${libName}`,
            site: SPSite.fromSharepointIds(spIds, MicrosoftApps.SharePoint),
            OfficeAppLocated: MicrosoftApps.SharePoint,
          }),
        });
        return {
          folder,
          date: f?.shared?.sharedDateTime || '',
        };
      });
    const sharedFromInsightItems = sharedItems
      .filter(item => item.resourceReference?.type !== 'microsoft.graph.entity')
      .map(item => InsightItem.fromInsight(item, account));

    const res = [...sharedFoldersList, ...sharedFromInsightItems]
      .sort(getSortByDate())
      .slice(0, SharedWithMeRoot.SharedCountState.value);
    const folderToShow: Array<SPFolder> = [];
    const documentsToShow: Array<InsightItem> = [];

    res.map((item: any) => {
      if (item instanceof InsightItem) documentsToShow.push(item);
      else folderToShow.push(item.folder);
    });
    folderToShow.map(folder => folder);
    const items = [...folderToShow, ...documentsToShow]
      .slice(0, Math.min(hrmProvisioning.sharedMaxCount, SharedWithMeRoot.SharedCountState.value))
      .map(v => {
        v.isTransientLocation = true;
        return v;
      });
    return {
      items: new EmptyStateItem({
        name: 'sharedwithme',
        inTree: true,
        images: { light: ShareWithMeEmptyStateLight, dark: ShareWithMeEmptyStateDark },
      }).apply(items, items.length === 0),
    };
  }

  override getIcon(_expanded: boolean): IconProps {
    return { icon: SharedWithMeMedium, isColorable: true };
  }
}
