import { PaletteOptions } from '@mui/material/styles';

const lightPaletteOptions: PaletteOptions = {
  mode: 'light',
  texts: {
    primary: '#5C5E60',
    secondary: '#8F98A3',
    tertiary: '#52627A',
    quaternary: '#849EBD',
    accentPrimary: '#1784E8',
    accentSecondary: '#0B77C9',
    onAccentPrimary: '#FFFFFF',
    onAccentSecondary: '#DAE9F6',
    titlePrimary: '#383E4D',
    disabled: '#C1C6CD',
    placeHolder: '#ABB1BA',
    placeHolderSecondary: 'rgba(132, 158, 189, 0.7)',
    teams: '#4C53BB',
  },
  backgrounds: {
    primary: '#FFFFFF',
    secondary: '#E3EEFA',
    tertiary: '#F5F7F9',
    quaternary: 'rgba(199, 204, 209, 0.3)',
    overlaidComponentsPrimary: '#FFFFFF',
    overlaidComponentsSesondary: '#FFFFFF',
    overlaidComponentsTertiary: '#FFFFFF',
    overlay: 'rgba(156, 158, 160, 0.5)',
    temp: '#F2F7FD',
    emptyStateBackground: '#F2F7FD',
  },
  buttons: {
    accentPrimary: '#1784E8',
    accentSecondary: '#3D9DF5',
    accentTertiary: '#0B77C9',
    primary: '#F2F7FD',
    secondary: 'rgba(125, 182, 232, 0.3)',
    tertiary: 'rgba(51, 138, 215, 0.3)',
    disabled: '#EEF0F1',
    dangerPrimary: 'rgba(232, 17, 35, 0.1)',
    dangerSecondary: 'rgba(232, 17, 35, 0.15)',
  },
  tooltips: {
    primary: '#383E4D',
    secondary: '#1784E8',
  },
  status: {
    defaultPrimary: '#1784E8',
    defaultSecondary: 'rgba(23, 132, 232, 0.1)',
    successPrimary: '#0F8A42',
    successSecondary: 'rgba(15, 138, 66, 0.1)',
    dangerPrimary: '#E81123',
    dangerSecondary: 'rgba(232, 17, 35, 0.1)',
    warningPrimary: '#E57700',
    warningSecondary: 'rgba(229, 119, 0, 0.1)',
  },
  scrollBar: {
    default: 'rgba(133, 153, 173, 0.4)',
    hover: 'rgba(133, 153, 173, 0.6)',
    pressed: 'rgba(133, 153, 173, 0.8)',
  },
  chip: {
    primary: 'rgba(191, 204, 217, 0.25)',
    secondary: 'rgba(191, 204, 217, 0.4)',
    tertiary: 'rgba(191, 204, 217, 0.6)',
    locationchipPrimary: 'rgba(191, 204, 217, 0.15)',
    userchipPrimary: 'rgba(23, 132, 232, 0.1)',
    userchipSecondary: 'rgba(23, 132, 232, 0.2)',
  },
  shadow: {
    primary: '#11243B',
    menu: 'rgba(17, 36, 59, 0.1)',
    item: '0px 0px 20px 0px rgba(61, 157, 245, 0.20)',
    tooltipPreview: '0px 35px 50px -30px rgba(17, 36, 59, 0.25)',
    tooltip: '0px 12px 10px -10px rgba(17, 36, 59, 0.50)',
    button: '0px 8px 20px -10px rgba(17, 36, 59, 0.50)',
    focus: ' 0px 0px 0px 2px rgba(23, 132, 232, 0.30)',
    buttonSheet:
      '0px 5px 25px 0px rgba(17, 36, 59, 0.10), 0px 0px 5px 0px rgba(17, 36, 59, 0.10), 0px 0px 60px 0px rgba(23, 132, 232, 0.05)',
    attachment:
      '0px 10px 20px 0px rgba(23, 132, 232, 0.05), 0px 5px 15px 0px rgba(17, 36, 59, 0.05), 1px 2px 5px 0px rgba(17, 36, 59, 0.15)',
    rootSectionSticky: '1px 2px 5px 0px rgba(17, 36, 59, 0.1)',
    bottomSheet:
      ' 0px 5px 25px 0px rgba(17, 36, 59, 0.10), 0px 0px 5px 0px rgba(17, 36, 59, 0.10), 0px 0px 60px 0px rgba(23, 132, 232, 0.05)',
  },
  outlinesAndStrokes: {
    primary: 'rgba(195, 196, 198, 0.3)',
    secondary: '#D8D9DA',
    tertiary: '#BEBFC1',
    disabled: '#C1C6CD',
    accent: '#1784E8',
  },
  boxshadow: {
    inputError: '0px 0px 0px 2px rgba(232, 17, 35, 0.10)',
    inputFocus: '0px 0px 0px 2px rgba(23, 132, 232, 0.10)',
  },
};

const darkPaletteOptions: PaletteOptions = {
  mode: 'dark',
  texts: {
    primary: '#E5E5E6',
    secondary: '#878C92',
    tertiary: '#CBCBCD',
    quaternary: '#929EAA',
    accentPrimary: '#66BCFF',
    accentSecondary: '#52A2E0',
    onAccentPrimary: '#FFFFFF',
    onAccentSecondary: '#DAE9F6',
    titlePrimary: '#E5E5E6',
    disabled: '#6D7378',
    placeHolder: '#788087',
    placeHolderSecondary: 'rgba(125, 140, 155, 0.7)',
    teams: '#7B82EA',
  },
  backgrounds: {
    primary: '#424242',
    secondary: '#2D3237',
    tertiary: '#36383A',
    quaternary: 'rgba(80, 89, 98, 0.3)',
    overlaidComponentsPrimary: '#313335',
    overlaidComponentsSesondary: '#252628',
    overlaidComponentsTertiary: '#191A1A',
    overlay: 'rgba(0, 0, 0, 0.5)',
    temp: '#272E35',
    emptyStateBackground: '#2D3237',
  },
  buttons: {
    accentPrimary: '#1784E8',
    accentSecondary: '#3D9DF5',
    accentTertiary: '#0B77C9',
    primary: '#3C4753',
    secondary: '#343D47',
    tertiary: '#333B42',
    disabled: '#474C52',
    dangerPrimary: 'rgba(251, 106, 118, 0.1)',
    dangerSecondary: 'rgba(251, 106, 118, 0.15)',
  },
  tooltips: {
    primary: '#23282F',
    secondary: '#0B77C9',
  },
  status: {
    defaultPrimary: '#66BCFF',
    defaultSecondary: 'rgba(102, 188, 255, 0.1)',
    successPrimary: '#70C292',
    successSecondary: 'rgba(112, 194, 146, 0.1)',
    dangerPrimary: '#FB6A76',
    dangerSecondary: 'rgba(251, 106, 118, 0.1)',
    warningPrimary: '#F0B575',
    warningSecondary: 'rgba(228, 168, 103, 0.1)',
  },
  scrollBar: {
    default: 'rgba(125, 140, 155, 0.4)',
    hover: 'rgba(125, 140, 155, 0.6)',
    pressed: 'rgba(125, 140, 155, 0.8)',
  },
  chip: {
    primary: 'rgba(23, 25, 28, 0.25)',
    secondary: 'rgba(23, 25, 28, 0.4)',
    tertiary: 'rgba(23, 25, 28, 0.6)',
    locationchipPrimary: 'rgba(11, 13, 14, 0.15)',
    userchipPrimary: 'rgba(102, 188, 255, 0.1)',
    userchipSecondary: 'rgba(102, 188, 255, 0.2)',
  },
  shadow: {
    primary: '#22262A',
    menu: 'rgba(34, 38, 42, 0.7)',
    item: '0px 0px 20px 0px rgba(11, 119, 201, 0.20)',
    tooltipPreview: '0px 35px 50px -30px rgba(34, 38, 42, 0.25)',
    tooltip: '0px 12px 10px -10px rgba(34, 38, 42, 0.80)',
    button: '0px 8px 20px -10px rgba(17, 36, 59, 0.50)',
    focus: '0px 0px 0px 2px rgba(23, 132, 232, 0.30)',
    buttonSheet:
      '0px 5px 25px 0px rgba(34, 38, 42, 0.10), 0px 0px 5px 0px rgba(34, 38, 42, 0.10), 0px 0px 60px 0px rgba(11, 119, 201, 0.05)',
    attachment:
      '0px 10px 20px 0px rgba(23, 132, 232, 0.05), 0px 5px 15px 0px rgba(17, 36, 59, 0.05), 1px 2px 5px 0px rgba(17, 36, 59, 0.15);',
    rootSectionSticky: '1px 2px 5px 0px rgba(34, 38, 42, 0.2)',
    bottomSheet:
      ' 0px 5px 25px 0px rgba(34, 38, 42, 0.40), 0px -4px 25px 0px rgba(34, 38, 42, 0.40), 0px 0px 16px 0px rgba(11, 119, 201, 0.05)',
  },
  outlinesAndStrokes: {
    primary: 'rgba(101, 102, 103, 0.3)',
    secondary: '#58595A',
    tertiary: '#717374',
    disabled: '#6D7378',
    accent: '#66BCFF',
  },
  boxshadow: {
    inputError: '0px 0px 0px 2px rgba(232, 17, 35, 0.10)',
    inputFocus: '0px 0px 0px 2px rgba(23, 132, 232, 0.10)',
  },
};

export { lightPaletteOptions, darkPaletteOptions };
