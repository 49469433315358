export enum LibraryViewScope {
  Default,
  Recursive,
  RecursiveAll,
  FilesOnly,
}

export interface OrderByInfo {
  field: string;
  dir: boolean;
}

export interface ListView {
  exportXml(rowLimit: number): string;
  combineWithFields(viewFields: string[]): ListView;
  combineWithOrderBy(orderByFields: OrderByInfo[]): ListView;
  combineWithQuery(query: string): ListView;
  combineWithScope(scope: LibraryViewScope): ListView;
  get extraFields(): string[];
  get viewFields(): string[];
  get orderBy(): OrderByInfo[];
  get isDefault(): boolean;
  get title(): string;
  get id(): string;
  get hidden(): boolean;
  get whereQuery(): string;
  get scope(): LibraryViewScope;
  get orderByOverride(): boolean;
  get paged(): boolean;
  get rowLimit(): number;
}

interface ContentTypeId {
  StringValue: string;
}

interface GridInitInfo {
  ControllerId: any;
  GridSerializer: any;
  JsInitObj: any;
}

interface ServerRelativePath {
  DecodedUrl: string;
}

export function viewScopeToString(scope: LibraryViewScope): string {
  switch (scope) {
    case LibraryViewScope.Default:
      return 'Default';
    case LibraryViewScope.Recursive:
      return 'Recursive';
    case LibraryViewScope.RecursiveAll:
      return 'RecursiveAll';
    default:
      return 'Default';
  }
}
export interface View {
  'odata.type': string;
  'odata.id': string;
  'odata.editLink': string;
  Aggregations: any;
  AggregationsStatus: any;
  AssociatedContentTypeId: any;
  BaseViewId: string;
  CalendarViewStyles: any;
  ColumnWidth: any;
  ContentTypeId: ContentTypeId;
  CustomFormatter: any;
  DefaultView: boolean;
  DefaultViewForContentType: boolean;
  EditorModified: boolean;
  Formats: any;
  GridInitInfo: GridInitInfo;
  GridLayout: any;
  Hidden: boolean;
  HtmlSchemaXml: string;
  Id: string;
  ImageUrl: string;
  IncludeRootFolder: boolean;
  ViewJoins: any;
  JSLink: string;
  ListViewXml: string;
  Method: any;
  MobileDefaultView: boolean;
  MobileView: boolean;
  ModerationType: any;
  NewDocumentTemplates: any;
  OrderedView: boolean;
  Paged: boolean;
  PersonalView: boolean;
  ViewProjectedFields: any;
  ViewQuery: string;
  ReadOnlyView: boolean;
  RequiresClientIntegration: boolean;
  RowLimit: number;
  Scope: LibraryViewScope;
  ServerRelativePath: ServerRelativePath;
  Title: string;
  [key: string]: any; // To accommodate additional properties
}
