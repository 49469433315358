import React, { useContext, useEffect, useMemo, useRef } from 'react';
import Tree, {
  DefaultNodeProps,
  DragZoneProps,
  HyperTreeNodeProps,
  useTreeState,
  HyperTreeViewProps,
  treeHandlers,
  DefaultNode,
} from 'react-hyper-tree';
import { IUseTreeState } from 'react-hyper-tree/dist/helpers/hooks';
import { IData, TreeNode } from 'react-hyper-tree/dist/helpers/node';
import { ContainerHandlers, ViewItemRenderProps } from '../ContainerTypes';
import TreeRoot from './TreeRoot';
import { ScrollbarContext } from '../Scollbar';

export type UseTreeState = IUseTreeState;
export const treeViewHandlers = treeHandlers;
export const TreeViewDefaultNode = DefaultNode;
export type TreeHandlers = ReturnType<typeof useTreeState>['handlers'];
export type TreeViewNodeProps = HyperTreeNodeProps;
export type TreeViewDragZoneProps = DragZoneProps;
export type TreeViewNode = TreeNode;
export type TreeState = ReturnType<typeof useTreeState>;
export type TreeNodeHandlers = TreeState['handlers'];

export interface TreeViewRenderNodeProps extends Omit<ViewItemRenderProps<IData>, 'handlers' | 'node'> {
  handlers: TreeHandlers & Pick<ContainerHandlers, 'isSelected' | 'isExpanded'>;
  node: TreeNode;
}

export type TreeProps = Omit<
  HyperTreeViewProps,
  'renderNode' | 'renderDragZone' | 'required' | 'handlers' | 'depth' | 'nodeHeight' | 'draggableHandlers' | 'data'
> & {
  renderNode?: (props: TreeViewRenderNodeProps) => React.ReactNode | string | null;
  renderDragZone?: (props: TreeViewDragZoneProps) => React.ReactNode | string | null;
};

export const unselectChildren = (node: TreeNode, handlers: TreeHandlers) => {
  if (node.children) node.children.forEach(n => unselectChildren(n, handlers));
};

export interface TreeViewProps {
  useTreeStateProps: UseTreeState;
  treeProps: TreeProps;
  wrapperComponent: React.ComponentType<any>;
}

export interface WrapperComponentProps {
  children: any;
  handlers: TreeHandlers;
}

export type TreeViewHandlers<T extends IData = IData> = ContainerHandlers<T> & TreeHandlers;

const TreeView = ({ treeProps, useTreeStateProps, wrapperComponent: WrapperComponent }: TreeViewProps) => {
  const treeRef = useRef<HTMLDivElement | null>(null);
  const { resizeObserver } = useContext(ScrollbarContext);
  const { required, handlers: hyperTreeHandlers } = useTreeState(useTreeStateProps);
  const handlers = useMemo<ContainerHandlers & TreeHandlers>(
    () => ({
      ...hyperTreeHandlers,
      //Not implemented yet
      hasNewItems: () => false,
      //
      isExpanded: node => (node as TreeNode).isOpened(),
      isSelected: node => (node as TreeNode).isSelected(),
      setSelected: hyperTreeHandlers.setSelected as any,
      getSelectedNodes: () => {
        const nodes: any = [];
        treeHandlers.trees[useTreeStateProps.id].instance.traverse(node => {
          if (node.isSelected()) nodes.push(node);
        });
        return nodes;
      },
      getParent: node => (node as TreeNode).getParent(),
    }),
    [hyperTreeHandlers, useTreeStateProps.id]
  );
  const renderNode = treeProps?.renderNode
    ? (props: DefaultNodeProps) => treeProps.renderNode?.({ ...props, handlers })
    : undefined;

  useEffect(() => {
    treeRef.current && resizeObserver?.observe(treeRef.current);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      treeRef.current && resizeObserver?.unobserve(treeRef.current);
    };
  }, [resizeObserver]);

  return (
    <WrapperComponent handlers={handlers}>
      <TreeRoot ref={treeRef}>
        <Tree
          {...treeProps}
          renderNode={renderNode}
          {...required}
          {...hyperTreeHandlers}
          depthGap={20}
          disableTransitions
        />
      </TreeRoot>
    </WrapperComponent>
  );
};

export default TreeView;
