/* eslint-disable @typescript-eslint/no-use-before-define */
import { XMLParser } from 'fast-xml-parser';
import { LibraryViewScope, ListView, OrderByInfo, View, viewScopeToString } from '../itemTypes/ListView';
import { DEFAULT_FIELDS } from '../sharePointAPI';

abstract class BaseListView implements ListView {
  exportXml(rowLimit: number): string {
    rowLimit = Math.min(rowLimit, this.rowLimit);
    const allFieldsExp = Array.from(new Set([...this.viewFields, ...this.extraFields])) // Ensure no duplicates
      .map(f => `<FieldRef Name="${f}"/>`)
      .join('');
    const orderByExp = this.orderBy.map(o => `<FieldRef Name="${o.field}" Ascending="${o.dir}"/>`).join('');
    const whereExp = this.whereQuery;
    let query = orderByExp ? `<OrderBy Override=\"${this.orderByOverride}\">${orderByExp}</OrderBy>` : '';
    query += whereExp ? whereExp : '';
    query += query ? `<Query>${query}</Query>` : '';
    return `<View Scope="${viewScopeToString(this.scope)}"><ViewFields>${allFieldsExp}</ViewFields>${query}<RowLimit Paged="${this.paged.toString().toUpperCase()}">${rowLimit}</RowLimit></View>`;
  }

  get extraFields(): string[] {
    return [];
  }

  abstract get scope(): LibraryViewScope;
  abstract get orderByOverride(): boolean;
  abstract get whereQuery(): string;
  abstract get orderBy(): OrderByInfo[];
  abstract get viewFields(): string[];
  abstract get paged(): boolean;
  abstract get rowLimit(): number;
  abstract get isDefault(): boolean;
  abstract get title(): string;
  abstract get id(): string;
  abstract get hidden(): boolean;

  abstract combineWithFields(inViewFields: string[]): ListView;
  abstract combineWithOrderBy(inOrderBy: OrderByInfo[]): ListView;
  abstract combineWithQuery(query: string): ListView;
  abstract combineWithScope(scope: number): ListView;
}

export class EmptyListView extends BaseListView {
  get scope(): LibraryViewScope {
    return LibraryViewScope.Default;
  }
  get orderByOverride(): boolean {
    return false;
  }

  private static FilterByType = (type: number) =>
    `<Eq><FieldRef Name="FSObjType"/><Value Type="Text">${type}</Value></Eq>`;

  static DocumentOnlyQuery = EmptyListView.FilterByType(0);
  static FolderOnlyQuery = EmptyListView.FilterByType(1);
  static LastModifiedField = 'Modified';
  static NameLinkField = 'LinkFilename';
  static OrderByName: OrderByInfo[] = [{ field: EmptyListView.NameLinkField, dir: true }];
  static OrderByLastModified: OrderByInfo[] = [{ field: EmptyListView.LastModifiedField, dir: false }];

  get whereQuery(): string {
    return '';
  }

  get orderBy(): OrderByInfo[] {
    return [];
  }

  get viewFields(): string[] {
    return [];
  }

  get paged(): boolean {
    return true;
  }
  get rowLimit(): number {
    return 30;
  }

  combineWithFields(inViewFields: string[]): ListView {
    return new SPListViewWithFields(this, inViewFields);
  }

  combineWithOrderBy(inOrderBy: OrderByInfo[]): ListView {
    return new SPListViewWithOrderBy(this, inOrderBy);
  }

  combineWithQuery(query: string): ListView {
    return new SPListViewWithQuery(this, query);
  }

  combineWithScope(scope: number): ListView {
    return new SPListViewWithScope(this, scope);
  }

  get isDefault(): boolean {
    return true;
  }

  get title(): string {
    return '';
  }

  get id() {
    return '';
  }

  get hidden() {
    return true;
  }
}

export class SPListView extends BaseListView {
  private spView: View;
  private parser: XMLParser;
  constructor(spView: View) {
    super();
    this.spView = spView;
    this.parser = new XMLParser({
      ignoreAttributes: false,
      attributeNamePrefix: '', // Ensure attributes are parsed without a prefix
    });
  }

  get scope(): LibraryViewScope {
    return this.spView.Scope;
  }

  get orderByOverride(): boolean {
    return this.parser.parse(this.spView.ViewQuery)?.OrderBy?.Override ?? 'FALSE';
  }

  get whereQuery(): string {
    const whereTagMatch = this.spView.ViewQuery.match(/<Where[\s\S]*?<\/Where>/);
    return whereTagMatch ? whereTagMatch[0] : '';
  }

  get isDefault(): boolean {
    return this.spView.DefaultView;
  }

  get title(): string {
    return this.spView.Title;
  }

  get id(): string {
    return this.spView.Id;
  }

  get hidden(): boolean {
    return this.spView.Hidden;
  }

  get paged(): boolean {
    return this.spView.Paged;
  }

  get rowLimit(): number {
    return this.spView.RowLimit;
  }

  get orderBy(): OrderByInfo[] {
    const result = this.parser.parse(this.spView.ViewQuery);
    if (result.OrderBy?.FieldRef) {
      const fieldRefs = Array.isArray(result.OrderBy.FieldRef) ? result.OrderBy.FieldRef : [result.OrderBy.FieldRef];
      return fieldRefs.map(f => ({
        field: f.Name || '',
        dir: f.Ascending !== 'FALSE', // assume true if not explicitly set to 'FALSE'
      }));
    }
    return [];
  }
  get extraFields(): string[] {
    return DEFAULT_FIELDS;
  }

  get viewFields(): string[] {
    const result = this.parser.parse(this.spView.ListViewXml);
    const fieldRefs = result.View?.ViewFields?.FieldRef;
    if (!fieldRefs) {
      return [];
    }

    const fields = Array.isArray(fieldRefs) ? fieldRefs : [fieldRefs];
    return fields.reduce((uniqueFields: string[], field) => {
      const fieldName = field.Name;
      // Exclude the 'SharedWith' field
      if (fieldName && fieldName !== 'SharedWith') {
        uniqueFields.push(fieldName);
      }
      return uniqueFields;
    }, []);
  }

  combineWithFields(inViewFields: string[]): ListView {
    return new SPListViewWithFields(this, inViewFields);
  }

  combineWithOrderBy(inOrderBy: OrderByInfo[]): ListView {
    return new SPListViewWithOrderBy(this, inOrderBy);
  }

  combineWithQuery(query: string): ListView {
    return new SPListViewWithQuery(this, query);
  }

  combineWithScope(scope: number): ListView {
    return new SPListViewWithScope(this, scope);
  }
}

class SPListViewForwarder extends EmptyListView {
  constructor(protected readonly next: ListView) {
    super();
  }

  get scope(): LibraryViewScope {
    return this.next.scope;
  }

  get orderByOverride(): boolean {
    return this.next.orderByOverride;
  }

  get orderBy() {
    return this.next.orderBy;
  }
  get viewFields() {
    return this.next.viewFields;
  }
  get extraFields() {
    return this.next.extraFields;
  }
  get whereQuery(): string {
    return this.next.whereQuery;
  }
}

class SPListViewWithFields extends SPListViewForwarder {
  constructor(
    next: ListView,
    private readonly inViewFields: string[]
  ) {
    super(next);
  }

  get viewFields() {
    return this.inViewFields;
  }
}

export class SPListViewWithOrderBy extends SPListViewForwarder {
  constructor(
    next: ListView,
    private readonly inOrderBy: OrderByInfo[]
  ) {
    super(next);
  }
  override get id() {
    return this.next.id;
  }

  override get orderBy() {
    return this.inOrderBy;
  }
}

class SPListViewWithQuery extends SPListViewForwarder {
  constructor(
    next: ListView,
    private readonly inQuery
  ) {
    super(next);
  }

  get whereQuery() {
    return `<Where>${this.inQuery}</Where>`;
  }
}

class SPListViewWithScope extends SPListViewForwarder {
  constructor(
    next: ListView,
    private readonly _scope: LibraryViewScope
  ) {
    super(next);
  }

  get scope() {
    return this._scope;
  }
}
