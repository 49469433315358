/* eslint-disable react/prop-types */
import React, { MutableRefObject, useRef, DependencyList, useCallback } from 'react';
import { MenuSmall, ViewItem, MenuMedium, ContainerHandlers, MenuActions } from '@storybook';
import { SafeCallbackStrings, strings } from '@vendor/languages';
import { VoidPromiseFunc, useSafeCallback, useSafeCallbackType } from '~/hooks/UseSafeCallback';
import { ActionRendererHookType, ActionTrigger, BaseAction } from './BaseAction';
import { ItemData } from '../itemTypes';
import { BaseActionUIWrap } from './BaseActionUIWrap';
import { createActionEvent, trackErrorEvent, trackEvent } from '../analytics/tracking';
import { BaseMenuActionClass } from './BaseMenuAction';
import { GroupAction } from './GroupeAction';

// eslint-disable-next-line @typescript-eslint/ban-types
export function wrapActionCallback<T extends Function>(
  wrapped: T,
  action: BaseAction,
  trigger: ActionTrigger,
  nodes: ViewItem<ItemData>[],
  handlers: ContainerHandlers<ItemData>,
  actionRef?: MutableRefObject<any>
): T {
  return ((...args: any[]) => {
    try {
      const res = wrapped(...args);
      const tracked = createActionEvent(action, trigger, nodes, handlers, actionRef?.current);
      trackEvent(tracked.eventName, tracked.data);
      return res;
    } catch (error: any) {
      const tracked = createActionEvent(action, trigger, nodes, handlers, actionRef?.current); // has to happen after teh callback is cakked.
      trackErrorEvent(tracked, error);
      throw error;
    }
  }) as any as T;
}

export const createUseCallbackWrap = (
  action: BaseAction,
  trigger: ActionTrigger,
  nodes: ViewItem<ItemData>[],
  useOrigHook: typeof useCallback,
  handlers: ContainerHandlers<ItemData>,
  actionContext?: MutableRefObject<any>
): typeof useCallback => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  return ((wrapped: Function, deps: DependencyList) => {
    const newWrapped = wrapActionCallback(wrapped, action, trigger, nodes, handlers, actionContext);
    return useOrigHook(newWrapped, [...deps, nodes, trigger]);
  }) as typeof useCallback;
};

export function wrapAsyncActionCallback<T extends VoidPromiseFunc>(
  wrapped: T,
  action: BaseAction,
  trigger: ActionTrigger,
  nodes: ViewItem<ItemData>[],
  handlers: ContainerHandlers<ItemData>,
  actionRef?: MutableRefObject<any>
): T {
  return (async (...args: any[]) => {
    try {
      const res = await wrapped(...args);
      const tracked = createActionEvent(action, trigger, nodes, handlers, actionRef?.current);
      trackEvent(tracked.eventName, tracked.data);
      return res;
    } catch (error: any) {
      const tracked = createActionEvent(action, trigger, nodes, handlers, actionRef?.current); // has to happen after teh callback is cakked.
      trackErrorEvent(tracked, error);
      throw error;
    }
  }) as T;
}

export const createSafe2CallbackWrap = (
  action: BaseAction,
  trigger: ActionTrigger,
  nodes: ViewItem<ItemData>[],
  useOrigHook: useSafeCallbackType,
  handlers: ContainerHandlers<ItemData>,
  actionContext?: MutableRefObject<any>
) => {
  return (
    wrapped: VoidPromiseFunc,
    deps: DependencyList,
    hasAction: boolean,
    messages?: Partial<SafeCallbackStrings>,
    isEnabled?: () => boolean,
    withUploadInicator?: () => void,
    notificationsIcon?: React.ReactNode
  ) => {
    const newWrapped = wrapAsyncActionCallback(wrapped, action, trigger, nodes, handlers, actionContext);
    return useOrigHook(
      newWrapped,
      [...deps, nodes, trigger],
      hasAction,
      messages,
      isEnabled,
      withUploadInicator,
      notificationsIcon
    );
  };
};

interface RenderActionByHookProps
  extends Omit<Parameters<NonNullable<ActionRendererHookType>>[0], 'useCallback' | 'useSafeCallback' | 'actionRef'> {
  trigger: ActionTrigger;
}

export const RenderActionByHook: React.FC<RenderActionByHookProps> = ({ trigger: trigger, nodes, ...props }) => {
  const actionRef = useRef<any>();
  const safeCallbackWrap = createSafe2CallbackWrap(
    props.action,
    trigger,
    nodes,
    useSafeCallback,
    props.handlers,
    actionRef
  ) as useSafeCallbackType;
  const callbackWrap = createUseCallbackWrap(props.action, trigger, nodes, useCallback, props.handlers, actionRef);
  const rendererResults = props.action!.useRenderer!({
    actionRef,
    useCallback: callbackWrap as typeof useCallback,
    useSafeCallback: safeCallbackWrap,
    trigger,
    nodes,
    ...props,
  });
  if (!rendererResults) return null;
  const { SideUI, actionVariant, ...rest } = rendererResults;
  return (
    <div>
      <BaseActionUIWrap BaseActionVariant={actionVariant || props.actionVariant} {...rest} node={nodes[0]} />
      {SideUI}
    </div>
  );
};

export const generateActionMenu = (
  actions: BaseAction[],
  nodes: Array<ViewItem<ItemData>>,
  trigger: ActionTrigger,
  handlers: ContainerHandlers<ItemData>
) => {
  const build = actions.flatMap((a, ind) => {
    if (a instanceof BaseMenuActionClass) {
      const children = generateActionMenu(a.actions || [], nodes, trigger, handlers);
      const Icon = Array.isArray(a.icon) ? a.icon[1] : a.icon;
      const renderIconElement = typeof Icon === 'function' ? <Icon /> : Icon;
      return children?.length
        ? {
            type: 'item',
            title: a.title,
            Icon: renderIconElement,
            children,
          }
        : undefined;
    }
    if (a instanceof GroupAction) {
      const groupItems = generateActionMenu(a.actions || [], nodes, trigger, handlers);
      return groupItems.length > 0 ? [{ type: 'divider' }, ...groupItems, { type: 'divider' }] : undefined;
    }
    return {
      render: (
        <RenderActionByHook
          key={`action-${a.constructor.name}-${ind}`}
          trigger={trigger}
          action={a}
          nodes={nodes}
          actionVariant={MenuActions}
          handlers={handlers}
        />
      ),
    };
  });

  return build.reduce((menuActions, action, index) => {
    if (!action) return menuActions;
    if (action.type === 'divider') {
      const lastItem = menuActions[menuActions.length - 1];
      if (lastItem && lastItem?.type !== 'divider' && build.length !== index + 1) {
        menuActions.push(action);
      }
    } else {
      menuActions.push(action);
    }
    return menuActions;
  }, []);
};

export class ContextMenuAction extends BaseMenuActionClass {
  readonly title = strings.lang.actionToolTip.moreActions;
  readonly isContextMenu = true;
  readonly icon = [MenuSmall, MenuMedium];

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return !nodes[0].data.isVirtual && nodes.length === 1;
  }
}
