import { ShowNotificationFunction } from '@harmon.ie-ce/storybook/src/contexts/NotificationsContext';
import { Recipient } from '@microsoft/microsoft-graph-types';
import { useGraphClient } from '@services';
import { ErrorSmall, IndicatorRequestHandler } from '@storybook';
import { strings } from '@vendor/languages';
import React from 'react';
import { UploadStep } from '~/context/UploadContext';
import { ehm_black_list } from '~/modules/Settings/SettingContext';
import { SPDocument, verifyRecipient } from '~/utilities';
import { ItemProperties, manageProperties } from '~/utilities/manageProperties/ManageProperties';
import { EmailUploadProvider } from '~/utilities/uploadItem/EmailUploadProvider';
import { UploadItemProvider } from '~/utilities/uploadItem/UploadItemProvider';

export const useEmailHeaderMapping = (): UploadStep => {
  const gcl = useGraphClient();
  const execute = async (
    uploadItemProvider: UploadItemProvider,
    _index: number,
    _actionsRef: any,
    _isMultipleUploads: boolean,
    _progressHandler?: IndicatorRequestHandler,
    showNotification?: ShowNotificationFunction
  ) => {
    const newItem = uploadItemProvider.getNewItem();
    if (
      uploadItemProvider instanceof EmailUploadProvider &&
      newItem &&
      uploadItemProvider.uploadItemProps &&
      !ehm_black_list.value.includes(uploadItemProvider.itemContainer.apiId)
    ) {
      const contentTypes = await uploadItemProvider.itemContainer.getContentTypes(gcl);
      const isEmailContentTypeExist = contentTypes.value.find(
        (contentType: any) => contentType.Name === 'Email' || contentType.Name === 'E-mail'
      );

      if (isEmailContentTypeExist) {
        try {
          await manageProperties(newItem as SPDocument, gcl, {
            contentType: contentTypes,
            date: uploadItemProvider.uploadItemProps.emailFields?.sentDateTime,
            from: verifyRecipient([uploadItemProvider.uploadItemProps.emailFields?.sender] as Recipient[]),
            to: verifyRecipient(uploadItemProvider.uploadItemProps.emailFields?.toRecipients as unknown as Recipient[]),
            subject: uploadItemProvider.uploadItemProps.emailFields?.subject,
            cc: verifyRecipient(uploadItemProvider.uploadItemProps.emailFields?.ccRecipients as unknown as Recipient[]),
            hasAttachments: uploadItemProvider.uploadItemProps.emailFields?.hasAttachments,
          } as ItemProperties);
        } catch (e: any) {
          showNotification &&
            showNotification(
              strings.lang.emailHeaderMapping.errorTitle,
              undefined,
              undefined,
              'indicator-still-uploading',
              <ErrorSmall />
            );
        }
      }
    }
  };
  const clean = () => {};
  return { clean: clean, execute: execute };
};
