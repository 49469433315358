import { GraphClient } from '@services';
import { logError } from '@vendor/utils/misc';
import { BaseAction } from './BaseAction';
import { BaseMenuActionClass } from './BaseMenuAction';
import { ItemContainer } from '../itemTypes';

export abstract class DynamicMenuActionClass extends BaseMenuActionClass {
  private isInitialized = false;
  private cachedActions: BaseAction[] = [];

  protected location: ItemContainer;
  protected gcl: GraphClient;
  constructor(gcl: GraphClient, location: ItemContainer) {
    super([]);
    this.gcl = gcl;
    this.location = location;
    this.init();
  }

  abstract getDynamicActions(refresh?: boolean): Promise<BaseAction[]>;

  async init(refresh?: boolean): Promise<void> {
    if (!this.isInitialized || refresh) {
      try {
        this.cachedActions = await this.getDynamicActions(refresh);
        this.actions = this.cachedActions;
        this.isInitialized = true;
      } catch (e) {
        logError(e);
      }
    }
  }
}
