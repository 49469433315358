import React from 'react';
import { Rating, useTheme, Box } from '@mui/material';

interface RatingCellProps {
  value: number;
  isSelected: boolean;
}

export const RatingCell: React.FC<RatingCellProps> = ({ value, isSelected }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center', // Centers the rating vertically
        justifyContent: 'center', // Centers horizontally (optional)
        height: '100%', // Ensures the container takes full height of its parent
      }}
    >
      <Rating
        defaultValue={value}
        readOnly
        precision={0.5}
        size="small"
        sx={{
          '& .MuiRating-iconEmpty': {
            color: isSelected ? theme.palette.texts.onAccentPrimary : '',
          },
        }}
      />
    </Box>
  );
};
