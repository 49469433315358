import * as React from 'react';
import Menu from '@mui/material/Menu';
import { Divider, ListItemText, MenuItem, PopoverPosition } from '@mui/material';
import { animated, useTransition } from '@react-spring/web';
import { findMaxArrayLength, findParentWithClass } from '@vendor/utils/misc';
import { ArrowRightMedium, BackMedium } from '../../assets/icons';
import { MenuItemIcon } from '../Menu';

export interface ContextMenuInterface {
  handleClose?: () => void;
  handleContextMenu?: (event: React.MouseEvent, actions: MenuAction[], onClose?: () => void) => void;
  open?: boolean;
}

export const ContextMenuContext = React.createContext<ContextMenuInterface>({});

type OnClose = undefined | (() => void);

export type MenuAction = {
  type?: 'item' | 'divider';
  title?: string;
  render?: React.JSX.Element;
  className?: string;
  Icon?: React.ReactNode;
  shortCut?: React.ReactNode;
  children?: Array<MenuAction>;
  keepOpenOnSelection?: boolean;
};

const AnimatedMenuItem = animated(MenuItem);

const ContextMenu: React.FC<React.PropsWithChildren> = ({ children }) => {
  const onClose = React.useRef<OnClose>(undefined);
  const [menuStack, setMenuStack] = React.useState<MenuAction[][]>([]);
  const [menuItems, setMenuItems] = React.useState<MenuAction[]>([]);
  const [minHeight, setMinHeight] = React.useState<number>(0);
  const [innerClass, setInnerClass] = React.useState<'onTop' | 'onBottom'>('onTop');
  const [anchorPosition, setAnchorPosition] = React.useState<PopoverPosition | undefined>(undefined);
  const [open, setOpen] = React.useState<boolean>(false);

  const handleContextMenu = (event: React.MouseEvent, actions: MenuAction[], onCloseFun?: OnClose) => {
    event.preventDefault();
    const navigationItem = findParentWithClass(event, 'navigationItem', 5);
    const target = (navigationItem || event.target)['getBoundingClientRect']?.();
    const maxMenuHeight = findMaxArrayLength(actions);

    const isTop = window.innerHeight >= maxMenuHeight + event.clientY;
    const elementPosition = isTop ? target.bottom + 10 : target.top - 10;
    const verticalPositions = elementPosition;
    setMenuStack([]);
    setOpen(true);
    setMenuItems(actions);
    setMinHeight(maxMenuHeight);
    setInnerClass(isTop ? 'onTop' : 'onBottom');
    setAnchorPosition(
      isTop
        ? { top: verticalPositions, left: event.clientX }
        : { top: verticalPositions - maxMenuHeight, left: event.clientX }
    );
    if (onCloseFun) onClose.current = onCloseFun;
  };

  const handleClose = () => {
    onClose.current?.();
    onClose.current = undefined;
    setMenuStack([]);
    setMenuItems([]);
    setAnchorPosition(undefined);
    setOpen(false);
  };

  const transitions = useTransition(menuItems, {
    from: () => ({
      opacity: 0,
      transform: `translateX(${menuStack.length ? 5 : -5}0%)`,
      timeFrame: 0.1,
    }),
    enter: { position: 'relative', opacity: 1, transform: `translateX(0px)` },
    leave: () => ({
      position: 'absolute',
      opacity: 0,
      immediate: true,
      transform: `translateX(${menuStack.length ? -5 : 5}0%)`,
    }),
    keys: menuItems.map((item, index) => `${item.title}-${index}-${menuStack.length}`),
    delay: 0,
    timeFrame: 0.1,
  });

  const handleClick = (item: MenuAction, e: React.MouseEvent) => {
    e['contextmenu'] = true;
    if (item.children && item.children.length > 0) {
      setMenuStack([...menuStack, menuItems]);
      setMenuItems(item.children);
    } else {
      if (!item.keepOpenOnSelection) {
        setAnchorPosition(undefined);
        setOpen(false);
        onClose.current?.();
      }
    }
  };

  const handleBack = (e: React.MouseEvent) => {
    e['contextmenu'] = true;
    const previousMenu = menuStack.pop();
    if (previousMenu) {
      setMenuItems(previousMenu);
    }
  };

  return (
    <ContextMenuContext.Provider value={{ handleContextMenu, open, handleClose }}>
      {/* Render the children components */}
      {children}

      {/* Menu Component */}
      <Menu
        marginThreshold={null}
        autoFocus={false}
        variant="menu"
        keepMounted={true}
        sx={{ width: '100%', maxHeight: '500px' }}
        onClose={handleClose}
        slotProps={{
          paper: { sx: { minHeight } },
        }}
        className={innerClass}
        anchorPosition={anchorPosition}
        anchorOrigin={{ vertical: 0, horizontal: 0 }}
        anchorReference="anchorPosition"
        open={open}
      >
        {/* Conditionally render 'Back' menu item and a divider if menuStack has items */}
        {menuStack.length > 0 && [
          <MenuItem key="back" onClick={handleBack}>
            <MenuItemIcon>
              <BackMedium />
            </MenuItemIcon>
            <ListItemText>Back</ListItemText>
          </MenuItem>,
          <Divider key="divider" />,
        ]}

        {/* Render transitions based on action types */}
        {transitions((styles, action) => {
          // If action has a render function, use it within an animated div
          if (action.render) {
            return (
              <animated.div key={`${action.title}-render`} onClick={e => handleClick(action, e)} style={styles}>
                {action.render}
              </animated.div>
            );
          }

          // If action is of type 'item', render an animated menu item
          if (action.type === 'item') {
            return (
              <AnimatedMenuItem
                key={`${action.title}-item`}
                style={styles}
                onClick={e => handleClick(action, e)}
                className={action.className}
              >
                {/* Optional icon rendering */}
                {action.Icon && <MenuItemIcon>{action.Icon}</MenuItemIcon>}
                <ListItemText
                  primaryTypographyProps={{
                    variant: 'H2Medium',
                  }}
                >
                  {action.title}
                </ListItemText>
                {/* Render shortcut if available */}
                {action.shortCut && action.shortCut}
                {/* Arrow icon if there are child actions */}
                {!!action.children?.length && <ArrowRightMedium />}
              </AnimatedMenuItem>
            );
          }

          // Otherwise, render a divider within an animated list item
          return (
            <animated.li key={`${action.title}-divider`} style={styles}>
              <Divider />
            </animated.li>
          );
        })}
      </Menu>
    </ContextMenuContext.Provider>
  );
};

export default ContextMenu;
