import { AllowedItemType } from '../itemTypes';
import { SPMetadata } from '../sharePointTypes';

// We will always upgrade from no version to '1.0'
// Future upgrades will upgrade from '1.0' to (say) '2.0' & will let this function upgrade from no version to '1.0'
export const noVersionTo1st = (oldData: any) => {
  const nextVersion = 1.0;
  if (oldData.version) return oldData;
  switch (oldData.type as AllowedItemType) {
    case 'document':
    case 'folder':
      const {
        user,
        fileSize,
        date,
        uniqueId,
        listItemId,
        serverRelativeUrl,
        relativeUrl,
        name,
        contentTypeId,
        ...rest
      } = oldData;
      return {
        ID: listItemId,
        ContentTypeId: contentTypeId,
        FileRef: relativeUrl || serverRelativeUrl,
        FileLeafRef: name,
        UniqueId: uniqueId,
        Modified: new Date(date || 0), // Parse the date string into a Date object
        File_x0020_Size: fileSize || 0,
        user: user || null, // Map user if available
        version: nextVersion,
        ...rest,
      } as SPMetadata;
  }
  return { ...oldData, version: nextVersion };
};
