import React, { MutableRefObject, useCallback } from 'react';
import { BAVariant, ContainerHandlers, ViewItem } from '@storybook';
import { useSafeCallbackType } from '~/hooks/UseSafeCallback';
import { ItemData, MicrosoftApps } from '../itemTypes/ItemData';
import { BaseActionUIWrapProps } from './BaseActionUIWrap';

interface ExtraRendererHookProps {
  SideUI?: React.JSX.Element;
  actionVariant?: BAVariant;
}

export type TrackedEventName =
  | 'Group'
  | 'Menu'
  | 'SidebarInit'
  | 'SidebarError'
  | 'Feedback'
  | 'ManageSites'
  | 'ManageTeams'
  | 'ManageItemCount'
  | 'GotoSettings'
  | 'SignIn'
  | 'Navigate'
  | 'NavigationHistory'
  | 'DeleteItems'
  | 'AddItems'
  | 'DeleteItems'
  | 'AddItems'
  | 'SaveEmailButton'
  | 'UploadTransaction'
  | 'CopyLink'
  | 'EmailWithLink'
  | 'EmailWithAttachment'
  | 'ShareToTeams'
  | 'RefreshItem'
  | 'Download'
  | 'EditOnSharepoint'
  | 'OpenInApp'
  | 'SearchAllLocations'
  | 'Rename'
  | 'OpenDocument'
  | 'OpenContainingLocation'
  | 'CopyAddress'
  | 'Sort'
  | 'GetSupport'
  | 'Auto Email Header Mapping'
  | 'ChangeView'
  | 'DetailsView'
  | 'SimpleView';

export type ActionItemType =
  | 'None'
  | 'Site'
  | 'Library'
  | 'OneDrive'
  | 'Custom List'
  | 'Document Set'
  | 'Folder'
  | 'Document'
  | 'Custom List Item'
  | 'Shared Channel'
  | 'Private Channel'
  | 'Channel'
  | 'Team'
  | 'Group'
  | 'Location Url'
  | 'Favorite'
  | 'Followed Site'
  | 'Registered Site'
  | 'Group By Item'
  | 'Virtual Item'
  | 'Home Location'
  | 'Outlook'
  | 'View'
  | 'View Library'
  | 'Favorite'
  | 'Favorites List'
  | 'Teams List'
  | 'Sites List'
  | 'Recent Items'
  | 'User Setting'
  | 'History Item'
  | 'People Tab'
  | 'Favorites Folder'
  | 'License File';

export type ActionTrigger =
  | 'Quick Action'
  | 'Context Menu'
  | '3Dots'
  | 'Double Click'
  | 'Shortcut'
  | 'Empty State'
  | 'Breadcrumbs'
  | 'Other';

export interface TrackedEventType {
  data: {
    trigger: ActionTrigger;
    itemsCount?: number;
    targetApp?: MicrosoftApps;
    targetView?: string;
    itemType: ActionItemType;
    actionType: BaseActionType;
    rootType?: ActionItemType;
  };
  eventName: TrackedEventName;
}

type BaseActionType = 'Read' | 'Update' | 'Create' | 'Delete';

export abstract class BaseAction {
  protected constructor(readonly type: BaseActionType) {}

  abstract get trackedName(): TrackedEventName;

  isQuickAction?(data: ItemData, isTopBarItem: boolean): string | undefined;
  abstract isHandled(nodes: ViewItem<ItemData>[]): boolean;
  transformEvent?({ data, eventName }: TrackedEventType, _ctx?: any): TrackedEventType;
  isDirty(): boolean {
    return false;
  }
  readonly useRenderer: (props: {
    useSafeCallback: useSafeCallbackType;
    useCallback: typeof useCallback;
    nodes: ViewItem<ItemData>[];
    actionVariant: BAVariant;
    handlers: ContainerHandlers<ItemData>;
    trigger: ActionTrigger;
    action: BaseAction;
    actionRef: MutableRefObject<any>;
  }) => (Omit<BaseActionUIWrapProps, 'BaseActionVariant' | 'node'> & ExtraRendererHookProps) | null;
}

export type ActionRendererHookType = BaseAction['useRenderer'];

export abstract class BaseReadActionClass extends BaseAction {
  constructor() {
    super('Read');
  }
}

export abstract class BaseUpdateActionClass extends BaseAction {
  constructor() {
    super('Update');
  }
}

export abstract class BaseAddActionClass extends BaseAction {
  readonly trackedName: TrackedEventName = 'AddItems';

  constructor() {
    super('Create');
  }
}

export abstract class BaseDeletActionClass extends BaseAction {
  readonly trackedName = 'DeleteItems';
  constructor() {
    super('Delete');
  }
}

export interface ActionComponentProps<nodeType = ViewItem<ItemData>> {
  title: string;
  node: nodeType;
  isDrilldown?: boolean;
  BaseActionVariant: BAVariant;
}

export interface ActionComponentWithHandlersProps<nodeType = ViewItem<ItemData>>
  extends ActionComponentProps<nodeType> {
  handlers?: ContainerHandlers<ItemData>;
}

//
