import { createTheme, ThemeOptions } from '@mui/material/styles';
import { darkPaletteOptions, lightPaletteOptions } from './palette';
import typography from './typography';
import overrides from './overrides';

const defaultTheme = createTheme();

export default (themeMode = 'light') => {
  const baseTheme: ThemeOptions = {
    typography,
    palette: themeMode === 'light' ? lightPaletteOptions : darkPaletteOptions,
    spacing: 5,
    // @ts-ignore
    shadows: [
      'none',
      '0px 5px 20px rgba(132, 158, 189, 0.15), 1px 2px 5px rgba(132, 158, 189, 0.3)',
      ...defaultTheme.shadows.slice(2),
    ],
  };

  const base = createTheme(baseTheme);

  return createTheme(baseTheme, {
    components: {
      ...overrides(base),
      MuiDataGrid: {
        styleOverrides: {
          root: {
            ['--DataGrid-pinnedBackground']: `transperant !important`,
            ['--DataGrid-containerBackground']: ' transperant !important',
            ['--DataGrid-rowBorderColor']: 'transperant !important',
          },
        },
      },
    },
  });
};
