import React from 'react';
import { useTheme } from '@mui/material';
import { LocationLabelTooltip } from '../../../Tooltip';
import { Stack } from '../../../Stack';
import { Typography } from '../../../Typography';

interface CustomHeaderCellProps {
  Icon?: React.ReactNode;
  headerCellName: string;
}

export const CustomHeaderCell = ({ headerCellName, Icon }: CustomHeaderCellProps) => {
  const theme = useTheme();
  return (
    <>
      {Icon !== undefined ? (
        <Stack alignItems={'center'} width={'100%'}>
          {Icon}
        </Stack>
      ) : (
        <LocationLabelTooltip title={headerCellName && headerCellName.length > 15 && headerCellName}>
          <Stack
            sx={{
              color: theme.palette.texts.quaternary,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              cursor: 'default',
              width: '100%',
            }}
          >
            <Typography
              variant={'H2Medium'}
              noWrap
              sx={{
                pointerEvents: 'none',
                display: 'inline-block',
                overflow: 'hidden',
                position: 'relative',
                flexShrink: 0,
                width: '100%',
                height: '100%',
                paddingLeft: 0,
                color: `${theme.palette.texts.secondary}`,
              }}
            >
              {headerCellName}
            </Typography>
          </Stack>
        </LocationLabelTooltip>
      )}
    </>
  );
};
