import { useCallback } from 'react';
import { useGraphClient } from '@services';
import { useGetChildren } from '~/hooks';
import { RefreshAction, StateManager } from '~/utilities';
import { useSubscribe } from '~/hooks/useSubscribe';
import { TreeNodeRenderer } from './GetNodeRenderer';

export const renderWithReload = <T,>(
  stateManager: () => StateManager<T>,
  NextRenderer: TreeNodeRenderer
): TreeNodeRenderer => {
  return props => {
    const gcl = useGraphClient();
    const { node, handlers } = props;
    const getChildren = useGetChildren();
    useSubscribe(
      stateManager().sKey,
      useCallback(
        async () => RefreshAction.refresh(gcl, node, handlers, getChildren, true, 0),
        [gcl, getChildren, handlers, node]
      )
    );

    return NextRenderer && NextRenderer(props);
  };
};
