import { EllipseSelected, Typography } from '@harmon.ie-ce/storybook';
import { FormControlLabel, styled } from '@mui/material';
import React from 'react';
import MuiCheckbox from '@mui/material/Checkbox';

export const Ellipse = styled(EllipseSelected)`
  g rect {
    fill: ${({ theme }) => theme.palette.outlinesAndStrokes.primary};
  }

  g circle {
    fill: ${({ theme }) => theme.palette.backgrounds.overlaidComponentsPrimary};
    r: 7;
  }
`;

export const SortCheckbox = styled(MuiCheckbox)`
  margin-left: ${({ theme }) => theme.spacing(3)};
  margin-right: ${({ theme }) => theme.spacing(1)};
  margin-top: 3px;
  & g {
    filter: none;
  }
  &:hover {
    background-color: transparent;
  }
`;
export interface OrderByInfo {
  field: string;
  dir: boolean;
}

export interface SortActiongProps {
  actionName: string;
  itemValue: OrderByInfo;
  onSelect: (value: OrderByInfo) => void;
  checked: boolean;
}

export const SortAction = ({ actionName, itemValue, onSelect, checked }: SortActiongProps) => {
  return (
    <FormControlLabel
      sx={{ width: '100%' }}
      label={
        <Typography variant="H2Regular" noWrap>
          {actionName}
        </Typography>
      }
      control={
        <SortCheckbox
          checked={checked}
          onClick={() => onSelect(itemValue)}
          value={itemValue.field}
          icon={<Ellipse />}
          checkedIcon={<EllipseSelected />}
        />
      }
    />
  );
};
