import { logError } from '@vendor/utils/misc';
import { XMLParser } from 'fast-xml-parser';
import { GraphClient } from '../../../../services/src/networkAndCache';
import { ItemContainer } from '../itemTypes';
import { SharePointClient } from '../sharePointAPI';
import { SPDocument } from '../sharePointTypes';
import { formatDateTime } from '../actions/ActionsUtils';

export interface ViewCreationParameters {
  title: string;
  rowLimit: number;
  viewFields: string[];
  query?: string;
  viewType?: string;
}

// Define interfaces for the properties
export interface ItemProperties {
  contentType: any;
  to: string;
  cc: string;
  from: string;
  subject: string;
  date: string;
  hasAttachments?: string;
}

export interface EmailHeaderMapping {
  shouldMap: boolean;
  fieldMappings: { [key: string]: string };
  contentTypeId?: string; // Include ContentTypeId in the interface
}

export interface UpdatePropertiesPayload {
  formValues: Array<{
    FieldName: string;
    FieldValue: string | boolean;
    HasException?: boolean;
  }>;
  bNewDocumentUpdate?: boolean;
  checkInComment?: string | null;
  datesInUTC: boolean;
}
export const possibleFieldMappings: { [key: string]: string[] } = {
  Cc: ['hrmV2CC', 'hrmV2Cc', 'hrmCc', 'hrmCcOWSMTXT', 'EmailCc', 'Cc', 'MailCc'],
  Attachments: [
    'hrmV2HasAttachments',
    'hrmHasAttachments',
    'hrmHasAttachmentsOWSTEXT',
    'Attachments',
    'hrmAttachments',
    'Attachment',
    'MailAttachments',
    'HasAttachments',
    'WithAttachments',
  ],
  From: ['hrmV2From', 'hrmFrom', 'hrmFromOWSTEXT', 'EmailFrom', 'EMailSender', 'From', 'From1', 'MailFrom', 'Sender'],
  Received: [
    'hrmV2Received',
    'hrmV2Recieved',
    'hrmReceived',
    'hrmReceivedOWSTEXT',
    'EmailReceived',
    'MailDate',
    'Received',
  ],
  Subject: [
    'hrmV2Subject',
    'q_TEXT_Subject',
    'hrmSubject',
    'hrmSubjectOWSTEXT',
    'EmailSubject',
    'Subject',
    'MailSubject',
  ],
  To: ['hrmV2To', 'hrmTo', 'hrmToOWSMTXT', 'EmailTo', 'To', 'MailTo'],
};

async function extractFieldNames(xml: string): Promise<{ [key: string]: string }> {
  try {
    const parser = new XMLParser({
      ignoreAttributes: false,
      attributeNamePrefix: '', // Ensure attributes are parsed without a prefix
    });
    const result = parser.parse(xml);

    const fields = result.ContentType?.Fields?.Field;
    if (!fields) {
      throw new Error('Fields not found in the parsed XML.');
    }

    const extractedFields: { [key: string]: string } = {};

    const fieldsArray = Array.isArray(fields) ? fields : [fields];
    for (const field of fieldsArray) {
      if (field && field.StaticName) {
        const staticNameLower = field.StaticName.toLowerCase();
        for (const [key, possibleNames] of Object.entries(possibleFieldMappings)) {
          if (
            possibleNames.map(name => name.toLowerCase()).includes(staticNameLower) &&
            extractedFields[key] === undefined
          ) {
            extractedFields[key] = field.StaticName;
            break;
          }
        }
      }
    }

    return extractedFields;
  } catch (error: any) {
    logError('Error parsing XML:', error);
    throw error;
  }
}

// Function to get content types from SharePoint
export async function getContentTypes(gcl: GraphClient, itemContainer: ItemContainer) {
  return await itemContainer.getContentTypes(gcl);
}

async function getEmailHeaderMapping(contentTypes: any): Promise<EmailHeaderMapping> {
  const emailContentType = contentTypes.value.find(
    (contentType: any) => contentType.Name === 'Email' || contentType.Name === 'E-mail'
  );
  if (emailContentType) {
    const xml = emailContentType.SchemaXml;
    const fieldMappings = await extractFieldNames(xml);
    const contentTypeId = emailContentType?.Id?.StringValue;
    return {
      shouldMap: true,
      fieldMappings,
      contentTypeId, // Include ContentTypeId if found
    };
  } else {
    return { shouldMap: false, fieldMappings: {} };
  }
}

// Function to prepare email header fields for updating using dynamic field names from XML
function prepareEmailHeaderFields(
  emailHeaders: ItemProperties,
  fieldMappings: { [key: string]: string },
  contentTypeId?: string
): UpdatePropertiesPayload {
  const formValues: UpdatePropertiesPayload['formValues'] = [];
  // Define a mapping of email header properties to their corresponding values in emailHeaders
  const possibleMappings = {
    To: emailHeaders.to,
    Cc: emailHeaders.cc,
    From: emailHeaders.from,
    Subject: emailHeaders.subject,
    Attachments: emailHeaders.hasAttachments ? 'True' : 'False',
    Received: formatDateTime(emailHeaders.date, true), // Use the custom format for date mapping
  };

  // Iterate over the possibleMappings to create the formValues array dynamically
  for (const [key, value] of Object.entries(possibleMappings)) {
    // Check if fieldMappings contains a mapping for the current key and that the value is not undefined or empty
    if (fieldMappings[key] && value) {
      formValues.push({
        FieldName: fieldMappings[key], // Use the field name from the XML mappings
        FieldValue: value,
        HasException: false,
      });
    }
  }

  // Include the ContentTypeId if it exists and is not empty
  if (contentTypeId) {
    formValues.push({
      FieldName: 'ContentTypeId', // Use 'ContentTypeId' as the field name
      FieldValue: contentTypeId,
      HasException: false,
    });
  }

  return {
    bNewDocumentUpdate: false,
    checkInComment: null,
    datesInUTC: true,
    formValues,
  };
}

// Function to update properties in SharePoint
async function updateProperties(
  item: SPDocument,
  spc: SharePointClient,
  payload: UpdatePropertiesPayload
): Promise<void> {
  await spc.api(`${item.parent.list.apiId}/items(${item.metadata.ID})/ValidateUpdateListItem()`).post(payload);
}

// Main function to manage properties for a given document
export const manageProperties = async (item: SPDocument, gcl: GraphClient, content: ItemProperties): Promise<void> => {
  const spc = new SharePointClient(gcl, item.rootSite);
  // Extract email header mapping and prepare the payload for update
  const emailHeaderMapping = await getEmailHeaderMapping(content.contentType);
  if (emailHeaderMapping.shouldMap) {
    const payload = prepareEmailHeaderFields(
      content,
      emailHeaderMapping.fieldMappings,
      emailHeaderMapping.contentTypeId
    );
    await updateProperties(item, spc, payload);
  }
};
