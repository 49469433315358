import React, { useCallback, useContext } from 'react';
import { ContextMenuContext, MouseEventsInterface, MouseEventsContext, MenuAction, SVGComponent } from '@storybook';
import { ActionRendererHookType, BaseAction, BaseReadActionClass } from './BaseAction';
import { generateActionMenu } from './ContextMenuAction';

const useBaseMenuAction: ActionRendererHookType = ({ trigger, action, nodes, handlers }) => {
  const node = nodes[0];
  const menuAction = action as BaseMenuActionClass;
  const { handleContextMenu, open } = useContext(ContextMenuContext);
  const { waitForAction, actionDone } = useContext<MouseEventsInterface>(MouseEventsContext);
  const openMenu = useCallback(
    (e: React.MouseEvent) => {
      const actions: MenuAction[] = generateActionMenu(menuAction.actions, [node], trigger, handlers);
      if (menuAction.isContextMenu) e['contextmenu'] = true;
      e['no-expand'] = true;
      e['no-open'] = true;
      if (actions.length) {
        waitForAction?.();
        handleContextMenu?.(e, actions, actionDone);
      }
    },
    [actionDone, handleContextMenu, handlers, menuAction, node, trigger, waitForAction]
  );

  return {
    'aria-controls': open ? 'basic-menu' : undefined,
    'aria-haspopup': 'true',
    'aria-expanded': open ? 'true' : undefined,
    onClick: openMenu,
    opened: open && !handlers?.isSelected(node),
    title: menuAction.title,
    icon: menuAction.icon,
  };
};

export abstract class BaseMenuActionClass extends BaseReadActionClass {
  readonly trackedName = 'Menu';
  constructor(public actions: BaseAction[]) {
    super();
  }

  public isContextMenu = false;
  abstract get title(): string;
  abstract get icon(): SVGComponent[] | SVGComponent | React.JSX.Element;

  useRenderer = useBaseMenuAction;
}
