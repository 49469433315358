import React from 'react';
import { Chip, useTheme } from '@mui/material';

interface HiddenCountChipProps {
  hiddenCount: number;
}

export const HiddenCountChip = ({ hiddenCount }: HiddenCountChipProps) => {
  const theme = useTheme();
  return (
    <Chip
      label={`+${hiddenCount}`}
      sx={{
        backgroundColor: theme.palette.chip.secondary,
        borderRadius: '8px',
        cursor: 'default',
        height: theme.spacing(5), // Set height for the chip
        marginLeft: theme.spacing(1),
        padding: theme.spacing(0.5, 0.5), // Adjust padding inside the chip
        textAlign: 'center',
        minWidth: theme.spacing(6), // Ensure a minimum width

        '&:hover': {
          backgroundColor: theme.palette.chip.tertiary,
        },
      }}
    />
  );
};
