import React, { useEffect, useRef, useState } from 'react';
import { Dialog as MuiDialog } from '@mui/material';
import { Stack } from '@mui/system';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { LottieAnimation } from '../Lottie';
import { ButtonsStack } from '../ButtonsStack';
import { Checkbox } from '../Checkbox';
import { ButtonProps } from '../Button';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  border: `1px solid ${theme.palette.outlinesAndStrokes.tertiary}`,
  borderRadius: theme.shape.borderRadius,
  maxWidth: 290,
  margin: '0 auto',
  textAlign: 'left',
}));

export interface InformativeDialogProps {
  show: boolean;
  onApprove: (checkboxState: boolean) => void;
  onCancel: (checkboxState: boolean) => void;
  BodySection: React.ReactNode;
  showCheckbox?: boolean;
  headerAnimation: any; //TODO: Update to real type
  approveLabel: string;
  rejectLabel: string;
  chackboxLabel: string;
  isLoading: boolean;
}

function InformativeDialog({
  onApprove,
  onCancel,
  showCheckbox = false,
  BodySection,
  headerAnimation,
  show,
  approveLabel,
  rejectLabel,
  chackboxLabel,
  isLoading,
}: InformativeDialogProps) {
  const [checkboxState, toggleCheckBox] = useState(false);
  const [approveButtonWidth, setApproveButtonWidth] = useState<number | null>(null);
  const approveButtonRef = useRef<HTMLButtonElement>(null);

  const onClick = () => {
    if (approveButtonRef.current) {
      const { offsetWidth } = approveButtonRef.current;
      setApproveButtonWidth(offsetWidth);
    }
    onApprove(checkboxState);
  };

  const approveReject: ButtonProps[] = [
    {
      children: rejectLabel,
      variant: 'text',
      onClick: () => onCancel(checkboxState),
    },
    {
      children: approveLabel,
      variant: 'primary',
      onClick: onClick,
      loading: isLoading,
      ref: approveButtonRef,
      sx: {
        width: approveButtonWidth ? `${approveButtonWidth}px` : 'auto',
      },
    },
  ];

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (show) {
        if (event.key === 'Enter') {
          onApprove(false);
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onApprove, show]);

  return (
    <MuiDialog
      open={show}
      onClose={(_event, reason) => {
        if (reason === 'escapeKeyDown') {
          onCancel(false);
        }
      }}
    >
      <Container>
        <Stack direction={'column'}>
          {headerAnimation && (
            <Box mb={4}>
              <LottieAnimation options={{ loop: true, autoplay: true, animationData: headerAnimation }} />
            </Box>
          )}
          <Box mb={2}>{BodySection}</Box>
          {showCheckbox && (
            <Checkbox
              value={checkboxState}
              onClick={() => toggleCheckBox(!checkboxState)}
              alignItems="left"
              className="default-checkbox left-aligned-checkbox"
              label={chackboxLabel}
            />
          )}
          <Box mt={2}>
            <ButtonsStack buttons={approveReject} />
          </Box>
        </Stack>
      </Container>
    </MuiDialog>
  );
}

export default InformativeDialog;
