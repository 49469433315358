import { IconProps, TeamMedium } from '@storybook';
import { Channel as GChannel } from '@microsoft/microsoft-graph-types';
import {
  TeamExpirationWindow,
  GValue,
  GraphChannelKeys,
  GraphClient,
  EternalWindow,
  GraphWebUrlKeys,
  GraphBatchRequest,
} from '@services';
import { getSortByName } from '@vendor/utils/misc';
import {
  AccessUrls,
  BaseContainer,
  BaseItemProps,
  FetchChildrenProps,
  ItemDataWithPaging,
  MicrosoftApps,
} from '../itemTypes';
import { Channel } from './Channel';
import { TeamsRoot } from '../virtualRoots';

export interface TeamProps extends BaseItemProps {
  description?: string | null;
  isUserManaged?: boolean;
}

export class Team extends BaseContainer {
  readonly description?: string | null;

  constructor(props: TeamProps) {
    super({ ...props, type: 'team' });
    this.description = props.description;
  }

  protected fillJson(res: TeamProps): void {
    super.fillJson(res);
    res.description = this.description;
  }

  override get isUserManaged(): boolean {
    return true;
  }
  get secondLineContent(): string | null {
    return `${MicrosoftApps.Teams}`;
  }
  override get isTeamsEntity(): boolean {
    return true;
  }
  override get canRemove(): boolean {
    return !this.isAdvancedSearchItem;
  }
  get canUndoRemove(): boolean {
    return true;
  }
  get OfficeAppLocated() {
    return MicrosoftApps.Teams;
  }

  override async fetchChildren({ gcl, refreshStamp, sortDir }: FetchChildrenProps): Promise<ItemDataWithPaging> {
    const sortByName = getSortByName(sortDir);
    const generalFirst = (a: Channel, b: Channel) => {
      if (a.name === 'General') return -1;
      if (b.name === 'General') return 1;
      return sortByName(a, b);
    };
    const items: GValue<GChannel> = await gcl
      .api(`/teams/${this.apiId}/channels`)
      .cache(TeamExpirationWindow, refreshStamp)
      .select(GraphChannelKeys)
      .get();
    return {
      items: items.value
        .map(
          channel =>
            new Channel({
              name: channel.displayName || '',
              id: channel.id || '',
              team: this,
              membershipType: channel.membershipType || 'standard',
            })
        )
        .sort(generalFirst),
    };
  }

  override async removeItem() {
    return TeamsRoot.Settings.remove(this.apiIdKey);
  }

  override async getAccessUrls(gcl: GraphClient): Promise<AccessUrls | undefined> {
    const gb = new GraphBatchRequest(gcl.client);
    const team = await gb.api(`/teams/${this.apiId}`).cache(EternalWindow).select(GraphWebUrlKeys).get();
    const library = await gb.api(`/groups/${this.apiId}/drive`).cache(EternalWindow).select(GraphWebUrlKeys).get();
    await gb.runAll();
    const libraryUrl = await library();
    const teamUrl = await team();
    return { webUrl: libraryUrl.webUrl, appUrl: teamUrl.webUrl };
  }

  override getIcon(_expanded: boolean): IconProps {
    return { icon: TeamMedium, isColorable: true };
  }
}
