// src/components/SharePointCell.tsx
import React from 'react';
import CellContentIcon from '@harmon.ie-ce/storybook/src/components/Table/components/cells/CellContentIcon';
import {
  TextCell,
  DateTimeCell,
  UserCell,
  ViewItem,
  Stack,
  LinkCell,
  RatingCell,
  ChoiceCell,
  Typography,
  useTheme,
  VMedium,
  AttachmentSmall,
} from '@storybook';
import ItemNodeContentText from '@harmon.ie-ce/storybook/src/components/NavigationItem/components/ItemNodeContentText';
import { isArray } from 'lodash';
import { ItemData } from '~/utilities';
import { possibleFieldMappings } from '~/utilities/manageProperties/ManageProperties';
import { SharePointField, SharePointFieldType } from '../../../../utilities/metadata/SharePointFieldType';
import { cellIsEmpty } from './SharePointCellHelper';

interface SharePointCellProps {
  field: SharePointField;
  item: ViewItem<ItemData>;
  isSelected: boolean;
  isTypeIcon: boolean;
  columnWidth: number;
}
// Utility function to extract text from HTML
const extractTextFromHTML = (html: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};
const SharePointCell: React.FC<SharePointCellProps> = ({ field, item, isSelected, isTypeIcon, columnWidth }) => {
  const data = item.data;
  const theme = useTheme();
  const value = data?.getProperty(field.InternalName, field.TypeAsString) || '';
  if (cellIsEmpty(field.TypeAsString, field.InternalName, value)) {
    return (
      <Typography variant="H1Regular" color={theme.palette.texts.disabled}>
        {'-'}
      </Typography>
    );
  }
  switch (field.TypeAsString) {
    case SharePointFieldType.Computed: {
      //handle special computed fields, Name && Icon
      switch (field.InternalName) {
        case 'DocIcon': {
          const IconProps = data.getIcon(false);
          const Icon = IconProps?.icon;
          return (
            <Stack direction="row">
              <CellContentIcon isColorable={IconProps?.isColorable} isSelected={isSelected} isTypeIcon={isTypeIcon}>
                {Icon && <Icon />}
              </CellContentIcon>
            </Stack>
          );
        }
        case 'FileSizeDisplay':
          return <TextCell value={value} isArray={false} columnWidth={columnWidth} />;
        case 'LinkFilename':
          return <TextCell value={data.name} isArray={false} columnWidth={columnWidth} />;
        case 'LinkFilenameNoMenu':
          return <TextCell value={data.name} isArray={false} columnWidth={columnWidth} />;
      }
    }
    case SharePointFieldType.Calculated: {
      if (field.OutputType === 4) return <DateTimeCell value={value} dataAndTime={field.DateFormat === 1} />;
      if (field.OutputType === 8) {
        if (value === 'Yes') {
          return (
            <CellContentIcon isColorable={true} isSelected={isSelected}>
              <VMedium />
            </CellContentIcon>
          );
        } else return <></>;
      } else {
        return <TextCell value={value} isArray={false} columnWidth={columnWidth} />;
      }
    }
    case SharePointFieldType.Text: {
      if (possibleFieldMappings.Attachments.includes(field.StaticName)) {
        if (value === 'True')
          return (
            <CellContentIcon isColorable={true} isSelected={isSelected}>
              <AttachmentSmall />
            </CellContentIcon>
          );
        else return <></>;
      } else if (
        possibleFieldMappings.To.includes(field.StaticName) ||
        possibleFieldMappings.Cc.includes(field.StaticName)
      ) {
        const val = value.split(',');
        return <TextCell value={val} isArray={isArray(val)} columnWidth={columnWidth} />;
      } else return <TextCell value={value} isArray={false} columnWidth={columnWidth} />;
    }
    case SharePointFieldType.Number:
      return <TextCell value={value} isArray={false} columnWidth={columnWidth} />;
    case SharePointFieldType.DateTime: {
      return <DateTimeCell value={value} dataAndTime={field.DateFormat === 1 || field.DisplayFormat === 1} />;
    }
    case SharePointFieldType.User:
      return <UserCell value={value?.[0]?.value ?? value?.[0]?.title} />;
    case SharePointFieldType.UserMulti:
      const userMultiDisplayValue = isArray(value) ? value?.map(val => val?.value || val?.title) : value;
      return <TextCell value={userMultiDisplayValue} isArray={isArray(value)} columnWidth={columnWidth} />;
    case SharePointFieldType.Lookup:
      return <TextCell value={value?.[0]?.lookupValue} isArray={false} columnWidth={columnWidth} />;
    case SharePointFieldType.LookupMulti:
      const lookupDisplayValue = isArray(value) ? value?.map(val => val?.lookupValue) : value;
      return <TextCell value={lookupDisplayValue} isArray={isArray(value)} columnWidth={columnWidth} />;
    case SharePointFieldType.Choice:
      return <ChoiceCell value={value} isArray={false} columnWidth={columnWidth} />;
    case SharePointFieldType.MultiChoice:
      return <ChoiceCell value={value} isArray={true} columnWidth={columnWidth} />;
    case SharePointFieldType.URL:
      return <LinkCell value={value} />;
    case SharePointFieldType.AverageRating:
      return <RatingCell value={value} isSelected={isSelected} />;
    case SharePointFieldType.Boolean:
      if (value === 'Yes') {
        const Icon = possibleFieldMappings.Attachments.includes(field.StaticName) ? <AttachmentSmall /> : <VMedium />;
        return (
          <CellContentIcon isColorable={true} isSelected={isSelected}>
            {Icon}
          </CellContentIcon>
        );
      } else return <></>;
    case SharePointFieldType.Note:
      // Extract and render the plain text from HTML
      const plainText = extractTextFromHTML(value);
      return <ItemNodeContentText highLightSecondLine={false}>{plainText}</ItemNodeContentText>;
    case SharePointFieldType.TaxonomyFieldType:
      return <ChoiceCell value={value.Label} isArray={false} columnWidth={columnWidth} />;
    case SharePointFieldType.TaxonomyFieldTypeMulti:
      const taxonomyDisplayValue = isArray(value) ? value.map(val => val.Label) : value;
      return <ChoiceCell value={taxonomyDisplayValue} isArray={isArray(value)} columnWidth={columnWidth} />;
    default: {
      return <ItemNodeContentText highLightSecondLine={false}>{value}</ItemNodeContentText>;
    }
  }
};

export default SharePointCell;
