import { SelectItem } from '@storybook';
import { getEmailAttachmentContent } from '../actions';
import { ItemContainer } from '../itemTypes';
import { UploadItemProvider } from './UploadItemProvider';

export class AttacmentEmailUploadProvider extends UploadItemProvider {
  constructor(gcl: any, file: SelectItem, itemContainer: ItemContainer) {
    super(gcl, file, itemContainer);
  }
  getItemName(): string {
    return (this.item as SelectItem).name;
  }
  async getItemBlob(): Promise<void> {
    const data = await getEmailAttachmentContent(this.item as SelectItem, this.gcl);
    this.uploadItemProps = {
      name: (this.item as SelectItem).name,
      data: data,
    };
  }
}
