import React from 'react';
import ItemNodeContentText from '../../../NavigationItem/components/ItemNodeContentText';

interface UserCellProps {
  value: string;
}

export const UserCell: React.FC<UserCellProps> = ({ value }) => {
  return <ItemNodeContentText highLightSecondLine={false}>{value}</ItemNodeContentText>; // Render the date in local format
};
