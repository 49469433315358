import React from 'react';
import { GridRow, GridRowProps } from '@mui/x-data-grid-premium';
import { Stack, ViewItem } from '@storybook';
import classNames from 'classnames';
import { ItemData } from '~/utilities';

export interface RowTableProps {
  rowProps: GridRowProps;
  onSelect: React.MouseEventHandler<HTMLDivElement>;
  customOnDoubleClick: React.MouseEventHandler<HTMLDivElement> | undefined;
}

export const RowTable = ({ rowProps, customOnDoubleClick, onSelect }: RowTableProps) => {
  const node = rowProps.row as ViewItem<ItemData>;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { className, onClick, onDoubleClick, onContextMenu, ...restRowProps } = rowProps;
  const isNew = node.data.isNew;
  const styledClass = classNames('navigationItem', className, { isNew });

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    switch (e.detail) {
      case 1:
        onSelect(e);
        break;
      case 2:
        customOnDoubleClick && customOnDoubleClick(e);
        break;
    }
  };

  return (
    <Stack className={styledClass}>
      <GridRow
        key={node.id}
        {...restRowProps}
        onClick={handleClick}
        onContextMenu={onSelect}
        className="drag-and-drop"
      ></GridRow>
    </Stack>
  );
};
