import * as React from 'react';
import { VariableSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Box } from 'material-ui-core';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { Tooltip } from '@harmon.ie-ce/storybook';
import { strings } from '@vendor/languages';
import MiniLoader from '../Lottie/Animations/mini-loader/lottie/Loader_loop.json';
import { ContextMenu2 } from '../ContextMenu';
import { LOADER_HEIGHT } from '../Loader/Loader';
import { LottieAnimation } from '../Lottie';
import { Scrollbar, ScrollbarContext } from '../Scollbar';
import { BackToTop } from '../../assets/icons';
export type VirtualListRender = (x: any) => React.ReactNode;
interface BarProps extends React.ComponentPropsWithoutRef<'div'> {
  forwardedRef?: React.ForwardedRef<HTMLDivElement>;
}

const VirtualListScrollWrap = styled(Box)`
  position: relative;
  & > div {
    position: relative;
  }
`;

const ScrollToTopButton = styled(Button)`
  position: fixed;
  z-index: 10000;
  width: 36px;
  padding: 8px;
  height: 36px;
  bottom: 20px;
  right: 20px;
  display: none;
  border-radius: 10px;
  boxshadow: ${({ theme }) => theme.palette.shadow.buttonSheet};
  border: 1px solid ${({ theme }) => theme.palette.backgrounds.emptyStateBackground};

  .showbutton-true + & {
    display: block;
  }
`;

const VirtualListScroll: React.FC<React.PropsWithChildren> = ({ children }) => {
  const scrollRef = React.useRef<HTMLDivElement | null>(null);
  const { resizeObserver } = React.useContext(ScrollbarContext);

  React.useEffect(() => {
    const curScrollRef = scrollRef.current;
    curScrollRef && resizeObserver?.observe(curScrollRef);
    return () => {
      curScrollRef && resizeObserver?.unobserve(curScrollRef);
    };
  }, [resizeObserver]);

  return <VirtualListScrollWrap {...{ ref: scrollRef }}>{children}</VirtualListScrollWrap>;
};

const CustomScrollbars: React.FC<BarProps> = ({ onScroll, style, children }) => {
  const [showButton, setShowButton] = React.useState<boolean>(false);
  const scrollMiddleWare = React.useCallback(
    e => {
      setShowButton(e?.currentTarget?.scrollTop > e?.currentTarget?.clientHeight);
      onScroll?.(e);
    },
    [onScroll]
  );

  return (
    <Scrollbar
      noHorizontal
      withThrottling
      style={style}
      horizontalOffset={-10}
      className={`showbutton-${showButton}`}
      onScroll={scrollMiddleWare}
    >
      <VirtualListScroll>{children}</VirtualListScroll>
    </Scrollbar>
  );
};

const CustomScrollbarsVirtualList = React.forwardRef<HTMLDivElement>((props, ref) => (
  <CustomScrollbars {...props} forwardedRef={ref} />
));

export const Root = styled(Box)`
  flex: 1 1 auto;
  margin-left: ${({ theme }) => theme.spacing(2)};
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

// TODO: strange padding in 3 px
const ItemWrap = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
`;

type ItemsDataType = {
  itemsData: any[];
  render: VirtualListRender;
};

const ListItem: React.FC<{ data: ItemsDataType; index: number; style: React.CSSProperties }> = ({
  data: { itemsData: items, render },
  index,
  style,
}) => {
  const options = {
    loop: true,
    autoplay: true,
    animationData: MiniLoader,
  };
  return (
    <ItemWrap style={style}>
      {index < items.length ? render(items[index]) : <LottieAnimation options={options} height={100} width={100} />}
    </ItemWrap>
  );
};

const getItemSize = (
  index: number,
  sizeProp: number | ((items: any[], index: number) => number),
  items: any[]
): number =>
  index < items.length ? (typeof sizeProp == 'function' ? sizeProp(items, index) : sizeProp) : LOADER_HEIGHT + 30;

const VirtualList: React.FC<{
  size?: number | ((items: any[], index: number) => number);
  hasNextPage?: boolean;
  isNextPageLoading: boolean;
  items: any[];
  render: VirtualListRender;
  withScrollToTop?: boolean;
  loadNextPage?: () => void;
}> = ({ hasNextPage, isNextPageLoading, items, loadNextPage, size = 45, render, withScrollToTop }) => {
  const [fakeKey, setKey] = React.useState<number>(0);
  const itemCount = hasNextPage ? items.length + 1 : items.length;
  const loadMoreItems = !hasNextPage || isNextPageLoading ? () => null : loadNextPage;
  const isInitLoading = isNextPageLoading && !items.length;
  const isItemLoaded = React.useCallback(index => !hasNextPage || index < items.length, [hasNextPage, items.length]);
  const options = {
    loop: true,
    autoplay: true,
    animationData: MiniLoader,
  };

  return (
    <Root key={`fakeKey${fakeKey}`} className="drag-and-drop-drilldown">
      {isInitLoading ? (
        <LottieAnimation options={options} height={100} width={100} />
      ) : (
        <AutoSizer>
          {({ height, width }) => (
            <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems}>
              {({ onItemsRendered, ref }) => (
                <ContextMenu2>
                  <List
                    className="List"
                    height={height || 0}
                    itemData={{ itemsData: items, render }}
                    itemCount={itemCount}
                    itemSize={index => getItemSize(index, size, items)}
                    onItemsRendered={onItemsRendered}
                    outerElementType={CustomScrollbarsVirtualList}
                    ref={ref}
                    width={width || 0}
                  >
                    {ListItem}
                  </List>
                  {withScrollToTop && (
                    <Tooltip title={strings.lang.actiosNames.backToTop}>
                      <ScrollToTopButton variant="primary" onClick={() => setKey(k => k + 1)}>
                        <BackToTop width={20} height={20} />
                      </ScrollToTopButton>
                    </Tooltip>
                  )}
                </ContextMenu2>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      )}
    </Root>
  );
};

export default VirtualList;
