import { NotificationContext } from '@storybook';
import React, { useCallback, useContext, useRef } from 'react';
import { strings } from '@vendor/languages';
import { useSafeCallbackType, VoidPromiseFunc } from '~/hooks/UseSafeCallback';
import { UploadItemProvider } from '~/utilities/uploadItem/UploadItemProvider';
import { useCreateEmailHeaderMappingStep } from '~/upload/useCreateEmailHeaderMappingStep';
import { publishNewItem, useUploadStep } from '~/upload/useUploadStep';
import { useCheckIfExistStep } from '~/upload/useCheckIfExistStep';
import { useEmailHeaderMapping } from '~/upload/useEmailHeaderMapping';
import { useSaveEmailAttachments } from '~/beforeUpload/useSaveEmailAttachments';
import UploadContext from './UploadContext';
import { useGraphClient } from '../../../services/src/networkAndCache';
import IndicatorContext from './IndicatorContext';

export const UploadProvider = (props: any) => {
  const gcl = useGraphClient();
  const { showNotification } = useContext(NotificationContext);
  const saveEmailAttachmentsBeforeUploadStep = useSaveEmailAttachments();
  const BeforeUploadSteps = React.useMemo(
    () => [saveEmailAttachmentsBeforeUploadStep],
    [saveEmailAttachmentsBeforeUploadStep]
  );
  const uploadSteps = [
    useCreateEmailHeaderMappingStep(),
    useUploadStep(),
    useCheckIfExistStep(),
    //useAddCategory(), // Remove category for now
    useEmailHeaderMapping(),
    publishNewItem(),
  ];
  const {
    errorfulProcessIndication,
    oneItemCompletedIndication,
    oneItemStartIndication,
    progressRequestHandler,
    startProgressIndication,
    successfulProcessIndication,
  } = useContext(IndicatorContext);
  const beforeUploadInProgress = useRef(false);
  const startUpload: Parameters<useSafeCallbackType> = [
    async (
      items: UploadItemProvider[], // Adjusted type to handle both Files and Emails
      actionRef: React.MutableRefObject<any>
    ) => {
      const uploadItems = items;
      // TODO : All upload function (D&D, Upload, Save email) sould use before upload actions.
      if (progressRequestHandler?.inProgress() || beforeUploadInProgress.current) {
        showNotification(
          strings.lang.indicatorTexts.unableToRun,
          undefined,
          undefined,
          progressRequestHandler?.inProgress() ? 'indicator-still-uploading' : ''
        );
        return;
      }

      startProgressIndication?.(
        uploadItems.length,
        uploadItems.map(ui => ui.getItemName())
      );
      for (let i = 0; i < uploadItems.length; i++) {
        if (progressRequestHandler?.needToCancel()) {
          return;
        }
        if (progressRequestHandler?.getCurrentProgressIndex())
          progressRequestHandler.getCurrentProgressIndex().current = i; // Remove optional chaining operator
        oneItemStartIndication?.(i);
        const uploadItem = uploadItems[i];
        for (let j = 0; j < uploadSteps.length; j++) {
          await uploadSteps[j].execute(
            uploadItem,
            i,
            actionRef,
            uploadItems.length > 1,
            progressRequestHandler,
            showNotification
          );
        }
        oneItemCompletedIndication?.(i);
      }
      uploadSteps.map(step => step.clean());
      successfulProcessIndication?.();
    },
    [
      gcl,
      oneItemCompletedIndication,
      oneItemStartIndication,
      progressRequestHandler,
      showNotification,
      startProgressIndication,
      successfulProcessIndication,
    ],
    false,
    {},
    undefined,
    errorfulProcessIndication,
  ];
  const beforeUpload = useCallback(
    async (
      items: UploadItemProvider[],
      actionRef: React.MutableRefObject<any>,
      upload: VoidPromiseFunc
    ): Promise<void> => {
      if (beforeUploadInProgress.current || progressRequestHandler?.inProgress()) {
        showNotification(
          strings.lang.indicatorTexts.unableToRun,
          undefined,
          undefined,
          progressRequestHandler?.inProgress() ? 'indicator-still-uploading' : ''
        );
        return;
      }
      beforeUploadInProgress.current = true;
      let uploadItems: UploadItemProvider[] = [];
      for (let j = 0; j < BeforeUploadSteps.length; j++) {
        uploadItems = await BeforeUploadSteps[j].execute(items);
      }
      beforeUploadInProgress.current = false;
      if (uploadItems.length > 0) await upload(uploadItems, actionRef);
    },
    [BeforeUploadSteps, progressRequestHandler, showNotification]
  );
  return (
    <UploadContext.Provider
      value={{
        startUpload: startUpload,
        beforeUpload: beforeUpload,
      }}
    >
      {uploadSteps.map((step, index) => (
        <div key={index}>{step.stepUi}</div>
      ))}{' '}
      {BeforeUploadSteps.map((step, index) => (
        <div key={index}>{step.stepUi}</div>
      ))}{' '}
      {props.children}
    </UploadContext.Provider>
  );
};
