import React, { MouseEventHandler } from 'react';
import { Box, styled, useTheme } from '@mui/material';
import { Typography } from '../../Typography';

const StyledStack = styled(Box)`
  display: flex;
  padding-right: ${({ theme }) => theme.spacing(1)};
  justify-content: start;
  align-content: center;
  align-items: center;
  flex-direction: row;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex-shrink: inherit;
  margin-top: 0 !important;
  & .underline {
    position: relative;
    display: inline-block;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 2px;
      background: ${({ theme }) => theme.palette.buttons.accentSecondary};
      border-radius: 1px;
      left: 0;
      .navigationItem.isSelected & {
        background: ${({ theme }) => theme.palette.texts.onAccentPrimary};
      }
    }
  }
  .navigationItem:hover & {
    flex-shrink: initial;
  }
`;

const ItemNodeContentText: React.FC<
  React.PropsWithChildren<{
    highLightSecondLine: boolean;
    highlightString?: string;
    isSecondLine?: boolean;
    onMouseOver?: MouseEventHandler | undefined;
    onMouseLeave?: MouseEventHandler | undefined;
    isSelected?: boolean;
    isSummary?: boolean;
  }>
> = ({
  children,
  highlightString,
  isSecondLine,
  onMouseLeave,
  onMouseOver,
  isSelected,
  isSummary,
  highLightSecondLine,
}) => {
  const wordsToHighlight = highlightString
    ? highlightString.split(' ').map(word => new RegExp(`\\b${word}\\b`, 'gi'))
    : [];

  const parts =
    typeof children === 'string'
      ? children.split(/\b/).map((part, index) => {
          const shouldHighlight = wordsToHighlight.some(regex => regex.test(part));
          return shouldHighlight ? (
            <span key={index} className={!isSecondLine || highLightSecondLine ? 'underline' : ''}>
              {part}
            </span>
          ) : (
            part
          );
        })
      : [children];

  const theme = useTheme();
  return (
    <StyledStack onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
      <Typography
        variant={isSecondLine && !isSummary ? 'H3Regular' : isSummary ? 'H3Italic' : 'H1Regular'}
        noWrap
        sx={{
          pointerEvents: 'none',
          display: 'inline-block',
          overflow: 'hidden',
          position: 'relative',
          flexShrink: 0,
          width: '100%',
          height: '100%',
          paddingLeft: isSecondLine || isSummary ? theme.spacing(6) : 0,
          color: isSelected
            ? `${theme.palette.texts.onAccentPrimary} !important`
            : isSummary
              ? `${theme.palette.texts.secondary}`
              : '',
        }}
      >
        {parts}
      </Typography>
    </StyledStack>
  );
};

export default ItemNodeContentText;
